import React from 'react';
import { Col, Row, Button } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import { BookCard, BookCover, BookMeta, BookTitle, BOOK_COL_STYLE, THUMBNAIL_BASE_URL, SMART_ECLASS_LINK_BUTTON_STYLE } from './CommonComp';
import styled from 'styled-components';
const LEVELS = [1, 2, 3, 4];
const VOLUMES = [1, 2, 3];
const URL = [
  ['obsq', 'nmpq', 'zbqf'],
  ['dzmn', 'eyaq', 'csgi'],
  ['zoru', 'oezf', 'foej'],
  ['jtsu', 'mbhi', 'scky'],
];

const GrammarTap = () => {
  const { levelNo, onChangeLevelNo } = useLevelTab();
  return (
    <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
      <LevelTab
        levelNo={levelNo}
        levels={LEVELS}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />
      {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv) => (
        <Row key={`row-${lv}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
          {VOLUMES.map((vol) => (
            <Col span={4} key={`col-${lv}-${vol}`} style={{ ...BOOK_COL_STYLE, borderRight: `${vol === 3 ? '1px solid #dedede' : ''}` }}>
              <BookTitle level={5}>{`Grammar Tap ${lv}-${vol}`}</BookTitle>
              <BookCard
                
                cover={
                  <>
                    <BookCover
                      style={{
                        backgroundImage: `url(${THUMBNAIL_BASE_URL}GT-${lv}-${vol}.jpg)`,
                      }}
                    />
                    <ButtonWrap>
                      <Button
                        onClick={() => {
                          window.open(`https://online.flipbuilder.com/sangbong91/${URL[lv - 1][vol - 1]}`, '_blank', 'width=1400, height=900');
                        }}
                        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.9em', lineHeight: '1' }}
                      >
                        e Book
                      </Button>
                    </ButtonWrap>
                  </>
                }
              >
                <BookMeta />
              </BookCard>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default GrammarTap;
const ButtonWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  top: 0;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }
`;
