import { gql } from '@apollo/client';

export const CREATE_CLASS = gql`
  mutation createClass(
    $name: String!
    $campus_idx: Int!
    $book_code: String!
    $day_of_week: String!
    $day_of_week_view: String!
    $begin_date: String!
    $end_date: String
    $begin_time: String
    $end_time: String
    $room: String
    $teachers: String
    $is_auto_schedule: Boolean!
    $book_title: String
    $lexile_level: String
    $levelup_level: String
    $is_learning_center: Int
    $parent_idx: Int
    $each_day_count: [String!]
  ) {
    createClass(
      name: $name
      campus_idx: $campus_idx
      book_code: $book_code
      day_of_week: $day_of_week
      day_of_week_view: $day_of_week_view
      begin_date: $begin_date
      end_date: $end_date
      begin_time: $begin_time
      end_time: $end_time
      room: $room
      teachers: $teachers
      is_auto_schedule: $is_auto_schedule
      book_title: $book_title
      lexile_level: $lexile_level
      levelup_level: $levelup_level
      is_learning_center: $is_learning_center
      parent_idx: $parent_idx
      each_day_count: $each_day_count
    ) {
      idx
      name
      book_code
      day_of_week
      day_of_week_view
      begin_date
      end_date
      begin_time
      end_time
      room
      idate
      is_learning_center
      parent_idx
      campus {
        code
        name
        company {
          name
        }
      }
      class_teacher {
        user {
          id
          idx
          name
          campus_idx
        }
      }
    }
  }
`;

export const UPDATE_CLASS = gql`
  mutation updateClass(
    $idx: Int!
    $name: String!
    $campus_idx: Int!
    $book_code: String!
    $day_of_week: String
    $day_of_week_view: String!
    $begin_date: String!
    $end_date: String!
    $begin_time: String
    $end_time: String
    $room: String
    $teachers: String
    $is_auto_schedule: Boolean!
    $book_title: String
    $lexile_level: String
    $levelup_level: String
    $is_learning_center: Int
    $parent_idx: Int
    $each_day_count: [String!]
  ) {
    updateClass(
      idx: $idx
      name: $name
      campus_idx: $campus_idx
      book_code: $book_code
      day_of_week: $day_of_week
      day_of_week_view: $day_of_week_view
      begin_date: $begin_date
      end_date: $end_date
      begin_time: $begin_time
      end_time: $end_time
      room: $room
      teachers: $teachers
      is_auto_schedule: $is_auto_schedule
      book_title: $book_title
      lexile_level: $lexile_level
      levelup_level: $levelup_level
      is_learning_center: $is_learning_center
      parent_idx: $parent_idx
      each_day_count: $each_day_count
    ) {
      idx
      name
      book_code
      day_of_week
      begin_date
      end_date
      begin_time
      end_time
      room
      idate
      is_learning_center
      parent_idx
      campus {
        code
        name
        company {
          name
        }
      }
      class_teacher {
        user {
          id
          idx
          name
        }
      }
    }
  }
`;

export const ADD_BOOK_TO_CLASS = gql`
  mutation addBookToClass(
    $class_idx: Int!
    $book_codes: [String!]!
    $begin_date: String!
    $day_of_week: String!
    $is_auto_schedule: Boolean!
    $each_day_count: [String!]
  ) {
    addBookToClass(
      class_idx: $class_idx
      book_codes: $book_codes
      day_of_week: $day_of_week
      begin_date: $begin_date
      is_auto_schedule: $is_auto_schedule
      each_day_count: $each_day_count
    ) {
      idx
      class_book {
        book_code
        begin_date
        day_of_week
        book {
          level_no
          volume_no
          book_sub {
            title
            book_category {
              no
            }
            no
            cover_path
          }
        }
      }
    }
  }
`;

export const REMOVE_SINGLE_LESSON = gql`
  mutation removeSingleLesson($idx: Int!) {
    removeSingleLesson(idx: $idx) {
      idx
    }
  }
`;

export const ADD_STUDENT_IN_CLASS = gql`
  mutation addStudentInClass($class_idx: Int!, $students: [Int!]!, $is_auto_schedule: Boolean!) {
    addStudentInClass(class_idx: $class_idx, students: $students) {
      idx
      user {
        idx
        id
        name
        english_name
        school_name
        school_grade
        email
        phone
        memo
        birthday
        parent_name
        parent_phone
        address
        type
        idate
        class_student {
          idx
          class {
            idx
            name
          }
        }
      }
    }

    # addStudentClassLesson(class_idx: $class_idx, students: $students) @include(if: $is_auto_schedule)
    addStudentClassLesson(class_idx: $class_idx, students: $students)
    addStudentBookCredit(class_idx: $class_idx, students: $students) @skip(if: $is_auto_schedule)
  }
`;
export const MOVE_STUDENT_TO_OTHER_CLASS = gql`
  mutation moveStudentToOtherClass($class_idx: Int!, $user_idx: Int!, $target_class_idx: Int!) {
    moveStudentToOtherClass(class_idx: $class_idx, user_idx: $user_idx, target_class_idx: $target_class_idx) {
      idx
      class_idx
      user_idx
      class {
        name
      }
      user {
        name
        id
      }
    }
  }
`;
//반에 학생 추가 삭제시에 사용함.
export const ADD_STUDENT_IN_CLASS_SUB = gql`
  mutation addStudentInClassSub($class_idx: Int!, $students: [Int!]!, $is_auto_schedule: Boolean!) {
    addStudentInClassSub(class_idx: $class_idx, students: $students) {
      ok
      result
    }

    addStudentBookCredit(class_idx: $class_idx, students: $students) @skip(if: $is_auto_schedule)
  }
`;

//현재 사용하지 않음.. addStudentInClass 에서 추가, 삭제 처리는 모두함
export const REMOVE_STUDENT_IN_CLASS = gql`
  mutation removeStudentInClass($class_idx: Int!, $students: [Int!]!) {
    removeStudentInClass(class_idx: $class_idx, students: $students)

    removeStudentClassLesson(class_idx: $class_idx, students: $students)
  }
`;

export const DELETE_CLASS = gql`
  mutation deleteClass($class_idx_list: [Int!]!) {
    deleteClass(class_idx_list: $class_idx_list)
  }
`;
