import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const TalkingTutorMenuGroup = ({ currentMenu }) => {
    const history = useHistory();

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15, flexWrap: 'wrap', gap: '8px' }}>
            <MenuButton
                type={`${currentMenu === 'assign' ? 'primary' : 'default'} `}
                onClick={() => {
                    history.push('/ai-talking-tutor/topiclist');
                }}
            >
                Assign
            </MenuButton>
            &nbsp;
            <MenuButton
                type={`${currentMenu === 'list' ? 'primary' : 'default'} `}
                onClick={() => {
                    history.push('/ai-talking-tutor/list');
                }}
            >
                Assigned Group List
            </MenuButton>
        </div>
    );
};

export default TalkingTutorMenuGroup;
