import React, { useState, useMemo } from 'react';
import { Layout, Menu, Image, Modal, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { queryAncestors } from 'src/utils';
import { getCurrentToken } from 'src/utils';
import { classStoreData } from 'src/operations/store';
import * as dummy from 'src/dummy';
import { isPturn } from 'src/utils';
import NewsomaticButton from 'src/components/common/NewsomaticButton';
import ScriboButton from 'src/components/common/ScriboButton';
import BookrButton from 'src/components/common/BookrButton';
import StarWordsButton from 'src/components/common/StarWordsButton';
import * as queries from 'src/operations/queries';
import { useLazyQuery } from '@apollo/client';
import SwitchAccountButton from '../common/SwitchAccountButton';
import SwitchCampusButton from '../common/SwitchCampusButton';

const { Sider } = Layout;
const { SubMenu } = Menu;

const AppLogo = styled.div`
  height: 40px;
  margin: 16px 18px;
  text-align: center;
`;

const FooterArea = styled.div`
  width: 180px;
  position: fixed;
  bottom: 0;
  margin: 16px 18px;
  color: rgba(255, 255, 255, 0.65);
  opacity: 0.9;
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.65);
  }
`;

const onHandleRecordingClick = () => {
  if (window.Android) {
    window.Android.startRecordingActivity();
  }
};

const onHandleZoomClick = (user) => {
  if (window.Android) {
    if (user.type === 0) {
      window.Android.joinZoomClass(
        JSON.stringify({
          cloubot_zoom_user_id: user.id,
          id: 96909142653,
          start_url: 'https://zoom.us/s/96909142653',
          zak: 'eyJ0eXAiOiJKV1QiLCJzdiI6IjAwMDAwMSIsInptX3NrbSI6InptX28ybSIsImFsZyI6IkhTMjU2In0.eyJhdWQiOiJjbGllbnRzbSIsInVpZCI6Ill6aWhLMEp3VGxtTUdweHJ4M3FRVEEiLCJpc3MiOiJ3ZWIiLCJzdHkiOjk5LCJ3Y2QiOiJhdzEiLCJjbHQiOjAsIm1udW0iOiI5NjkwOTE0MjY1MyIsInN0ayI6IkRGRE9jenE0Um5SZHNmVk42WWYxcWxqYnNyb1lhbEJSc3hCSGpsdVcxNnMuQmdZZ1RUTnFNRlJEYVRWU1drRlBZVFJZYW10cWRVRlBUR2xEWm1SblZWVTJaVGNBQUF4eVpXeHdlRmN2VlVOblFUMEFBMkYzTVFBQUFYMElJU2RlQUJKMUFBQUEiLCJleHAiOjE2NDQyOTQ5MzAsImlhdCI6MTYzNjUxODkzMCwiYWlkIjoiLVRXcW5BVFBUNEc2aDc2MUFmRUtCUSIsImNpZCI6IiJ9.p_zDcQB0kFdwG_VayNxlHzYWohoN_jcEWJ-EQaaxwCE',
          password: '307379',
        }),
      );
    } else {
      window.Android.startZoomClass(
        JSON.stringify({
          cloubot_zoom_user_id: user.id,
          id: 96909142653,
          start_url: 'https://zoom.us/s/96909142653',
          zak: 'eyJ0eXAiOiJKV1QiLCJzdiI6IjAwMDAwMSIsInptX3NrbSI6InptX28ybSIsImFsZyI6IkhTMjU2In0.eyJhdWQiOiJjbGllbnRzbSIsInVpZCI6Ill6aWhLMEp3VGxtTUdweHJ4M3FRVEEiLCJpc3MiOiJ3ZWIiLCJzdHkiOjk5LCJ3Y2QiOiJhdzEiLCJjbHQiOjAsIm1udW0iOiI5NjkwOTE0MjY1MyIsInN0ayI6IkRGRE9jenE0Um5SZHNmVk42WWYxcWxqYnNyb1lhbEJSc3hCSGpsdVcxNnMuQmdZZ1RUTnFNRlJEYVRWU1drRlBZVFJZYW10cWRVRlBUR2xEWm1SblZWVTJaVGNBQUF4eVpXeHdlRmN2VlVOblFUMEFBMkYzTVFBQUFYMElJU2RlQUJKMUFBQUEiLCJleHAiOjE2NDQyOTQ5MzAsImlhdCI6MTYzNjUxODkzMCwiYWlkIjoiLVRXcW5BVFBUNEc2aDc2MUFmRUtCUSIsImNpZCI6IiJ9.p_zDcQB0kFdwG_VayNxlHzYWohoN_jcEWJ-EQaaxwCE',
          password: '307379',
        }),
      );
    }
  } else {
    window.open('https://zoom.us/j/96909142653?pwd=MUFwTmM0bWxhRzUzcmVGaTRYZmtyZz09', '_blank');
  }
};

const AdminMenuList = [
  { id: 1, name: 'FC 관리', route: '/fcManager' },
  { id: 2, name: '컨텐츠 관리', route: '/contentManagement' },
  { id: 3, name: 'Scribo 이용 관리', route: '/scriboSubscriptionManagement' },
  { id: 4, name: 'AI 사용 기록 열기', route: '/openAiUsage' },
  { id: 5, name: 'Meta Statics All', route: '/meta-statics/all' },
  { id: 6, name: 'Add content', route: '/ai-talking-tutor/topiclist' },
  {
    id: 7,
    name: 'Test Management',
    route: '/test/manage/list',
  },
  {
    id: 8,
    name: 'Test Group Management',
    route: '/test/syllabus/main',
  },
];

function Navigation() {
  const [selectedMenu, setSelectedMenu] = useState([]);
  const location = useLocation();
  const token = getCurrentToken();
  const defaultClass = useSelector(classStoreData);
  const { data } = useQueryProxy(USER_ME, { variables: { token } });
  const is_smarteclass = useMemo(() => {
    return isPturn(defaultClass) || defaultClass === 'educosneakers';
  }, [defaultClass]);
  const history = useHistory();
  const [fetchMenuList, { data: companyUserMenuListData }] = useLazyQuery(queries.company.GET_COMPANY_USER_MENU_LIST);

  const [fetchCampusMenuItemSettingList, { data: campusMenuItemSettingListData }] = useLazyQuery(
    queries.campus.GET_CAMPUS_USER_MENU_ITEM_SETTING_LIST,
  );

  const menuList = useMemo(() => {
    const userType = data?.me?.type || 0;
    const companyId = data?.me?.campus?.fc_company_idx;
    //const has_branch = data?.me?.campus?.company?.has_branch === '1' ? true : false;
    const campusId = data?.me.campus_idx;

    if (userType === 5) {
      return AdminMenuList;
    }

    let formatUserType = String(userType);

    if ([6, 7, 8].includes(userType)) {
      formatUserType = '2';
    }

    fetchMenuList({
      variables: {
        company_idx: companyId,
        user_type: formatUserType,
      },
    });

    let menuListData = [];
    if (campusId !== 12 && (userType === 0 || userType === 1 || userType === 2 || userType === 3 || userType === 6)) {
      fetchCampusMenuItemSettingList({
        variables: {
          campus_idx: campusId,
        },
      });

      const menuSettingMap = new Map();
      campusMenuItemSettingListData?.campusUserMenuItemSettingList?.forEach((element) => {
        menuSettingMap.set(element.company_menu_item_id, element);
      });

      menuListData = companyUserMenuListData?.companyUserMenuList[0]?.company_user_menu_item?.map((item) => {
        const menuItemSetting = menuSettingMap.get(item.idx);
        if (menuItemSetting) {
          const isHidden = menuItemSetting.is_hidden ? menuItemSetting.is_hidden : false;
          return { ...item, is_hidden: isHidden };
        }
        return item;
      });
    } else {
      menuListData = companyUserMenuListData?.companyUserMenuList[0]?.company_user_menu_item;
    }

    let menuListItems = [];

    menuListItems = menuListData?.map((item) => {
      let menuName = item.item_name;
      if (item.show_icon && item.icon_url) {
        menuName = (
          <>
            <img src={item.icon_url} alt="" style={{ width: '25px', height: '25px' }} />
            &nbsp;{menuName}
          </>
        );
      }
      return { ...item, item_name: menuName };
    });

    const getChildren = (parentId) => {
      return menuListItems?.filter((item) => item.parent_id === parentId && !item.is_hidden).sort((a, b) => a.index - b.index);
    };

    const rootItems = menuListItems?.filter((item) => item.parent_id === null && !item.is_hidden).sort((a, b) => a.index - b.index);
    const rootMenu = rootItems?.map((item) => {
      const children2 = getChildren(item.idx);
      const children2Menu = children2.map((item) => {
        const children3 = getChildren(item.idx);
        const children3Menu = children3.map((item) => {
          return {
            id: item.idx,
            name: item.item_name,
            route: item.route_item.route,
            type: item.route_item.type,
            passUserInfo: item.route_item.pass_user_info,
            showIcon: item.show_icon,
            iconUrl: item.icon_url,
          };
        });

        return {
          id: item.idx,
          name: item.item_name,
          route: item.route_item.route,
          type: item.route_item.type,
          passUserInfo: item.route_item.pass_user_info,
          showIcon: item.show_icon,
          iconUrl: item.icon_url,
          childs: [...children3Menu],
        };
      });
      return {
        id: item.idx,
        name: item.item_name,
        route: item.route_item.route,
        type: item.route_item.type,
        passUserInfo: item.route_item.pass_user_info,
        showIcon: item.show_icon,
        iconUrl: item.icon_url,
        childs: [...children2Menu],
      };
    });

    return rootMenu;
  }, [data, companyUserMenuListData, campusMenuItemSettingListData, fetchMenuList, fetchCampusMenuItemSettingList]);

  function infoConfirm(e) {
    e.preventDefault();
    Modal.warning({
      title: '준비중입니다!',
      okText: '확인',
    });
  }

  const currentMenu = menuList?.find((menu) => menu.route && !menu.type === 1 && pathToRegexp(menu.route)?.exec(location.pathname));

  const getMenuListItem = (menu) => {
    if (menu.type === 1) {
      let route = menu.route;
      if (menu.passUserInfo) {
        route += `?id=${data?.me?.id}&name=${data?.me?.name}&token=${token}`;
      }
      return (
        <Menu.Item key={menu.id}>
          <a href={route}>{menu.name}</a>
        </Menu.Item>
      );
    } else if (menu.type === 2) {
      return (
        <Menu.Item key={menu.id}>
          <a href={`/i-frame/${btoa(menu.route)}/`}>{menu.name}</a>
        </Menu.Item>
      );
    } else if (menu.type === 3) {
      return (
        <Menu.Item key={menu.id}>
          <Link to="#" onClick={() => onHandleZoomClick(data?.me)}>
            {menu.name}
          </Link>
        </Menu.Item>
      );
    } else if (menu.type === 4) {
      return (
        <Menu.Item key={menu.id}>
          <NewsomaticButton type="link" />
        </Menu.Item>
      );
    } else if (menu.type === 5) {
      return (
        <Menu.Item key={menu.id}>
          <BookrButton type="link" />
        </Menu.Item>
      );
    } else if (menu.type === 6) {
      return (
        <Menu.Item key={menu.id}>
          <ScriboButton type="link" />
        </Menu.Item>
      );
    } else if (menu.type === 7) {
      return (
        <Menu.Item key={menu.id}>
          <StarWordsButton type="link" text={menu.name} />
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item key={menu.id}>
          {menu.route && menu.route !== '#' ? <Link to={menu.route}>{menu.name}</Link> : <Link onClick={infoConfirm}>{menu.name}</Link>}
        </Menu.Item>
      );
    }
  };

  const selectedKeys = currentMenu ? queryAncestors(menuList, currentMenu, 'menuParentId').map((_) => _.id) : [];

  return (
    <Sider trigger={null} style={{ height: '100vh', overflowY: 'auto', padding: '0px 1x' }}>
      <AppLogo className={`${defaultClass} company_logo`}>
        <Image height={40} src={dummy.settings.settings[defaultClass].logo || ''} preview={false} />
      </AppLogo>
      <Menu
        theme="dark"
        triggerSubMenuAction="click"
        subMenuCloseDelay={0}
        forceSubMenuRender={true}
        defaultSelectedKeys={[`${selectedKeys ? selectedKeys : 1}`]}
        selectedKeys={selectedMenu}
        mode="vertical"
        onSelect={(key, e) => setSelectedMenu([key])}
        style={{ fontSize: 12 }}
      >
        {menuList?.map((menu) => {
          if (menu.childs?.length > 0) {
            return (
              <SubMenu key={menu.id} title={menu.name} popupClassName={`transparent-sub-menu-list ${defaultClass}`}>
                {menu.childs.map((child) => {
                  if (child.childs?.length > 0) {
                    return (
                      <SubMenu key={child.id} title={child.name} popupClassName={`transparent-sub-menu-list ${defaultClass}`}>
                        {child.childs.map((subChild) => {
                          if (child.route === '/recording/add') {
                            return (
                              <Menu.Item key={subChild.id}>
                                <Link to="#" onClick={() => onHandleRecordingClick()}>
                                  {subChild.name}
                                </Link>
                              </Menu.Item>
                            );
                          } else {
                            return getMenuListItem(subChild);
                          }
                        })}
                      </SubMenu>
                    );
                  } else {
                    return getMenuListItem(child);
                  }
                })}
              </SubMenu>
            );
          } else {
            return getMenuListItem(menu);
          }
        })}
        {data?.me?.type < 4 && <SwitchAccountButton />}
        {data?.me?.type < 4 && <SwitchCampusButton />}
      </Menu>
      {is_smarteclass && (
        <FooterArea className="headerfooter-area">
          <Row gutter={[16, 16]}>
            <Col>
              <span style={{ color: '#fff' }}>CS (1899-0597)</span>
            </Col>
          </Row>
        </FooterArea>
      )}
      {defaultClass === 'creo' && (
        <FooterArea className="headerfooter-area">
          <Row gutter={[16, 16]}>
            <Col>
              <div style={{ textAlign: 'center', marginBottom: '5px', lineHeight: '1.6' }}>
                <span style={{ color: 'black' }}>
                  {`오류 문의 전화 1811-8905`}
                  <br />
                  운영시간 AM 10:00~PM 7:00
                  <br />
                </span>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <div
                onClick={() => {
                  history.push('/faq');
                }}
                style={{
                  color: 'black',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  border: '1px black solid',
                  padding: '10px 15px',
                  marginBottom: '5px',
                  width: '170px',
                  textAlign: 'center',
                }}
              >
                자주 묻는 질문
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <div
                onClick={() => {
                  window.open('https://939.co.kr/choisunmate/', '_blank');
                }}
                style={{
                  color: 'black',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  border: '1px black solid',
                  padding: '10px 15px',
                  marginBottom: '5px',
                  width: '170px',
                  textAlign: 'center',
                }}
              >
                원격 지원 요청
              </div>
            </Col>
          </Row>
        </FooterArea>
      )}
    </Sider>
  );
}

export default Navigation;
