import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Button, Row, DatePicker, Radio } from 'antd';
import { getStageColsRise } from 'src/utils/bookStage';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_CLASSS_LESSONS_FOR_CONTENT } from 'src/operations/queries/getClass';
import { userInfoVar } from 'src/apollo/cache';
import HomeworkResultMenuGroup from 'src/components/common/HomeworkResultMenuGroup';
import HomeworkResultScoreMenuGroupRise from 'src/components/common/HomeworkResultScoreMenuGroupRise';
import queryString from 'query-string';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const StageGroups = {
  RP: [
    {
      stage_no: 1,
      name_abbr: 'intro',
      book_stage: [
        {
          name: 'intro',
          name_abbr: 'intro',
          no: 1,
          group_code: 'lesson',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 2,
      name_abbr: 'practice',
      book_stage: [
        {
          name: 'practice',
          name_abbr: 'practice',
          no: 2,
          group_code: 'lesson',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 3,
      name_abbr: 'match',
      book_stage: [
        {
          name: 'match',
          name_abbr: 'match',
          no: 3,
          group_code: 'lesson',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 4,
      name_abbr: 'talk',
      book_stage: [
        {
          name: 'talk',
          name_abbr: 'talk',
          no: 4,
          group_code: 'lesson',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 5,
      name_abbr: 'search',
      book_stage: [
        {
          name: 'search',
          name_abbr: 'search',
          no: 1,
          group_code: 'review',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 6,
      name_abbr: 'picture',
      book_stage: [
        {
          name: 'picture',
          name_abbr: 'picture',
          no: 2,
          group_code: 'review',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 7,
      name_abbr: 'outro',
      book_stage: [
        {
          name: 'outro',
          name_abbr: 'outro',
          no: 3,
          group_code: 'review',
          category_name: null,
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
  ],
  'RR,RW,RL': [
    {
      stage_no: 1,
      name_abbr: 'pi',
      book_stage: [
        {
          name: 'Pronunciation',
          name_abbr: 'pi',
          no: 1,
          group_code: 'voca',
          category_name: 'pronunciation',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 2,
      name_abbr: 'wpd',
      book_stage: [
        {
          name: 'Drag and drop',
          name_abbr: 'wpd',
          no: 2,
          group_code: 'voca',
          category_name: 'word_practice',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 3,
      name_abbr: 'wpt',
      book_stage: [
        {
          name: 'Typing',
          name_abbr: 'wpt',
          no: 3,
          group_code: 'voca',
          category_name: 'word_practice',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 4,
      name_abbr: 'vs',
      book_stage: [
        {
          name: 'Speaking',
          name_abbr: 'vs',
          no: 4,
          group_code: 'voca',
          category_name: 'speaking',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 5,
      name_abbr: 'wrp',
      book_stage: [
        {
          name: 'Word Puzzle',
          name_abbr: 'wrp',
          no: 5,
          group_code: 'voca',
          category_name: 'word_review',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 6,
      name_abbr: 'wrm',
      book_stage: [
        {
          name: 'Memory Card',
          name_abbr: 'wrm',
          no: 6,
          group_code: 'voca',
          category_name: 'word_review',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 7,
      name_abbr: 'ksu',
      book_stage: [
        {
          name: 'Unscramble',
          name_abbr: 'ksu',
          no: 1,
          group_code: 'expr',
          category_name: 'sentence',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 8,
      name_abbr: 'ksq',
      book_stage: [
        {
          name: 'Choice',
          name_abbr: 'ksq',
          no: 2,
          group_code: 'expr',
          category_name: 'sentence',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 9,
      name_abbr: 'kst',
      book_stage: [
        {
          name: 'Sentence Typing',
          name_abbr: 'kst',
          no: 3,
          group_code: 'expr',
          category_name: 'sentence',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 10,
      name_abbr: 'exs',
      book_stage: [
        {
          name: 'Speaking',
          name_abbr: 'exs',
          no: 4,
          group_code: 'expr',
          category_name: 'speaking',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 11,
      name_abbr: 'ra',
      book_stage: [
        {
          name: 'Read Aloud',
          name_abbr: 'ra',
          no: 1,
          group_code: 'rc',
          category_name: 'read_aloud',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
    {
      stage_no: 12,
      name_abbr: 'quiz',
      book_stage: [
        {
          name: 'Comprehension',
          name_abbr: 'quiz',
          no: 2,
          group_code: 'rc',
          category_name: 'comprehension',
          ai_stage_code: null,
          __typename: 'BookStageOrg',
        },
      ],
      __typename: 'BookStageAbs',
    },
  ],
};

const getPercentGrade = (total, score) => {
  if (total === 0 || total === null || score === null) {
    return 0;
  }
  return Math.round((100 * score) / total);
};

const LearningContentStd = () => {
  const params = queryString.parse(window.location.search);
  const history = useHistory();
  const [studentIdx, setStudentIdx] = useState(null);
  const [content, setContent] = useState('RP');
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [studyDate, setStudyDate] = useState([]);
  const [state, setState] = useState('all');
  const company_name = userInfoVar()?.company_name;
  const PER_PAGE = company_name === 'rise' ? 20 : 10;
  const campus_idx = userInfoVar()?.campus_idx;
  const { data: dataClassLessons, loading } = useQuery(GET_CLASSS_LESSONS_FOR_CONTENT, {
    variables: {
      campus_idx: campus_idx,
      page: page,
      search_text: searchText,
      searchDate: studyDate,
      state: state,
      content: content,
      student_idx: studentIdx,
    },
    skip: !campus_idx || !studentIdx,
  });

  useEffect(() => {
    if (params.study_start_date && params.study_end_date) {
      setStudyDate([params.study_start_date, params.study_end_date]);
    }
  }, [params.study_start_date, params.study_end_date]);

  useEffect(() => {
    if (params.content) {
      setContent(params.content);
    }
  }, [params.content]);

  useEffect(() => {
    if (params.student_idx) {
      setStudentIdx(parseInt(params.student_idx));
    }
  }, [params.student_idx]);

  const classLessonsReal = useMemo(() => {
    if (dataClassLessons) {
      return dataClassLessons.classLessonsForContentResult?.map((item, key) => {
        const studentTotal = item?.class?.class_student?.length;
        const assignedUsers = item?.user_lesson;

        let stage_group_code = item?.book_lesson?.stage_group_code?.split('')[0];
        let stageGroup = StageGroups[content];
        let filterStageGroup = stageGroup.filter((stage) => {
          return item?.book_lesson?.stage_group_code === stage.book_stage[0].group_code;
        });

        let user_lessons = item?.user_lesson.filter((u) => u.user_idx === studentIdx);
        let completeList = 0;
        let totalLesson = 0;
        if (user_lessons) {
          user_lessons.forEach((user_lesson) => {
            let user_study = user_lesson?.user_study;
            if (user_study) {
              let filtered = user_study
                .filter((item) => {
                  return filterStageGroup.find((stage) => {
                    return item.stage_no === stage.book_stage[0].no;
                  });
                })
                .map((item) => {
                  return {
                    ...item,
                    user_idx: user_lesson.user_idx,
                  };
                });
              completeList += filtered.length;
              totalLesson += filterStageGroup.length;
            }
          });
        }

        return {
          No: PER_PAGE * page + key + 1,
          key: `class-lesson-row${item.idx}-${item.lesson_code}`,
          class: item?.class?.name,
          bookInfo: item?.book_lesson?.book?.book_sub ? item?.book_lesson?.book?.book_sub[0]?.title : item?.book_lesson?.book_code,
          lessonCode: item.lesson_code,
          classIdx: item.class.idx,
          title: `${item?.book_lesson?.title || item.lesson_code}`,
          date: moment(item.studyDate).format('YY/MM/DD'),
          org_date: item.studyDate,
          studentTotal,
          students: assignedUsers,
          user_lessons: user_lessons,
          stage_group_code,
          stage_group_code_full: item?.book_lesson?.stage_group_code,
          completeList,
          totalLesson,
          filterStageGroup,
        };
      });
    }
    return [];
  }, [dataClassLessons, studentIdx]);


  const studentLessonList = useMemo(() => {
    if (classLessonsReal) {
      let list = classLessonsReal.map((item) => {
        let stageGroups = item.filterStageGroup;
        let data = {
          student: item.user_lessons[0]['user']?.name,
          english_name: item.user_lessons[0]['user']?.english_name,
          class: item.class,
          No: item.No,
          total: 0,
          correct: 0,
          study_date: null,
          lesson: item.title,
          stat: item.user_lessons[0].stat,
        };

        if (stageGroups) {
          for (let i = 0; i < stageGroups.length; i++) {
            let stage = stageGroups[i];
            let user_study = item.user_lessons[0]?.user_study.filter((s) => s.stage_no === stage.book_stage[0].no);
            if (user_study.length === 0) {
              data[`stage${stage.name_abbr}`] = '-';
            } else {
              data[`stage${stage.name_abbr}`] = `${user_study[0].exam_correct}/${user_study[0].exam_total}(${getPercentGrade(
                user_study[0].exam_total,
                user_study[0].exam_correct,
              )})%`;
              data['total'] += user_study[0].exam_total;
              data['correct'] += user_study[0].exam_correct;
              data['study_date'] = data['study_date']
                ? moment(data['study_date']).isBefore(moment(user_study[0].study_date))
                  ? user_study[0].study_date
                  : data['study_date']
                : user_study[0].study_date;
            }
          }
        }

        return data;
      });

      return list;
    }
    return [];
  }, [classLessonsReal]);
  const handleDateChange = (_, date_string) => {
    if (
      date_string.length === 0 ||
      date_string[0] === 'Invalid date' ||
      date_string[1] === 'Invalid date' ||
      date_string[0] === '' ||
      date_string[1] === ''
    ) {
      setStudyDate([]);
      return;
    }

    setStudyDate(date_string);
  };

  const handleNameSearch = (sv) => {
    setSearchText(sv);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    // {
    //   key: 'name',
    //   title: '학생명',
    //   dataIndex: 'student',
    //   align: 'center',
    // },
    {
      key: 'class',
      title: '반명',
      dataIndex: 'class',
      align: 'center',
      sorter: true,
    },
  ];

  const realColumns = useMemo(() => {
    const stageGroup = StageGroups[content];
    if (stageGroup) {
      let added = getStageColsRise(stageGroup, stageGroup, true);
      added = [
        {
          title: 'Lesson',
          dataIndex: 'lesson',
          key: 'lesson',
          align: 'center',
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          align: 'center',
          render: (text, record) => {
            return `${record.correct || 0}/${record.total || 0}(${getPercentGrade(record.total, record.correct)}%)`;
          },
        },
        {
          title: '학습일',
          dataIndex: 'study_date',
          key: 'study_date',
          align: 'center',
          render: (text, record) => {
            return record.stat === '1' ? moment(record.study_date).format('YY/MM/DD HH:MM') : '-';
          },
        },
        ...added,
      ];
      return columns.concat(added);
    }
    return columns;
  }, [content]);

  return (
    <>
      {company_name === 'rise' ? (
        <HomeworkResultScoreMenuGroupRise currentMenu="content-std" />
      ) : (
        <HomeworkResultMenuGroup currentMenu="content-std" />
      )}
      <div
        className="homeresult-dv"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 'auto',
        }}
      >
        <HeaderTitle level={4}>{company_name === 'rise' ? '학생별 점수관리' : 'Homework Result'}{`${studentLessonList[0]?.student?`(${studentLessonList[0]?.student})`:''}`}</HeaderTitle>
        <div
          className="homeresult-selectfiled"
          style={{ display: 'inline-block', width: '75%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}
        >
          <Button style={{ color: '#289428' }} onClick={() => history.goBack()}>
            <ArrowLeftOutlined />
            Back{' '}
          </Button>
          &nbsp;&nbsp;
          <RangePicker
            disabled
            placeholder="출제날짜"
            initialValues={studyDate.length && [moment(studyDate[0], 'YYYY-MM-DD'), moment(studyDate[1], 'YYYY-MM-DD')]}
            value={studyDate.length && [moment(studyDate[0], 'YYYY-MM-DD'), moment(studyDate[1], 'YYYY-MM-DD')]}
            onChange={handleDateChange}
          />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="homeresult-halfwrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              {/* <Title level={5}>학생 List</Title> */}
              {/* <Button style={{ color: '#289428' }}>+ 숙제출제일정</Button> */}
            </div>

            <CustomTable
              dataSource={studentLessonList}
              columns={realColumns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                total: studentLessonList?.length,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default LearningContentStd;
