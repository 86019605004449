import React, { useState } from 'react';
import { Checkbox, Input, Select, Tooltip, DatePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FormField from 'src/components/common/FormField';

const { Option } = Select;

const CreateSubClassForm = ({ bookList, yoilOptions, isAutoSchedule, hasLevel, onDateChange }) => {
  const [showBooktitleInput, setShowBookTitleInput] = useState(false);

  function handleChangeBook(code) {
    setShowBookTitleInput(code === 'manual');
  }
  return (
    <>
      <FormField hasFeedback={true} title="클래스명" span={24}>
        <FormItem name="name" rules={[{ required: true, message: '클래스명을 입력해 주세요.' }]} hasFeedback style={{ width: '90%' }}>
          <Input name="name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={hasLevel} title={<Tooltip title="레벨을 선택하거나 입력하세요">레벨</Tooltip>} span={24}>
        <FormItem name="book_code" hasFeedback style={{ width: '90%' }} rules={[{ required: hasLevel, message: '교재를 선택해 주세요.' }]}>
          {/* <Tooltip title="레벨을 선택하거나 입력하세요"> */}
          <Select
            showSearch
            name="book_code"
            style={{ width: 200 }}
            placeholder={'레벨 선택'}
            onChange={handleChangeBook}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {bookList.map((book) => (
              <Option key={book.key} value={book.key}>
                {book.value}
              </Option>
            ))}
          </Select>
          {/* </Tooltip> */}
        </FormItem>
        {showBooktitleInput && (
          <FormItem name="book_direct" style={{ width: '90%' }}>
            <Input name="book_direct" style={{ width: 200 }} placeholder="교재명을 입력하세요" maxLength={30} />
          </FormItem>
        )}
      </FormField>

      <FormField hasFeedback={true} title={<Tooltip title="선택한 교재의 수업 시작일을 설정합니다.">학습 시작일</Tooltip>}>
        <FormItem
          name="begin_date"
          rules={[{ required: true, message: '학습 시작일을 선택해 주세요.' }]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <DatePicker name="begin_date" onChange={(_, d) => onDateChange('begin_date', d)} style={{ width: '100%' }} />
        </FormItem>
      </FormField>
      <FormField hasFeedback={true} title="학습 종료일">
        <FormItem
          name="end_date"
          rules={[{ required: true, message: '학습 종료일을 선택해 주세요.' }]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <DatePicker name="end_date" onChange={(_, d) => onDateChange('end_date', d)} style={{ width: '100%' }} />
        </FormItem>
      </FormField>

      <FormField
        hasFeedback={!hasLevel ? hasLevel : isAutoSchedule}
        title={<Tooltip title={'수업시간에 맞게 실라버스를 자동 설정합니다.'}>온라인 학습일</Tooltip>}
        span={24}
      >
        <FormItem
          name="day_of_week"
          rules={[{ required: !hasLevel ? hasLevel : isAutoSchedule, message: '요일을 선택해 주세요.' }]}
          style={{ width: '90%' }}
        >
          <Checkbox.Group options={yoilOptions} name="day_of_week" />
        </FormItem>
      </FormField>
    </>
  );
};

export default CreateSubClassForm;
