import React, { useState, useEffect } from 'react';
import SwitchArea, { useSwitchArea } from '../SwitchArea';
import ImageWordBox from '../ImageWordBox';
import ImageSoundWrite from '../ImageSoundWrite';
import ImageSelectWrite from '../ImageSelectWrite';
import PopUp from '../PopUp';
import MultiReviewType from '../MultiReviewType';
import SingleReviewType from '../SingleReviewType';
import PhonemeImageWrite from '../PhonemeImageWrite';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useStudyCreate } from '../../../utils/pm/api';
import { useHistory } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import _ from 'lodash';
import MagicWrite from '../MagicWrite';
import MagicWriteWrapper from '../MagicWriteWrapper';
// import MagicScript from "./common/MagicScript";

import ImmageWritePattern from '../ImageWritePattern';
import ImageWordSelectWrite from '../ImageWordSelectWrite';
import ImageAlphabetWrite from '../ImageAlphabetWrite';
import { userInfoVar } from 'src/apollo/cache';

//MyScript OR Keyboard 유형은 현재까지 세 가지 유형이 있다.
// 1. 그림보고 쓰기(Unit1 Stage 6) -> type: imgWrite
// 2. 그림보고 단어 듣고, 선택하고, 쓰기(Unit 1 Stage 8) -> type: imgSoundWrite

const MyScriptStage = ({ unitInfo, bookInfo, stages, steps, setSteps, typeName, directionEnd }) => {
  const {
    customData: { problem, hint },
  } = stages;
  const { word: userInputWord, onWriteEnd: handleWriteEnd } = useSwitchArea();
  const [showPopUp, setShowPopUp] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [clickSound, setClickSound] = useState('');

  const [isAfter, setIsAfter] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  // randomProblem을 만드는 경우... like Magic e stage 6
  const [randomProblem, setRandomProblem] = useState(undefined);

  const [end, setEnd] = useState(false);

  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setPopUpType('correct');
      setShowPopUp(true);
    },
  });

  useEffect(() => {
    if (hint) setRandomProblem(_.shuffle(problem));
    //학습목표 팝업 안 보여줌
    //setShowPopUp(true);
  }, [hint, problem]);

  const handleFinish = () => {
    setPopUpType('correct');
    setShowPopUp(true);

    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
    setTimeout(() => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    }, 3000);
  };

  const handleGoodJobSoundEnd = () => {
    // setShowPopUp(false);
    // history.replace({
    //   pathname: `${window.location.pathname.split('/stage')[0]}`,
    //   search: `${window.location.search}`,
    //   state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
    // });
  };

  const soundEnded = () => setEnd(true);

  useEffect(() => {
    setEnd(false);
    setClickSound('');
  }, [end]);
  console.log('myscriptstage', typeName);
  //여기서 부터 랜더링 복잡해졌구만..
  if (typeName === 'multiReview' || typeName === 'singleReview') {
    return (
      <>
        {
          {
            multiReview: <MultiReviewType stages={stages} steps={steps} setSteps={setSteps} onFinish={handleFinish} typeName={typeName} />,
            singleReview: (
              <SingleReviewType
                stages={stages}
                steps={steps}
                setSteps={setSteps}
                onFinish={handleFinish}
                clickSoundEnd={end}
                setClickSound={setClickSound}
              />
            ),
          }[typeName]
        }
        <SoundEffectPM url={clickSound} onEnd={soundEnded} />
        {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      </>
    );
  }
  //return 이라서 else if 를 안쓰고 그냥 if 쓴다. 가독성을 위해서..
  if (typeName === 'imgSoundWrite') {
    return (
      <>
        <ImageSoundWrite userInputWord={userInputWord} problem={problem} onFinish={handleFinish} steps={steps} setSteps={setSteps} />
        {showPopUp && <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} />}
      </>
    );
  }

  if (typeName === 'phonemeImageWrite') {
    return (
      <>
        <PhonemeImageWrite problem={problem} onFinish={handleFinish} steps={steps} setSteps={setSteps} />
        {showPopUp && <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} />}
      </>
    );
  }

  if (typeName === 'imgSelectWrite') {
    return (
      <>
        <ImageSelectWrite problem={problem} onFinish={handleFinish} steps={steps} setSteps={setSteps} directionEnd={directionEnd} />
        {showPopUp && <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} />}
      </>
    );
  }

  if (typeName === 'imageWordSelectWrite') {
    return (
      <>
        <ImageWordSelectWrite problem={problem} onFinish={handleFinish} steps={steps} setSteps={setSteps} />
        {showPopUp && <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} />}
      </>
    );
  }

  if (typeName === 'imageAlphabetWrite') {
    return (
      <>
        <ImageAlphabetWrite problem={problem} onFinish={handleFinish} steps={steps} setSteps={setSteps} />
        {showPopUp && <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} />}
      </>
    );
  }

  if (typeName === 'imgWrite') {
    return (
      <>
        <ImmageWritePattern
          userInputWord={userInputWord}
          problem={hint ? randomProblem : problem}
          onFinish={handleFinish}
          steps={steps}
          setSteps={setSteps}
          hint={hint}
          onWriteEnd={handleWriteEnd}
          onScriptLoaded={() => {
            console.log('onScriptLoaded');
            setIsLoading(false);
          }}
        />
        {showPopUp && <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} />}
      </>
    );
  }

  if (typeName === 'magicWrite') {
    return (
      <>
        <MagicWriteWrapper
          problem={problem}
          steps={steps}
          setSteps={setSteps}
          isAfter={isAfter}
          onFinish={handleFinish}
          stages={stages}
          onWriteEnd={handleWriteEnd}
          setIsAfter={setIsAfter}
          onScriptLoaded={() => {
            setIsLoading(false);
          }}
        />
        {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
        {/* {isLoading && <PopUp popUpType="loading" />} */}
      </>
    );
  }

  return (
    <>
      <div className="contents_hrbox hrbox_t">
        {
          {
            imgWrite: (
              <ImageWordBox
                userInputWord={userInputWord}
                problem={hint ? randomProblem : problem}
                onFinish={handleFinish}
                steps={steps}
                setSteps={setSteps}
                hint={hint}
              />
            ),
            magicWrite: <MagicWrite problem={problem} steps={steps} setSteps={setSteps} isAfter={isAfter} onFinish={handleFinish} />,
          }[typeName]
        }
      </div>
      <S.ImageSelectBottomBox className="contents_hrbox hrbox_b" style={typeName === 'magicWrite' ? { alignItems: 'center' } : {}}>
        {typeName === 'magicWrite' ? (
          // <MagicScript
          // 	stages={stages}
          // 	problem={problem}
          // 	onWriteEnd={handleWriteEnd}
          // 	steps={steps}
          // 	setSteps={setSteps}
          // 	setIsAfter={setIsAfter}
          // 	onScriptLoaded={() => {
          // 		setIsLoading(false);
          // 	}}
          // />
          <></>
        ) : (
          <SwitchArea
            onWriteEnd={handleWriteEnd}
            steps={steps}
            onScriptLoaded={() => {
              setIsLoading(false);
            }}
          />
        )}
      </S.ImageSelectBottomBox>
      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      {isLoading && <PopUp popUpType="loading" />}
    </>
  );
};

export default MyScriptStage;
