import React, { useMemo, useState, useRef } from 'react';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Col, Row, Typography, Select, Anchor, DatePicker, Button, Modal, Input } from 'antd';
import moment from 'moment';

import InvoiceModal from 'src/components/Modal/order/InvoiceModal';
import DeliveryInfoModal from 'src/components/Modal/order/DeliveryInfoModal';

import { MAX_SEARCH_INTERVAL_DAYS, DATE_SEARCH_OPTIONS_FOR_AFTER_SCHOOL } from 'src/constants/common';
import BookOrderAdminMenuGroup from 'src/components/common/BookOrderAdminMenuGroup';
import SmartEclassOrderListTable from './comp/SmartEclassOrderListTable';
import SmartEclassOrderListTableAll from './comp/SmartEclassOrderListTableAll';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import { MY_FC_ORDER_LIST, MY_FC_ORDER_LIST_BY_DURAION, MY_FC_ORDER_LIST_ALL } from 'src/operations/queries/pay';
import { DELETE_ORDERS, UPDATE_DELIVERY_STATUS, UPDATE_INVOICE_NUMBER, UPDATE_ORDER_PAY_CONFIRM } from 'src/operations/mutations/order';
//import imagesearch from 'src/images/search.png';
import imageprinter from 'src/images/printer.png';
import imageexcel from 'src/images/xlsicon.png';
import { getYearMonthArray } from 'src/utils';
import { ExportExcel } from 'src/utils/index';
import { useReactToPrint } from 'react-to-print';
import { columnsForSmartEclass, columnsForEnglishTap, columnsForBookOrder } from './comp/columns';

const { RangePicker } = DatePicker;
const { Option } = Select;
const DEFAULT_DATE_RANGE = [moment().subtract(1, 'months'), moment()];

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const { Title } = Typography;
const months = getYearMonthArray('2021-11');

const SEARCH_OPTIONS = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '지사',
    value: 'branch',
  },
  {
    label: '학교',
    value: 'school',
  },
  {
    label: '강사',
    value: 'name',
  },
];
const SEARCH_OPTIONS_ENGTAP = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '지사',
    value: 'branch',
  },
  {
    label: '학원',
    value: 'school',
  },
  {
    label: '강사',
    value: 'name',
  },
];

const PAGE_SIZE = 12;

//본사 주문 관리 페이지
const AdminAfterSchool = () => {
  const companyName = useSelector(classStoreData);
  const [invoiceVisible, setInvoiceVisible] = useState(false);
  const [deliveryVisible, setDeliveryVisible] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useState();
  const [checkRowList, setcheckRowList] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [page, setPage] = useState(1);

  const printRef = useRef();

  const history = useHistory();
  const [dateSearchOption, setDateSearchOption] = useState(DATE_SEARCH_OPTIONS_FOR_AFTER_SCHOOL[0].value);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
  const company_idx = userInfoVar()?.campus?.company?.idx;

  const { data, loading, refetch } = useQuery(
    dateSearchOption === -1 ? MY_FC_ORDER_LIST_ALL : dateSearchOption === 0 ? MY_FC_ORDER_LIST : MY_FC_ORDER_LIST_BY_DURAION,
    {
      variables:
        dateSearchOption === -1
          ? {
              company_idx,
              page,
              take: PAGE_SIZE,
              search_type: searchOption,
              search_value: searchValue,
            }
          : dateSearchOption === 0
          ? { ym: selectedMonth, company_idx }
          : { start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD'), company_idx },
      skip: !company_idx,
      fetchPolicy: 'no-cache',
    },
  );

  const refetchQueries = useMemo(() => {
    if (company_idx && dateRange && selectedMonth) {
      return [
        {
          query: dateSearchOption === -1 ? MY_FC_ORDER_LIST_ALL : dateSearchOption === 0 ? MY_FC_ORDER_LIST : MY_FC_ORDER_LIST_BY_DURAION,
          variables:
            dateSearchOption === -1
              ? {
                  company_idx,
                  page,
                  take: PAGE_SIZE,
                  search_type: searchOption,
                  search_value: searchValue,
                }
              : dateSearchOption === 0
              ? { ym: selectedMonth, company_idx }
              : { start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD'), company_idx },
        },
      ];
    }
    return [];
  }, [company_idx, dateRange, selectedMonth, dateSearchOption, page, searchOption, searchValue]);

  const [deleteOrders, { loading: loadingDelete }] = useMutation(DELETE_ORDERS, {
    refetchQueries,
    onCompleted: () => {
      Modal.info({ title: '삭제했습니다.' });
      refetch();
      setcheckRowList([]);
    },
    onError: () => {
      alert('오류가 발생했습니다.');
    },
  });

  const [updateStatus, { loading: loadingUpdate }] = useMutation(UPDATE_DELIVERY_STATUS, {
    refetchQueries,
    onCompleted: (res) => {
      const { message } = res.updateDeliveryStatus;
      Modal.info({
        title: message,
        onOk: () => {
          refetch();
          setInvoiceInfo(undefined);
          setInvoiceVisible(false);
        },
      });
    },
    onError: () => {
      alert('오류가 발생했습니다.');
    },
  });
  const [updateOrderConfirm, { loading: loadingOrderConfirm }] = useMutation(UPDATE_ORDER_PAY_CONFIRM, {
    refetchQueries,
    onCompleted: () => {
      Modal.info({
        title: '확인',
        onOk: () => {
          refetch();
        },
      });
    },
    onError: () => {
      alert('오류가 발생했습니다.');
    },
  });

  const [updateInvoiceNumber, { loading: loadingUpdateInvoiceNumber }] = useMutation(UPDATE_INVOICE_NUMBER, {
    refetchQueries,
    onCompleted: (res) => {
      Modal.info({
        title: '저장했습니다.',
      });
      refetch();
    },
    onError: () => {
      alert('오류가 발생했습니다.');
    },
  });

  const dataSource = useMemo(() => {
    const list = data?.MyFCOrderList || data?.MyFCOrderListByDuration;
    if (list && dateSearchOption !== -1) {
      return resolveList(list).filter((item2) => {
        if (searchValue !== '') {
          if (searchOption === 'branch') {
            return item2.branch.includes(searchValue);
          } else if (searchOption === 'school') {
            return item2.campus_name.includes(searchValue);
          } else if (searchOption === 'name') {
            return item2.buyer_name.includes(searchValue);
          } else {
            return item2.branch.includes(searchValue) || item2.campus_name.includes(searchValue) || item2.buyer_name.includes(searchValue);
          }
        }
        return true;
      });
    }
    return [];
  }, [data, searchValue, searchOption, dateSearchOption]);

  const { total, dataSourceAll } = useMemo(() => {
    if (dateSearchOption === -1) {
      const info = data?.MyFCOrderListAll;

      const list = info?.list;
      if (list) {
        return { total: info?.total || 0, dataSourceAll: resolveList(list, info.total, info.page) };
      }
    }
    return { total: 0, dataSourceAll: [] };
  }, [data, dateSearchOption]);
  console.log(dataSourceAll, 'dataSourceAll');
  function resolveList(list, total = 0, pageNumber = 1) {
    return list.map((item, key) => {
      const order_count = item?.order_list?.reduce((a, c) => a + parseInt(c.quantity), 0) || 0;
      console.log(order_count, 'order_count');
      const order_student_count =
        item?.order_list?.reduce((a, c) => {
          return a + (c.product_name.includes('학생용') ? parseInt(c.quantity) : 0);
        }, 0) || 0;
      const order_teacher_count =
        item?.order_list?.reduce((a, c) => {
          return a + (c.product_name.includes('교사용') ? parseInt(c.quantity) : 0);
        }, 0) || 0;
      const amount = item?.order_payment[0]?.amount;
      const pay_status = ['card', 'creditcard'].includes(item?.order_payment[0]?.pay_method) ? '카드완료' : '직접결제완료';
      return {
        key: `admin-order-${item.idx}`,
        no: total > 0 ? total - PAGE_SIZE * (pageNumber - 1) - key : list.length - key,
        campus_name: item?.campus?.name,
        branch: item?.campus?.type === '2' ? item?.campus?.name : item?.campus?.campus?.name,
        idate: moment(item?.idate).format('YYYY-MM-DD'),
        pay_date: moment(item?.order_payment[0]?.idate).format('YYYY-MM-DD'),
        count: order_count,
        order_student_count,
        order_teacher_count,
        amount,
        pay_status,
        //buyer_name: item?.order_payment[0].buyer_name,
        buyer_name: item?.user?.name,
        product_name: item?.order_payment[0].product_name,
        onViewInvoice: handleViewInvoice,
        handleUpdateOrderConfirm: handleUpdateOrderConfirm,
        invoice_info: {
          campus_name: item?.campus?.name,
          delivery_price: item?.order_payment[0]?.delivery_price,
          order_list: item?.order_list,
          idate: item?.idate,
          amount,
          order_payment: item?.order_payment[0],
        },
        delivery_status: item?.order_payment[0]?.delivery_status ? parseInt(item?.order_payment[0]?.delivery_status) : 0,
        handleUpdateInvoiceNumber,
        loadingUpdateInvoiceNumber,
      };
    });
  }

  function handleViewInvoice(info) {
    setInvoiceInfo(info);
    setInvoiceVisible(true);
  }

  function handleDisabledDate(current) {
    return current && current > moment().endOf('day');
  }

  function handleDelete() {
    if (checkRowList.length === 0) {
      Modal.info({ title: '삭제할 항목을 선택하세요.' });
      return;
    }
    Modal.confirm({
      title: '정말 삭제하시겠습니까?',
      onCancel: () => {
        //do nothing
      },
      onOk: () => {
        const idx_list = checkRowList.map((item) => {
          const [, , idx] = item.split('-');
          return parseInt(idx);
        });
        deleteOrders({ variables: { idx_list } });
      },
    });
  }

  function handleUpdateInvoice(order_payment_idx, status) {
    updateStatus({ variables: { order_payment_idx, status } });
  }
  function handleUpdateOrderConfirm(order_payment_idx, manual_pay_confirm_date) {
    updateOrderConfirm({ variables: { order_payment_idx, manual_pay_confirm_date } });
  }

  function handleUpdateInvoiceNumber(order_payment_idx, company_code, invoice_number) {
    if (!company_code) {
      //Modal.info({ title: '택배사를 선택하세요.' });
      alert('택배사를 선택하세요.');
      return;
    }
    if (!invoice_number) {
      //Modal.info({ title: '송장번호를 입력하세요.' });
      alert('송장번호를 입력하세요.');
      return;
    }

    updateInvoiceNumber({ variables: { order_payment_idx, company_code, invoice_number } });
  }

  const handlePrintReal = useReactToPrint({
    content: () => printRef.current,
  });

  function handlePrint() {
    if (dateSearchOption === -1) {
      Modal.info({ title: '기간을 선택하세요.' });
      return;
    }
    handlePrintReal();
  }

  function handleExportToExcel(e) {
    e.preventDefault();
    if (dateSearchOption === -1) {
      Modal.info({ title: '기간을 선택하세요.' });
      return;
    }
    const realColumns = columnsForSmartEclass;
    const fileName = `주문내역`;
    ExportExcel(realColumns, dataSource, fileName);
  }

  const type = userInfoVar()?.type;
  if (type && type !== 4) {
    history.replace('/');
  }
  return (
    <>
      <Col className="orderadmin-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <Row gutter={[24, 16]}>
          <Col className="orderadmin-wrapper-main-a" span={8} style={{ textAlign: 'left' }}>
            {/* <HeaderTitle level={4}>Purchase Invoice(주문 관리)</HeaderTitle> */}
            {companyName !== 'englishtap' && companyName !== 'pturnbookorder' && <BookOrderAdminMenuGroup currentMenu="order-admin" />}
          </Col>
          <Col className="orderadmin-imagesec" span={16} style={{ textAlign: 'left' }}>
            <Anchor>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                <img src={imageprinter} alt="print" />
              </a>
              <a href="/" onClick={handleExportToExcel}>
                <img src={imageexcel} alt="download" />
              </a>
            </Anchor>
          </Col>
        </Row>
        <MainBlock className="orderadmin-wrapper-mainblock">
          <Row gutter={[24, 16]} className="orderadmin-top-head">
            <Col className="orderadmin-top-title" span={8} style={{ textAlign: 'left' }}>
              <Title level={5}>주문 관리</Title>
            </Col>
            <Col span={16} style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <div className="purchase-select-option">
                <Select
                  style={{ marginRight: '5px' }}
                  placeholder="검색"
                  defaultValue=""
                  value={searchOption}
                  onChange={(v) => {
                    setSearchOption(v);
                    setPage(1);
                  }}
                  options={companyName === 'englishtap' ? SEARCH_OPTIONS_ENGTAP : SEARCH_OPTIONS}
                />
                <Input.Search
                  style={{ width: '150px', marginRight: 10 }}
                  placeholder={`search`}
                  maxLength={20}
                  onSearch={(v) => {
                    setSearchValue(v);
                    setPage(1);
                  }}
                />
                &nbsp; &nbsp;
                <Select
                  placeholder="선택"
                  value={dateSearchOption}
                  onChange={(v) => {
                    setDateSearchOption(v);
                    setPage(1);
                    setSearchValue('');
                  }}
                  style={{ width: 100 }}
                >
                  {DATE_SEARCH_OPTIONS_FOR_AFTER_SCHOOL.map((item) => (
                    <Option key={`search-month-option${item.value}`} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                {dateSearchOption !== -1 && (
                  <>
                    {dateSearchOption === 0 ? (
                      <Select placeholder="선택" value={selectedMonth} onChange={setSelectedMonth}>
                        {months &&
                          months.map((month, key) => (
                            <Option key={`search-month-option${key}`} value={month}>
                              {month}
                            </Option>
                          ))}
                      </Select>
                    ) : (
                      <RangePicker
                        value={dateRange}
                        allowClear={false}
                        disabledDate={handleDisabledDate}
                        onChange={(dates) => {
                          const [start, end] = dates;
                          const intervalDays = end.diff(start, 'days');
                          if (intervalDays > 365) {
                            alert('검색 기간은 최대 1년입니다.');
                            return;
                          }
                          setDateRange(dates);
                        }}
                      />
                    )}
                  </>
                )}
                &nbsp; &nbsp;
                <Button type="danger" style={{ background: '#ff4d4f' }} loading={loadingDelete} onClick={handleDelete}>
                  삭제
                </Button>
              </div>
            </Col>
          </Row>
          {dateSearchOption === -1 ? (
            <SmartEclassOrderListTableAll
              loading={loading}
              dataSource={dataSourceAll}
              checkRowList={checkRowList}
              setcheckRowList={setcheckRowList}
              columns={
                companyName === 'englishtap'
                  ? columnsForEnglishTap
                  : companyName === 'pturnbookorder'
                  ? columnsForBookOrder
                  : columnsForSmartEclass
              }
              pageSize={PAGE_SIZE}
              total={total}
              page={page}
              onPageChange={(p) => {
                setPage(p);
              }}
            />
          ) : (
            <SmartEclassOrderListTable
              loading={loading}
              dataSource={dataSource}
              checkRowList={checkRowList}
              setcheckRowList={setcheckRowList}
              prinfRef={printRef}
              columns={
                companyName === 'englishtap'
                  ? columnsForEnglishTap
                  : companyName === 'pturnbookorder'
                  ? columnsForBookOrder
                  : columnsForSmartEclass
              }
            />
          )}
        </MainBlock>
      </Col>
      <InvoiceModal
        visible={invoiceVisible}
        info={invoiceInfo}
        onCancel={() => {
          setInvoiceInfo(undefined);
          setInvoiceVisible(false);
        }}
        onViewDelivery={setDeliveryVisible}
        onUpdateInvoice={handleUpdateInvoice}
        loadingUpdate={loadingUpdate}
      />
      <DeliveryInfoModal
        visible={deliveryVisible}
        info={invoiceInfo}
        onCancel={() => {
          setDeliveryVisible(false);
        }}
      />
    </>
  );
};

export default AdminAfterSchool;
