import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Tooltip, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter, useHistory } from 'react-router';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_DATE_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { useQuery, useMutation } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import BackButton from 'src/components/common/BackButton';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import BookReportAnswerModal from 'src/components/Modal/BookReportAnswerModal';
import GeneratePdfButton from 'src/components/common/GeneratePdfButton';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const AICoachDetailsByDate = (props) => {
  const { idx } = useParams();
  const company_name = useSelector(classStoreData);
  const [ai_topic_user_assignments, setAssignedAITopics] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [currentChatList, setCurrentChatList] = useState([]);
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      page: 0,
    },
    'ai_date_details',
  );

  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  const { data, loading, refetch } = useQuery(AI_TOPIC_DATE_ASSIGNMENT_DETAILS, {
    variables: { idx: parseInt(idx) },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.aiTopicDateAssignmentDetails) {
      let ai_topic_date_assignmentData = JSON.parse(data?.aiTopicDateAssignmentDetails?.data || '{}');
      setAssignedAITopics(
        data?.aiTopicDateAssignmentDetails.ai_topic_user_assignments.map((item, index) => {
          return {
            ...item,
            start_time: item.start_time ? item.start_time.split('.')[0].replace('T', ' ') : undefined,
            end_time: item.end_time ? item.end_time.split('.')[0].replace('T', ' ') : undefined,
            ai_topic: {
              ...item.ai_topic,
              data: JSON.parse(item.ai_topic.data || '{}'),
              topic: ai_topic_date_assignmentData?.revisedPrompt || item.ai_topic.topic,
            },
            no: index + 1,
            start_date: data?.aiTopicDateAssignmentDetails?.start_date,
            rubric_data: JSON.parse(item.rubric_data),
            ai_request_responses: item.ai_request_responses.map((item) => {
              return {
                ...item,
                promt: item.prompt_text,
                content: item.completion_text,
                data: JSON.parse(item.data),
                created: item.idate.split('.')[0],
              };
            }),
          };
        }),
      );
      setSelectedDate(data?.aiTopicDateAssignmentDetails?.start_date?.substr(0, 10));
      setTotal(data?.aiTopicDateAssignmentDetails.ai_topic_user_assignments.length);
    }
  }, [data]);
  function calculateTimeDifference(date1, date2) {
    if (!date1 || !date2) {
      return '';
    }
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    const timeDifference = Math.abs(endDate - startDate);

    const seconds = String(Math.floor((timeDifference / 1000) % 60)).padStart(2, '0');
    const minutes = String(Math.floor((timeDifference / (1000 * 60)) % 60)).padStart(2, '0');
    const hours = String(Math.floor((timeDifference / (1000 * 60 * 60)) % 24)).padStart(2, '0');

    return (hours > 0 ? hours + ':' : '') + minutes + ':' + seconds;
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    // {
    //   title: 'A. Date',
    //   dataIndex: 'ai_topic_date_assignment',
    //   align: 'center',
    //   key: 'date',
    //   width: 100,
    //   render: (text, record) => {
    //     return <Space size="middle">{record.start_date.substr(0, 10)}</Space>;
    //   },
    // },
    {
      title: 'Student',
      dataIndex: 'user',
      key: 'user',
      // width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="middle">
            <span style={{ fontWeight: 'bold', color: 'blue' }}>{record.user.name + '(' + record.user.id + ')'}</span>
          </Space>
        );
      },
    },

    {
      title: 'Completion Time',
      dataIndex: 'end_time',
      key: 'end_time',
      align: 'center',
      render: (text) => {
        return <Space size="middle">{text}</Space>;
      },
    },
    {
      title: 'Learning Time',
      dataIndex: 'end_time',
      key: 'end_time',
      align: 'center',
      render: (text, record) => {
        console.log(calculateTimeDifference(record.start_time, text), record.start_time, text);
        return <Space size="middle">{calculateTimeDifference(record.start_time, text)}</Space>;
      },
    },
    {
      title: 'Rubric',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record.status < 1) {
          return <Space size="middle">-</Space>;
        }

        if (record.rubric_data?.pdf_url || record.rubric_data?.pdf_url_teacher) {
          return <Space size="middle">{record.rubric_data?.score}</Space>;
        }
        const txt = `${record.rubric_data?.score || (company_name === 'rise' ? '' : '-')}${
          company_name === 'rise' && !record.rubric_data?.score ? '' : ':'
        }`;
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                props.history.push('/aicoach/results/rubric/' + record.idx);
              }}
            >
              {txt}Edit
            </Button>
          </Space>
        );
      },
    },
    {
      title: 'Original',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);

                  setCurrentChatList(
                    record.ai_request_responses
                      .filter((item) => item?.data?.type === 'check' || item?.data?.type === 'comment' || item?.data?.type === 'revise')
                      .map((item) => {
                        return {
                          ...item,
                          content: item?.data?.currentText,
                        };
                      }),
                  );

                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Check',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList(record.ai_request_responses.filter((item) => item?.data?.type === 'check'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Feedback',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList(record.ai_request_responses.filter((item) => item?.data?.type === 'comment'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Rewrite',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList([
                    {
                      content: record.answer,
                      prompt: record.answer,
                      idate: record.idate.split('.')[0],
                      data: '{}',
                    },
                  ]);
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'ACC/WPM',
      key: 'wpm',
      dataIndex: 'wpm',
      width: 100,
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => openRecordingDataModal({ ...record, accuracy: `${record.score}%` }, record?.user?.name)}>
                {record.score}/{text}
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Std.PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        if (record.status < 1) {
          return <span>-</span>;
        } else {
          return (
            <Space size="middle">
              <GeneratePdfButton
                saveData={async (url) => {
                  await handleGeneratePdf(record.idx, { ...record.rubric_data, pdf_url: url });
                }}
                url={`https://culp.cloubot.com/aicoach/report/generate/${record.idx}?pdfType=student`}
                pdfUrl={record.rubric_data?.pdf_url}
                filename={`-writing-coach-report-result-${record.idx}`}
                isEditUrl={`/aicoach/report/generate/${record.idx}?pdfType=student`}
              />
            </Space>
          );
        }
      },
    },
    {
      title: 'T.PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        if (record.status < 1) {
          return <span>-</span>;
        } else {
          let commentFull = record.ai_request_responses.filter((item) => item?.data?.type === 'comment-full');
          return (
            <Space size="middle">
              <GeneratePdfButton
                saveData={async (url) => {
                  await handleGeneratePdf(record.idx, { ...record.rubric_data, pdf_url_teacher: url });
                }}
                url={`https://culp.cloubot.com/aicoach/report/generate/${record.idx}?pdfType=teacher&type=${
                  commentFull.length > 0 ? 'comment-full' : 'comment'
                }`}
                pdfUrl={record.rubric_data?.pdf_url_teacher}
                filename={`-writing-coach-report-result-full-${record.idx}`}
                isEditUrl={`/aicoach/report/generate/${record.idx}?pdfType=teacher&type=${
                  commentFull.length > 0 ? 'comment-full' : 'comment'
                }`}
              />
            </Space>
          );
        }
      },
    },
  ];

  const handleGeneratePdf = async (idx, data) => {
    await updateAITopicUserAssignment({
      variables: {
        idx,
        rubric_data: JSON.stringify(data),
      },
    });
    refetch();
  };

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  const EllipsisText = (text) => {
    const words = text ? text.split(' ') : [];

    if (words.length <= 5) {
      return <span>{text}</span>;
    }

    const truncatedText = words.slice(0, 5).join(' ') + ' ...';

    return (
      <Tooltip title={text} color="#fff">
        <span>{truncatedText}</span>
      </Tooltip>
    );
  };
  console.log(ai_topic_user_assignments);
  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          {/* <Row gutter={[24, 16]} style={{ margin: 0 }}> */}
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
            <div style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'end', display: 'flex' }}>
              <Title level={4}>AI Writing Coach Result Page</Title>
              {ai_topic_user_assignments && (
                <div style={{ fontSize: '16px', padding: '0 10px', display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#0070C0' }}>Assigned Date :</span>
                  &nbsp;
                  {selectedDate}
                  &nbsp; &nbsp;
                  <span style={{ color: '#0070C0' }}>Prompt :</span>
                  &nbsp;
                  {EllipsisText(ai_topic_user_assignments[0]?.ai_topic?.data?.book?.prompt)}
                  &nbsp; &nbsp;
                  <span style={{ color: '#0070C0', fontSize: '16px' }}>Class :</span>
                  &nbsp; &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      minWidth: '200px',
                      padding: '0 5px',
                      height: '32px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#000000',
                      backgroundColor: '#F5F9FD',
                      fontSize: '16px',
                    }}
                  >
                    {ai_topic_user_assignments[0]?.class.name}
                  </div>
                </div>
              )}
            </div>
            {/* <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            > */}
            <div style={{ margin: 'auto 0' }}>
              <BackButton />
            </div>
            {/* </Col> */}
          </div>
          {/* </Row> */}
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={ai_topic_user_assignments}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
          />
          <span style={{ position: 'absolute', bottom: '10px', right: '10px', fontSize: '14px', color: '#FF0000' }}>
            * Rubric is optional. If you don't want to use it, please ignore it.
          </span>
        </MainBlock>
        {viewAnswerModal && (
          <BookReportAnswerModal
            visible={viewAnswerModal}
            chatList={currentChatList}
            handleCancel={() => {
              setViewAnswerModal(false);
            }}
            topic={topic}
          />
        )}
        <RecordingDataModal
          data={{ ...recordingData, currentUserName, className: '' }}
          isShow={isRecordingVisible}
          onPrint={null}
          componentRef={null}
          onOk={handleOkForRecordingData}
          hidePrint={true}
        />
      </Col>
    </>
  );
};

export default withRouter(AICoachDetailsByDate);
