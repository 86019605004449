import React, { useState } from 'react';
import { Col, Row, Radio, Button } from 'antd';
import {
  BookCard,
  BookCover,
  BookMeta,
  BookTitle,
  BOOK_COL_STYLE,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  THUMBNAIL_BASE_URL,
} from './CommonComp';
import styled from 'styled-components';
const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;
const GROUPS = ['SB', 'WB'];
const VOLUMES = [1, 2, 3, 4];
const EBOOK_BASE_URL = 'https://online.flipbuilder.com/sangbong91/';
const SB_URL = ['yxwz', 'vhtl', 'wont', 'lvid'];
const WB_URL = ['wghf', 'tfej', 'edgm', 'hyxu'];
const PPT_URL = [
  'https://cdn.cloubot.com/class-boom/22a3a9f6-ea42-496e-8788-7093043d9eefHello_Phonics_Vol.1_교사용 ppt.zip',
  'https://cdn.cloubot.com/class-boom/baea2a30-efef-4d04-bc8c-c61f6b676ab0Hello_Phonics_Vol.2_교사용 ppt.zip',
  'https://cdn.cloubot.com/class-boom/44df67c8-f021-4375-b7de-5375d8ed8f00Hello_Phonics_Vol.3_교사용 ppt.zip',
  'https://cdn.cloubot.com/class-boom/745c9836-4251-4916-800c-25259fbef339Hello_Phonics_Vol.4_교사용 ppt.zip',
];
const handleSetupFileDownload = (volume) => {
  //window.open(`http://admin.smart-eclass.co.kr/include/common/downFile3.php?filename=kids_tap_${volume}.zip`);
  window.open(`${SMART_ECLASS_CDN_BASE_URL}common/kids_tap/kids_tap_${volume}.zip`);
};

// const BOOKS_NAME = ['PH_', , 'PH_R_'];

const HelloPhonics = () => {
  const [groupNo, setGroupNo] = useState(0);
  const handleChangeGroup = (evt) => {
    const {
      target: { value },
    } = evt;
    setGroupNo(value);
  };
const onClickBookCard = (key) => {
  window.open(
    // groupNo === 2? `http://admin.kidstapenglish.com/flipBook/Phonics/${BOOKS_NAME[groupNo]}${v}/`:
    `${EBOOK_BASE_URL}${(groupNo === 0 ? SB_URL : WB_URL)[key]}`,
    '_blank',
    'width=1400, height=900',
  );
}
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <Radio.Group
        value={groupNo}
        style={{ marginBottom: 16, marginLeft: 16 }}
        buttonStyle="solid"
        onChange={handleChangeGroup}
        size="middle"
      >
        {GROUPS.map((groupName, key) => (
          <Radio.Button key={`radio-level-key${key}`} value={key} style={{ width: 160, textAlign: 'center' }}>
            {groupName}
          </Radio.Button>
        ))}
      </Radio.Group>
      <Row gutter={[16, 16]} style={{ margin: '0 10px' }}>
        {VOLUMES.map((v, key) => (
          <Col span={4} key={`col-${key}`} style={{ ...BOOK_COL_STYLE, borderRight: `${v === 4 ? '1px solid #dedede' : ''}` }}>
            <BookTitle level={5}>{groupNo === 0 ? `Hello Phonics ${key + 1} SB` : `Hello Phonics ${key + 1} WB`}</BookTitle>
            <BookCard
              style={{ marginLeft: 5, marginRight: 5 }}
              // onClick={() => {
              //   onClickBookCard(key)
              // }}
              cover={
                <>
                <BookCover
                  style={{
                    backgroundImage: `url(${THUMBNAIL_BASE_URL.replace('SM', 'HPH')}L0V${key + 1}${groupNo === 0 ? '' : '_WB'}.jpg)`,
                  }}
                  />
                  { <EbookLinkGroup onlyEbook={groupNo !== 0} volume={v} handleClick={onClickBookCard} id={key} />} 
                  </>
              }
            >
              {/* {groupNo === 0 && <BookMeta description={<EbookLinkGroup volume={v} />} />} */}
            </BookCard>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default HelloPhonics;

const EbookLinkGroup = ({ volume, handleClick, id,onlyEbook }) => {
  return (
    <ButtonWrap>
      <Button
              onClick={() => {
                handleClick(id);
              }}
              style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.9em', lineHeight: '1' }}
            >
              e Book
            </Button>
          {!onlyEbook&&  <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          window.open(PPT_URL[volume-1]);
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.9em', lineHeight: '1' }}
        >
        PPT <br />
        Download
      </Button>
      <Button
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.9em' }}
        onClick={(e) => {
          e.stopPropagation();
          const culp_token = window.localStorage.getItem('culp_token');
          window.open(`${STUDY_TOOL_HOST}/HPH-L0V${volume}U1D1?token=${culp_token}`, '_blank');
        }}
      >
        E-learning
      </Button>
        </>}
    </ButtonWrap>
  );
};
const ButtonWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  top: 0;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }
`;
