import { gql } from '@apollo/client';

export const GET_FC_BOOK_ORDER_AND_RETURN_LIST = gql`
  query getAdminBookReturnList($ym: String!, $company_idx: Int!) {
    getAdminBookReturnList(ym: $ym, company_idx: $company_idx) {
      idx
      title
      idate
      check_date
      stat
      user {
        name
      }
      campus {
        name
        campus {
          idx
          name
        }
      }
      book_return_list {
        idx
        product_code
        product_name
        quantity
      }
    }

    MyFCOrderList(ym: $ym, company_idx: $company_idx) {
      idx
      idate
      user {
        name
      }
      order_payment {
        idx
        product_name
        buyer_name
        delivery_status
        invoice_check_date
        pay_method
        manual_pay_confirm_date
      }
      campus {
        name
        campus {
          idx
          name
        }
      }
      order_list {
        product_name
        product_code
        quantity
      }
    }
  }
`;

export const GET_FC_BOOK_ORDER_AND_RETURN_LIST_BY_DURATION = gql`
  query getAdminBookReturnListByDuration($start: String!, $end: String!, $company_idx: Int!) {
    getAdminBookReturnListByDuration(start: $start, end: $end, company_idx: $company_idx) {
      idx
      title
      idate
      check_date
      stat
      user {
        name
      }
      campus {
        name
        campus {
          idx
          name
        }
      }
      book_return_list {
        idx
        product_code
        product_name
        quantity
      }
    }

    MyFCOrderListByDuration(start: $start, end: $end, company_idx: $company_idx) {
      idx
      idate
      user {
        name
      }
      order_payment {
        idx
        product_name
        buyer_name
        delivery_status
        invoice_check_date
        pay_method
        manual_pay_confirm_date
      }
      campus {
        name
        campus {
          idx
          name
        }
      }
      order_list {
        product_name
        product_code
        quantity
      }
    }
  }
`;
