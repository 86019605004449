import React from 'react';

import DefaultLayout from 'src/layouts/DefaultLayout';
import DashBoard from 'src/pages/DashBoard';
import Branches from 'src/pages/Branches';
import Campuses from 'src/pages/Campuses';
import SMSStatic from 'src/pages/SMSstatic';
import SMSBranchStatic from 'src/pages/SMSBranchstatic';
import OrderAdmin from 'src/pages/Order/Admin';
import BookReturnAdmin from 'src/pages/BookReturn/Admin';
import OrderShipping from 'src/pages/Order/Shipping';
import SettingPayAdmin from 'src/pages/Settings/Pay/Admin';
import Ebook from 'src/pages/Ebook';
import Elibrary from 'src/pages/Elibrary';
import Test from 'src/pages/Test';
import Manage from 'src/pages/Test/Manage';
import TestCategoryManage from 'src/pages/Test/Category';
import TestDetails from 'src/pages/Test/TestDetails';
import Syllabus from 'src/pages/Test/Syllabus';
import SyllabusAssign from 'src/pages/Test/Syllabus/Assign';
import { SyllabusAssignedDetail, SyllabusEdit } from 'src/components/Test';
import QuestionExtractor from 'src/pages/QuestionExtractor';
import TestDocumentList from 'src/pages/QuestionExtractor/list';
import Recording from 'src/pages/Recording';
import RecordingStart from 'src/pages/Recording/RecordingStart';
import AITopic from 'src/pages/AITopic';
import ManageAITopic from 'src/pages/AITopic/Manage';
import DetailsByDate from 'src/pages/AITopic/detailsByDate';
import AITalkingTutorLayout from 'src/layouts/AITalkingTutorLayout';
import AICoachLayout from 'src/layouts/AICoachLayout';
import WritingCoachTopicList from 'src/pages/AiCoach/Assign/TopicList';
import AssignLessonPage from 'src/pages/Offline/AssignLessonPage';

import OfflineSyllabus from 'src/pages/Offline';
import CampusPageView from 'src/pages/Offline/CampusPageView';
import CampusDashboardPageView from 'src/pages/Offline/CampusDashboardPageView';

import OfflineAddSyllabus from 'src/pages/Offline/AddSyllabusPage';
import OfflineEditSyllabus from 'src/pages/Offline/EditSyllabusPage';

import OfflineEditSyllabusAdminView from 'src/pages/Offline/EditSyllabusPageAdminView';

import OfflineEditSyllabusUpdate from 'src/pages/Offline/EditSyllabusPageUpdate';

import OfflineEditSyllabusView from 'src/pages/Offline/EditSyllabusViewPage';

import OfflineTextBookList from 'src/pages/Offline/TextBookListPage';
import OfflineAssign from 'src/pages/Offline/AssignOfflinePage';

import OfflineAssignEdit from 'src/pages/Offline/AssignOfflineEditPage';

import AssignLessonPlanPage from 'src/pages/Offline/AssignLessonPlanPage';

import PhonicsElibraryAssign from 'src/pages/PhonicsAssign';
import PhonicsManageAssignBooks from 'src/pages/PhonicsAssign/Manage';
import PhonicsManageAssignBooksEdit from 'src/pages/PhonicsAssign/Manageedit';
import PhonicsResults from 'src/pages/PhonicsAssign/Result';
import PhonicsResultClass from 'src/pages/PhonicsAssign/ResultClass';
import PhonicsResultClassStudent from 'src/pages/PhonicsAssign/PhonicsResultClassStudent';
import PhonicsResultsGroup from 'src/pages/PhonicsAssign/Resultgroup';

import AiTalkingTutor from 'src/pages/AITalkingTutor/Assign/TopicList';
import AiContentList from 'src/pages/AITalkingTutor/Assign/ContentList';
import AddAITalkingTutor from 'src/pages/AITalkingTutor/AddGroupList';
import EditTalkingTutor from 'src/pages/AITalkingTutor/EditGroupList';

import Board from 'src/pages/Board';
import BoardDetail from 'src/pages/Board/BoardDetail';
import Folder from 'src/pages/Folder';
import OpenAiUsage from 'src/pages/OpenAiUsage';
import BookInfo from 'src/pages/Settings/BookInfo';
import Credits from 'src/pages/Credits';
import IframePage from 'src/pages/Iframe';
import Employee from 'src/pages/Settings/Employee';
import MetaExamStatics from 'src/pages/MetaExam/MetaExamStatics';
import MetaExamStaticsReadOnly from 'src/pages/MetaExam/MetaExamStaticsReadOnly';
import MetaExamStaticsDetails from 'src/pages/MetaExam/MetaExamStaticsDetails';
import Contents from 'src/pages/Contents';
import LessonList from 'src/pages/LessonList';
import CampusMoveStudent from 'src/pages/Campuses/MoveStudent';

const HeadManager = () => {
  return (
    <>
      <DefaultLayout exact path="/dashboard" component={DashBoard} />
      <DefaultLayout exact path="/branch" component={Branches} />
      <DefaultLayout exact path="/campus" component={Campuses} />
      <DefaultLayout exact path="/smsstatic" component={SMSStatic} />
      <DefaultLayout exact path="/smsstatic/:idx" component={SMSBranchStatic} />
      <DefaultLayout exact path="/order/admin" component={OrderAdmin} />
      <DefaultLayout exact path="/book-return/admin" component={BookReturnAdmin} />
      <DefaultLayout exact path="/order/shipping" component={OrderShipping} />
      <DefaultLayout exact path="/setting/pay" component={SettingPayAdmin} />
      <DefaultLayout exact path="/ebook" component={Ebook} />
      <DefaultLayout exact path="/ebook/:rootbookcode" component={Ebook} />
      <DefaultLayout exact path="/test/manage/:type" component={Test} />
      <DefaultLayout exact path="/test/manage/action/:type/:id" component={Manage} />
      <DefaultLayout exact path="/test/manage/action/:type/" component={Manage} />
      <DefaultLayout exact path="/test/category/list" component={TestCategoryManage} />
      <DefaultLayout exact path="/test/:id" component={TestDetails} />
      <DefaultLayout exact path="/test/new/:id/:type" component={TestDetails} />
      <DefaultLayout exact path="/test/syllabus/assigned/:id" component={SyllabusAssignedDetail} />
      <DefaultLayout exact path="/test/syllabus/:type" component={Syllabus} />
      <DefaultLayout exact path="/test/syllabus/:id/assign" component={SyllabusAssign} />
      <DefaultLayout exact path="/test/syllabus/edit/:idx" component={SyllabusEdit} />
      <DefaultLayout exact path="/question/extractor/add" component={QuestionExtractor} />
      <DefaultLayout exact path="/question/extractor/edit/:id" component={QuestionExtractor} />
      <DefaultLayout exact path="/question/extractor/list" component={TestDocumentList} />
      <DefaultLayout exact path="/recording" component={Recording} />
      <DefaultLayout exact path="/recording/start" component={RecordingStart} />
      <DefaultLayout exact path="/ai-topic/:type" component={AITopic} />
      <DefaultLayout exact path="/ai-topic/manage/:type" component={ManageAITopic} />
      <DefaultLayout exact path="/ai-topic/manage/:type/:id/:class_idx?/:title?" component={ManageAITopic} />
      <DefaultLayout exact path="/ai-topic/details-by-date/:idx" component={DetailsByDate} />
      <DefaultLayout exact path="/contents" component={Contents} />
      <DefaultLayout exact path="/lesson-list" component={LessonList} />
      <DefaultLayout exact path="/offline/lesson/assign" component={AssignLessonPage} />
      <DefaultLayout exact path="/offline/lesson/create/:programidx/:programlevel" component={OfflineSyllabus} />
      <DefaultLayout exact path="/offline/lesson/view/:programidx/:programlevel" component={CampusPageView} />
      <DefaultLayout exact path="/offline/lesson/dashboardview/:programidx/:programlevel" component={CampusDashboardPageView} />
      <DefaultLayout exact path="/offline/add/syllabus" component={OfflineAddSyllabus} />
      <DefaultLayout exact path="/offline/edit/syllabus/:bookprogramname/:bookprogramlevel/view" component={OfflineEditSyllabus} />
      <DefaultLayout
        exact
        path="/offline/adminedit/syllabus/:bookprogramname/:bookprogramlevel/adminview"
        component={OfflineEditSyllabusAdminView}
      />
      <DefaultLayout exact path="/offline/edit/syllabus/:bookprogramname/:bookprogramlevel/update" component={OfflineEditSyllabusUpdate} />
      <DefaultLayout exact path="/offline/editview/syllabus/:bookprogramname/:bookprogramlevel" component={OfflineEditSyllabusView} />
      <DefaultLayout exact path="/offline/textbook/list" component={OfflineTextBookList} />
      <DefaultLayout exact path="/offline/assign/:booklevel/:booktitle/:bookprogram/:bookrowno" component={OfflineAssign} />
      <DefaultLayout
        exact
        path="/offline/assign/edit/:booklevel/:bookprogram/:bookclassidx/:rowbookclassidx/:bookrono"
        component={OfflineAssignEdit}
      />
      <DefaultLayout exact path="/offline/lesson/plan" component={AssignLessonPlanPage} />
      <DefaultLayout exact path="/learningschedule/levels" component={PhonicsElibraryAssign} />
      <DefaultLayout exact path="/learningschedule/levels/manage/action/:type" component={PhonicsManageAssignBooks} />
      <DefaultLayout
        exact
        path="/learningschedule/levels/manage/action/edit/:type/:class_idx/:title/:key"
        component={PhonicsManageAssignBooksEdit}
      />
      <DefaultLayout exact path="/learningschedule/results" component={PhonicsResults} />
      <DefaultLayout exact path="/learningschedule/results-class" component={PhonicsResultClass} />
      <DefaultLayout exact path="/learningschedule/results-class/:class_idx/:user_idx" component={PhonicsResultClassStudent} />
      <DefaultLayout exact path="/mypage/results-class/:class_idx/:user_idx" component={PhonicsResultClassStudent} />
      <DefaultLayout exact path="/learningschedule/results/:book_idx/:start_date/:class_idx" component={PhonicsResultsGroup} />
      <DefaultLayout exact path="/board/:name" component={Board} />
      <DefaultLayout exact path="/board/:name/:id" component={BoardDetail} />
      <DefaultLayout exact path="/folder" component={Folder} />
      <DefaultLayout exact path="/openAiUsage" component={OpenAiUsage} />
      <DefaultLayout exact path="/settings" component={BookInfo} />
      <DefaultLayout exact path="/settings/account3" component={BookInfo} />
      <DefaultLayout exact path="/credit" component={Credits} />
      <DefaultLayout exact path="/i-frame/:url" component={IframePage} isHeaderStatic={true} />
      <DefaultLayout exact path="/settings/employee" component={Employee} />

      <DefaultLayout exact path="/meta-statics" component={MetaExamStatics} />
      <DefaultLayout exact path="/meta-statics/all" component={MetaExamStaticsReadOnly} />
      <DefaultLayout exact path="/meta-statics/details/:type/:campus_idx/:value" component={MetaExamStaticsDetails} />

      <AICoachLayout exact path="/aicoach/topiclist" isHeaderStatic={false} component={WritingCoachTopicList} />
      {/* AI-talking tutor */}
      <AITalkingTutorLayout exact path="/ai-talking-tutor/topiclist" isHeaderStatic={false} component={AiTalkingTutor} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/contentlist" isHeaderStatic={false} component={AiContentList} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/add" isHeaderStatic={false} component={AddAITalkingTutor} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/edit/:idx" isHeaderStatic={false} component={EditTalkingTutor} />
      <DefaultLayout exact path="/campus/move-student" component={CampusMoveStudent} />

      <DefaultLayout exact path="/" component={DashBoard} />
    </>
  );
};

export default HeadManager;
