import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const WritingReportCoachMenuGroup = ({ currentMenu }) => {
  const history = useHistory();
  const companyName = useSelector(classStoreData);
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
      
      {companyName!=='rise'&&<>
      <MenuButton
        type={`${currentMenu === 'assign' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/aicoach/topiclist');
        }}
        >
        Assign
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'list' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/aicoach/list');
        }}
        >
        Assigned Group List
      </MenuButton>
      &nbsp;
        </>}
      <MenuButton
        type={`${currentMenu === 'date-list' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/aicoach/date-list');
        }}
      >
        Assigned List & Results
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'result' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/aicoach/result');
        }}
      >
        Total Homework Results
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'extreaders' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/aicoach/results/extreaders');
        }}
      >
        Individual Results
      </MenuButton>
    </div>
  );
};

export default WritingReportCoachMenuGroup;
