import styled,{ createGlobalStyle } from 'styled-components';
import BSRe from "../../fonts/BalsamiqSans-Regular.eot";
import BSRw2 from "../../fonts/BalsamiqSans-Regular.woff2";
import BSRw from "../../fonts/BalsamiqSans-Regular.woff";
import BSRt from "../../fonts/BalsamiqSans-Regular.ttf"
import BSBe from "../../fonts/BalsamiqSans-Bold.eot";
import BSBw2 from "../../fonts/BalsamiqSans-Bold.woff2";
import BSBw from "../../fonts/BalsamiqSans-Bold.woff";
import BSBt from "../../fonts/BalsamiqSans-Bold.ttf";
const device = {
    smallDesktop: `(max-width: 1280px)`,
    tablet: `(max-width: 780px)`,
  }
export const fontStyle = createGlobalStyle`
@font-face{
	font-family: "BalsamiqSans";
	font-weight: 400;
	font-style: normal;
	src: url(${BSRe});
	src: url(${BSRw2}) format("woff2"),
			 url(${BSRw}) format("woff"),
			 url(${BSRt}) format("truetype");
}
@font-face{
	font-family: "BalsamiqSans";
	font-weight: 700;
	font-style: normal;
	src: url(${BSBe}) format("embedded-opentype");
	src: url(${BSBw2}) format("woff2"),
			 url(${BSBw}) format("woff"),
			 url(${BSBt}) format("truetype");
}
`;
export const RowFlex = styled.div`
  display:flex;
  flex-direction: row;
  `;
export const ColumnFlex = styled.div`
  display:flex;
  flex-direction: column;
  `;
export const BorderRowBox = styled(RowFlex)`
    border-radius: 1.2rem;
    border: 4px solid #ffb000;
    background-color: #fff;
`;
export const BorderColumnBox = styled(ColumnFlex)`
    border-radius: 1.2rem;
    border: 4px solid #ffb000;
    background-color: #fff;
`;
export const Root= styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: 'BalsamiqSans';
`;
export const Main= styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 85vw;
    height: calc(100vh - 120px);
    border-radius: 1rem;
    background-color: #feca57;
    box-shadow: 0 0 12px 6px rgba(114, 114, 114, 0.2);
    margin-bottom: 10px;
    margin: 0;
    padding: 0;
    @media (max-height: 720px){
        height: calc(100vh - 110px);

    }
    @media (max-height: 600px) and (orientation: landscape) {
        height: calc(100vh - 80px);
    }
`;
export const MainInnerWrap = styled.div`
  width: 95%;
  height: 95%;
  margin: 20px 0;
  @media (max-height: 768px){
    margin: 15px 0;
  }
  @media (max-height: 500px){
  margin: 10px 0;
}
`;
export const BookListWrapper= styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    margin: 8rem 0 8rem;
`;
export const BookList= styled.ul`
    display: grid;
    grid-template: repeat(2, auto) / repeat(4, auto);
    grid-row-gap: calc(calc(64vh / 768) * 100);
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    `;
export const BookCover= styled.li`
    display: grid;
    grid-template-rows: calc(calc(168vh / 768) * 100) auto;
    grid-row-gap: 2.4rem;
    justify-items: center;
    position: relative;
    list-style: none;
    cursor: pointer;
    ::after {
        content: "";
        display: block;
        position: absolute;
        top: calc(calc(168vh / 768) * 100);
        left: 0;
        right: 0;
        height: 1.2rem;
        max-height: 12px;
        background: url(https://cdn.cloubot.com/PM/image/bg_booklist.png) repeat-x 0 100%;
    }
`;
export const UnitWrapper= styled.div`
    overflow-x: auto;
    overflow-y: auto;
    align-items: flex-start;
    width: 82vw;
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 20px 0;
    padding: 0;
`;
export const UnitTable= styled.table`
    border-spacing: 0.8rem;
    border-collapse: separate;
    margin: 0;
    padding: 0;
    width: 100%;
`;
export const UnitTD= styled.td`
    padding: 7px 14px;
    border-radius: 10px;
    `;
export const UnitTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    @media (max-height: 768px){
        font-size: 16px;
        margin-bottom: 8px;
    }
    @media (max-height: 500px){
        font-size: 13px;
        margin-bottom: 6px;
}
`;
export const Button1= styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props)=>props.height?'140px':'50px'};
    height: ${(props)=>props.height?'140px':'50px'};
    background: url(/images/pm/btn_type1.svg) no-repeat center / contain;
    cursor: pointer;
    border: none;
    font-size: 1.4rem;
    @media (max-height: 768px){
        width: ${(props)=>props.height?'120px':'45px'};
        height: ${(props)=>props.height?'120px':'45px'};
        font-size: 1.3rem;
    }
    @media (max-height: 500px){
        width: ${(props)=>props.height?'100px':'35px'};
        height: ${(props)=>props.height?'100px':'35px'};
        font-size: 1.2rem;
}
`;
export const Button2= styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #00d2d3;
    border: 2px solid #fff;
    width: 94px;
    height: 42px;
    border-radius: 12px;
    box-shadow: 0 4px 0 rgba(0, 143, 144, 0.8);
    cursor: pointer;
    @media (max-height: 768px){
        width: 84px;
        height: 37px;
        border-radius: 10px;
        box-shadow: 0 3px 0 rgba(0, 143, 144, 0.8);
        
    }
    @media (max-height: 500px){
        width: 67px;
        height: 30px;
        border-radius: 8px;
        box-shadow: 0 2px 0 rgba(0, 143, 144, 0.8);

    }
`;
export const UnitHeadTH= styled.th`
    height: calc(calc(48vh / 768) * 100);
    border-radius: 0.7rem;
    font-weight: 800;
    font-size: 1.6rem;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 0;
    padding: 0;
    font-size: 16px;
`;
export const UnitBodyTH= styled.th`
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #2b89e7;
    height: calc(calc(80vh / 900) * 100);
    padding: 7px 14px;
    border-radius: 10px;
    margin: 0;
`;
export const UnitLayoutWrapper= styled.div`
    margin:20px 0;
`;
export const HeadUnitTitle = styled(RowFlex)`
    font-size: 30px;
    color:#fff;
    font-weight: bold;
    align-items: center;
    @media (max-height: 768px){
        font-size: 25px;
    }
    @media (max-height: 500px){
        font-size: 23px;
    }
`;
export const UnitButton= styled(RowFlex)`
    align-self: baseline;
    padding-top: 4px;
    margin-right: 8px;
    padding: 4px 12px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    background-color: #ff8a00;
`;
export const StageDirection= styled(RowFlex)`
    font-size:26px;
    align-items: center;
    font-weight: bold;
    margin-bottom: 1.2rem;
    @media (max-height: 768px){
        font-size:23px;
        margin-bottom: 1rem;
        
    }
    @media (max-height: 500px){
        
        font-size:20px;
        margin-bottom: 0.7rem;
}
`;
export const PopUpWrap= styled(RowFlex)`
    /* display: none; */
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.7);
    z-index: 1000;
    `;
    export const PopUpImgWordWrap = styled(ColumnFlex)`
    padding: 12px;
    border-radius: 16px;
    background-color: #fff;
    `;
    export const PopUpBorder = styled(ColumnFlex)`
    border: 6px solid #ee7470;
    border-radius: 16px;
    position: relative;
    padding: 24px;
    flex: 1 1;
    align-items:center;
    `;
    export const PopUpImgBox = styled.p`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    align-self: center;
    flex: 1 1;
    border-radius: 1.6rem;
    border: .3rem solid #eee;
    `;
    export const PopUpImg = styled.img`
    height: 40vh;
    `;
    export const PopUpWordBox = styled.p`
    font-weight: 600;
    font-size: 48px;
    margin: 0;
    padding: 0;
    @media (max-height: 500px){
        font-size: 30px;
    }
    `;
export const PopUpRed= styled(RowFlex)`
    height: calc(100% - 160px) !important;
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    width: 120.83333vh;
    border: 6px solid #ee7470;
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
    @media (max-height: 768px){
        height: calc(100% - 110px) !important;
    }
    @media (max-height: 500px){
        height: calc(100% - 80px) !important;
    }
    `;
export const PopUpHeader = styled.div`
    padding: 1.4rem;
	font-size: 1.6rem;
	text-align: center;
	color: #fff;
	background: #ee7470;
    @media (max-height: 768px){
        padding: 1.1rem;
        font-size: 1.3rem;
    }
    @media (max-height: 500px){
        padding: 0.8rem;
        font-size: 1rem;
}
`;
export const PopUpContents= styled.div`
    position: relative;
    margin: 20px 10px;
    height: 100%;
    `;
export const PopUpInnerWrap= styled(RowFlex)`
    position: absolute;
    display: flex; 
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    `;
export const PopupSolveWrap= styled.div`
    font-size: 10px;
    font-family: "BalsamiqSans";
    user-select: none;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.7);
    z-index: 1000;
    display: flex;
    & .popup_goodjob_wrap {
        font-size: 10px;
        font-family: "BalsamiqSans";
        user-select: none;
        margin: 0;
        padding: 0;
        position: relative;
        border: .6rem solid #fff;
        border-radius: 1.6rem;
    }
    `;
export const PopupSolveInner= styled.div`
    font-size: 10px;
    font-family: "BalsamiqSans";
    user-select: none;
    margin: 0;
    padding: 4rem;
    border-radius: .8rem;
    background-color: #54a0ff;
    border: 0 solid #ee7470!important;
    
    & .problem-solve {
        user-select: none;
        border: none;
        font-family: "Alata","NanumSquareRound",sans-serif;
        vertical-align: middle;
        outline: none;
        background: #ee7470;
        padding: 5px 24px;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        min-width: 26%;
        cursor: pointer;
        display: block;
        margin: 25px auto 0;
    }

    & span {
        font-family: "BalsamiqSans";
        user-select: none;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
    }
`;
export const WordCardArea= styled.div`
    width: ${props=>props.writeStage?'40%':'72.5vh'};
    text-align: center;
    `;
export const CardSpeaker= styled(RowFlex)`
    justify-content: center;
    align-items: center;
    `;
export const CardArea= styled.div`
    margin-left: 32px;
    text-align: center;
    `;
export const CardSmall= styled.div`
    margin: 0 auto;
    max-width: calc(100vw / 3);
    `;
export const CardXSmall= styled.div`
    margin: 0 auto;
    max-width: calc(100vw / 4.5);
    `;
export const SelCard= styled.p`
    border: 3px solid ${props=>props.active?'#feca57':'#e5e5e5'};
    text-align: center;
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
`;
export const TextArea= styled.div`
    padding-top: 20px;
    font-size: ${props=>props.writeStage?'3rem':'4rem'};
    line-height: 1;
    font-weight: 700;
    @media (max-height: 768px){
        font-size: ${props=>props.writeStage?'2rem':'3rem'};
    }
    @media (max-height: 500px){
        font-size: ${props=>props.writeStage?'1.5rem':'2rem'};
}
`;
export const PopUpBottom= styled.div`
    flex-basis: 5.6rem;
    position: relative;
    `;
export const PopUpBottomRight= styled.p`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.8rem;
`;
export const PopUpButtonWrap= styled(RowFlex)`
    width: 10%;
    justify-content: center;
`;
export const PopUpWriteWrap= styled.div`
    width: 50%;
`;
export const ImageSelectWrap= styled(ColumnFlex)`
    height: calc(100% - 80px);
    @media (max-height: 768px){
        height: calc(100% - 70px);
    }
    @media (max-height: 500px){
      height: calc(100% - 60px);
    }
`;
export const ImageSelectQuestionBox= styled(RowFlex)`
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: calc(calc(100% - 3.6rem) / 3);
    text-align: center;
    border-radius: 1.2rem;
    border: 3px solid #e5e5e5;
`;
export const ImageSelectTopBox= styled(BorderRowBox)`
    justify-content: center;
	align-items: center;
	position: relative;
    padding: 1.2rem;
    margin-bottom: 1rem;

    @media (max-height: 1080px){
        margin-bottom: 1.8rem;
        padding:1.2rem;
}
    @media (max-height: 720px){
        padding: 1.2rem;
    margin-bottom: 1rem;
}
@media (max-height: 500px){
    margin-bottom: 0.3rem;
    padding:0.5rem;
}
@media (orientation: portrait) {
    flex-direction: column;
}
`;
export const ImageSelectBottomBox= styled(BorderColumnBox)`
    justify-content: center;
    flex:2 1;
    margin:0;
    height: 100%;
    `;
export const ImageSelectBtnBox= styled.ul`
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    list-style: none;
    padding:0;
`;
export const ImageWriteBtnBox= styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0 10px 10px;
    list-style: none;
    padding:0;
`;
export const ImageSelectBtnLi= styled.li`
    padding: 0 4px;
    flex: 1 1;
    margin: 0;
    `;
export const ImageSelectBtn= styled.button`
    display: table;
    padding: 1.2rem 0;
    width: 100%;
    font-family: "BalsamiqSans";
    font-weight: 700;
    font-size: 2.4rem;
    border-radius: 16px;
    border: 3px solid ${props=>props.active?'#ffb000':'#fff'};
    background-color: #ffefcb;
    cursor: pointer;
    outline: none;
    vertical-align: middle;
    @media (max-height: 720px){
        padding: 1rem 0;
        font-size: 2rem;
    }
`;
export const ImageSelectBottomImgBox= styled.ul`
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.9rem; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0 10px;
    `;
export const ImageSelectBottomImgLi= styled.li`
    overflow: hidden;
    border-radius: 1.2rem;
    margin: 0 10px;
    padding: 0;
    list-style: none;
`;
export const ImageSelectBottomImgBtn= styled.button`
    border: 3px solid #e5e5e5;
    border-radius: 1.2rem;
    margin: 0;
    padding: 0;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
    vertical-align: middle;
`;
export const MainBox= styled(BorderColumnBox)`
    justify-content: center;
    flex:1 1;
    margin:0;
    height: calc(100% - 70px);
    `;
export const MultiDnDCardArea= styled(RowFlex)`
    padding: 0 2px;
    height: 80%;
`;
export const MultiDnDdl = styled.dl`
    flex: 1 1;
    flex-shrink: 1;
    padding: 1rem;
    height: 100%;
    max-height: 100%;
    @media (max-height: 500px){
        
        padding-top: 0.2rem;
}
`;
export const MultiDnDdt = styled.dt`
    padding: 10px;
    text-align: center;
    border: 3px solid #e5e5e5;
    border-radius: 1.2rem;
    background-color: #fff;
    overflow: hidden;
    `;
export const MultiDnDdd = styled.dd`
    display: flex;
    padding-top: ${(props)=>props.scale*1.5}rem;
    text-align: center;
    justify-content: center;
    `; 
export const MultiDndMonsterCard = styled.p`
    /* display: inline-block; */
    justify-content: center;
    align-items: center;
    display: flex;
    width: 19vw;
    height: ${(props)=>props.scale*4}rem;
    font-size: ${(props)=>props.scale*2.8}rem;
    font-weight: 800;
    text-align: center;
    color: ${props=>props.active?'#333':'transparent'};
    border: ${(props)=>props.scale*6}px solid ${props=>props.active?'#48dbfb':'#e5e5e5'};
    border-radius: ${(props)=>props.scale*1}rem;
    background-color: #fff;
    box-shadow: 0 6px 0 ${props=>props.active?'#0abde3':'#d5d5d5'};
    margin-top:${props=>props.active&&'-3px'};
    margin-bottom: 0;
    `;
export const MultiDndMonsterCardAlphabet = styled.span`
    color: ${props=>props.isPink?'#f368e0':'#2e86de'};
`;
export const MultiDndMonsterArea= styled.div`
    position: relative;
    padding: 0.8rem 0;
    overflow: hidden;
`;
export const MultiDndMonsterCart= styled(RowFlex)`
    position: relative;
    display: flex;
    height: ${(props)=>props.scale*7}rem;
    justify-content: center;
    ::before{
        content: "";
        display: inline-block;
        margin-right: -1px;
        width: 20vw;
        height: 100%;
        background: url(https://cdn.cloubot.com/PM/image/level1/monster_wordcart_l.png) no-repeat right / auto 100%;
        @media (max-height: 500px){
       display: none;
}
}
::after{
    content: "";
    display: inline-block;
    margin-left: -1px;
    width: 20vw;
    height: 100%;
    background: url(https://cdn.cloubot.com/PM/image/level1/monster_wordcart_r.png) no-repeat left / auto 100%;
    @media (max-height: 500px){
    display: none;
    }
    }
`;
export const MultiDndMonsterQ= styled.div`
    position: relative;
    width: 25vw;
    height: 100%;
    background: url(https://cdn.cloubot.com/PM/image/level1/monster_wordcart_bg.png) repeat-x center / auto 100%;
    text-align: center;
    @media (max-height: 500px){
        display: flex;
        background: none;
        justify-content: center;
}
`;
export const ImgDndBox= styled(BorderColumnBox)`
    justify-content: center;
    flex:1 1;
    margin:0;
    `;
export const ImgDndDragArea = styled.ul`
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    grid-gap: 1.2rem;
    padding: 1.2rem;
    height: 100%;
    list-style: none;
    @media (max-height: 500px){
        grid-gap: 1rem;
        padding: 1rem;
}
`;
export const ImgDndDragItem= styled.img`
    border-radius: 1rem;
    border: 4px solid #eee;
    box-sizing: border-box;
    height: calc(calc(100vh - 270px) / 2 - 10px);
    @media (max-height: 500px){
      height: 23vh;
}
`;
export const ImgDndDropArea = styled.ul`
    padding: 2rem 2rem 1rem;
    list-style: none;
    @media (max-height: 500px){
        padding: 1rem 1rem 0.5rem;
}
`;
export const ImgDndDropLi = styled.li`
    margin: 0 auto;
    overflow: hidden;
    width: 29vh;
    height: 38.31vh;
    border-radius: 1rem;
    border: 4px solid #feca57;
    @media (max-height: 500px){
        height: 25vh;
        width: 19vh;
        border-radius: 1rem;
}
`;
export const ImgDndDropTxt = styled.p`
    margin: 0;
    flex: 1 1;
    padding: 2px 24px 24px;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
    @media (max-height: 500px){
        padding: 2px 16px 16px;
        font-size: 1.4rem;
}
`;
export const ImgDndAlphabetJoin = styled(RowFlex)`
    justify-content: center;
    align-items: center;
    flex: 1 1;
    `;
export const ImgDndAlphabetP = styled.p`
    margin-right: 8px;
    font-weight: 800;
    font-size: 2.6rem;
    color : #2e86de;
    margin: 0;
    padding:0;
`;
export const ImgDnd2BorderBox = styled(BorderColumnBox)`
    display: flex;
    flex-direction: column;
    /* flex: 1 1; */
    position: relative;
    border-radius: 1.2rem;
    border: 0.4rem solid #ffb000;
    background-color: #fff;
`;
export const ImgDnd4AlphabetBox = styled.span`
    display: inline-flex;
    width: ${props=>props.isLong?'155px':'96px'};
    height: 96px;
    max-height: none;
    margin-right: 4px;
    font-size: 72px;
    margin: 0 0.05em;
    border: none;
    border-radius: 0;
    background: #f3f3f5;
    box-shadow: none;
    cursor: auto;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    font-weight: 800;
    white-space: nowrap;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media (max-height: 500px) {
    height: 50px;
    font-size: 40px;
  }
`;
export const HiddenFindArea= styled.div`
width: 40%;
padding: 0.7rem;
flex-grow: 1;
@media (max-height: 500px){
    padding:0 1rem;
}
`;
export const HiddenFind= styled.div`
    position: relative;
    border: 3px solid #feca57;
    border-radius: 1.2rem;
    overflow: hidden;
`;
export const HiddenWordArea= styled.div`
    width: 38%;
    padding: 0.7rem;
    flex-grow: 1;
    padding: 0.8rem;
    height: 100%;
`;
export const HiddenBtn = styled.button`
    position: absolute;
    transform: translate(-50%, -50%);
    width: 5vw;
    height: 5vw;
    background: transparent;
    border-radius: 100%;
    outline: none;
    border: ${props=>props.active?'3px solid #e3007e':'3px solid transparent'};
    margin: 0;
    padding: 0;
`;
export const HiddenWordOl= styled.ol`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    `;
export const HiddenWordLi = styled.li`
    position: relative;
    width: 49%;
    text-align: center;
    margin: 0;
    padding: 0;
`;
export const HiddenWordNum = styled.p`
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width:1.6rem;
    height: 1.6rem;
    color: #fff;
    background: #c9c9ca;
    border-radius: 100%;
    z-index: 0;
    
`;
export const HiddenWordImg = styled.p`
    margin: 0 auto 3px;
    width: 50%;
    padding: 0;
    position: relative;
    `;
export const CorrectCircle =styled.div`
    width: 100%;
    height: 100%;
    background: ${(props)=>props.isCorrect&&'url(/images/pm/score_check_pass.svg) no-repeat'};
    background-size: auto;
    background-position: center;
    position: absolute;
    z-index: 1;
`;
export const HiddenWordTxt = styled.p`
    font-size: 1.9rem;
    margin: 0;
    padding: 0;
    @media (max-height: 500px){
        font-size: 1.1rem;
    }
`;
export const HiddenWordBlank = styled.span`
    display: inline-block;
    margin: 0 2px;
    width: 0.8em;
    height: 1em;
    background-color: #fff8e4;
    vertical-align: text-bottom;
    padding: 0;
`;
export const TestImgBox = styled(RowFlex)`
    width: 45.5%;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 1.2rem;
    border: 3px solid #eee;
    padding:0.5rem;
    
    @media (orientation: portrait) {
        width: 100%;
    }
`;
export const TestScoreBox = styled.div`
    /* width: 40%; */
    position: relative;
    
    @media (orientation: portrait) {
        width: 100%;
    }
`;
export const TestAnswerArea= styled.ul`
    height: calc(calc(142vh / 768) * 100);
    list-style: none;
    margin:0;
    padding: 1.2rem;
    overflow-y: auto;
    overflow-x: hidden;
    `;
export const TestAnswer= styled.li`
display: flex;
`;
export const TestAnswerNum= styled.p`
    margin:0;
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 2.1rem;
    height: 2.1rem;
    margin-right: 0.5rem;
    border-radius: 100%;
    font-weight: 600;
    font-size: 1.1rem;
    color: #fff;
    background-color: #54a0ff;
    ::after{
        content: "";
        position: absolute;
        top: -0.5rem;
        bottom: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        display: block;
    }

    &.pass::after{
        background: url(/images/pm/score_check_pass.svg) no-repeat;
    }

    &.fail::after{
        background: url(/images/pm/score_check_fail.svg) no-repeat;
    }

`;
export const TestAnswerTxt = styled.p`
    margin:0;
    padding:0;
    width: calc(calc(316vh / 768) * 100);
    height: 11vh;
    font-size: 1.8rem;
    letter-spacing: -0.04rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    
    @media (orientation: portrait) {
        width: calc(calc(150vh / 768) * 100);
    }
`;
export const TestScoreBtn= styled.button`
    outline: none;
    padding: 5px 24px;
    border-radius: 5px;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 26%;
    margin: 0 auto;
    border: none;
    background: ${props=>props.finished?'#ff8a00':'gainsboro'};
    cursor: ${props=>props.finished?'pointer':'not-allowed'} ;
`;

export const PenKeyboardWrapMain = styled(ColumnFlex)`
    background-color: #a4aae3;
    border: 4px solid #daddff;
    border-radius: 32px;
    border-bottom-width: 15px;
    box-shadow: rgb(172, 176, 216) 0px 2px 0px;
    @media (max-height: 720px){
        border-bottom-width: 10px;

    }
`;
export const PenKeyboardWrap = styled(ColumnFlex)`
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    margin: 0 auto;
    @media (orientation: portrait) {
        width: 100%;
    }
`;
export const WriteImagePopupWrap= styled(RowFlex)`
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    `;
export const PopupWriteBGWrap = styled(ColumnFlex)`
width: auto;
height: auto;
padding: 1rem;
border-radius: 1.2rem;
background-color: #fff;
`;
export const PopupWriteBGBorder = styled(ColumnFlex)`
    position: relative;
    flex: 1 1;
    padding: 2.4rem;
    border-radius: 1.6rem;
    border: 0.6rem solid #ee7470;
    `;

export const PopupWriteDirection = styled.h3`
    font-weight: 600;
    text-align: center;
    padding-bottom: 1em;
    margin: 0px;
    font-size: 2.1rem;
    @media (max-height: 500px){
        font-size: 1.6rem;
}
    `;
export const PopupWriteWordBox = styled.p`
    margin: 0 0 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    padding: 8px;
    border-radius: 12px;
    border: 3px solid #eee;
    font-weight: 800;
    font-size: 20px;
    `;
export const ImageSoundImgWrap= styled.div`
    text-align: center;
    padding-top: 2rem;
    `;
export const Dnd4TopBox= styled(BorderRowBox)`
    flex:2 1;
    position: relative;
    justify-content: center;
    padding:16px;
    @media (max-height: 500px){
        padding:2px 16px;
}
    `;
export const Dnd4DropArea = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    list-style: none;
    padding: 0;
    margin: 0;
`;
export const Dnd4DropLi = styled.li`
    width: 100%;
    height: 100%;
    max-height: 14.3rem;
    border-radius: 16px;
    border: 4px dashed #e5e5e5;
    box-sizing: border-box;
    background-color: #f9f9f9;
    padding: 0;
    margin: 0;
    `;
export const Dnd4BlueBoxWrapM= styled(RowFlex)`
    justify-content: center;
    position: relative;
    `;
export const Dnd4WordWrap= styled.p`
    margin: 0;
    padding:0;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 4.5rem;
    @media (max-height: 500px) {
    font-size: 2.5rem;
    padding-top:20px;
  }
`;
export const Dnd4BottomBox= styled(BorderColumnBox)`
    justify-content: center;
    flex: 2 1;
    padding: 1.2rem;
`;
export const Dnd4DragArea= styled.ul`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.2rem;
    list-style: none;
    margin:0;
    padding:0;
`;
export const Dnd4DragItem= styled(BorderRowBox)`
    justify-content: center;
    align-items: center;
    height: 100%;
    border-color: #e5e5e5;
`;
export const ColorMainBox= styled(BorderColumnBox)`
    justify-content: space-around;
    flex: 1 1;
    position: relative;
    align-self: stretch;
    height: calc(100% - 70px);
    `;
export const ColorPaintWordsWrap= styled.div`
    height: 100%;
    text-align: center;
    background: #fffdeb url(https://cdn.cloubot.com/PM/image/paintwords_bg.png) no-repeat center bottom / 60%;
    border-radius: 1.6rem;
`;
export const ColorPaintUl= styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1.6rem 0;
    height: 100%;
    box-sizing: border-box;
    `;
export const ColorPaintLi= styled.li`
    margin: 0;
    padding: 0;
    margin-left: ${(props)=>props.num===0?'8%':0};
    margin-right: ${(props)=>props.num===3?'8%':props.num===5?'22%':0};
    width:${(props)=> props.num<4?'20%':props.num<8?'19%':'20%'};
;
`;
export const ColorPaintWord= styled.div`
    display: table;
    margin: 0 auto;
    width: calc(16vh + 1rem);
    height: calc(16vh + 1rem);
    border-radius: 50%;
    border: 4px solid #4f4a75;
    background: #fff;
`;
// export const ImageWordSelectWriteBox= styled(BorderColumnBox)`
//     position:relative;
//     flex: 1 1;
//     width:50%;
// `;
export const ImageWordSelectWriteBox= styled(BorderColumnBox)`
    position:relative;
    flex: 1 1;
    width:100%;
`;
export const RibbonTitle= styled.h4`
    width: 64%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem 0;
    font-weight: 800;
    font-size: 1.5rem;
    visibility: ${props=>props.isShortVowel&&'hidden'};
    background: url(https://cdn.cloubot.com/PM/image/BG_step_tit.svg) no-repeat center / cover;
    @media (max-height: 500px) {
        font-size: 1.3rem;
  }
`;
export const AlphabetWrap= styled.ul`
    display: flex;
    flex : 1 1;
    padding: 0.6rem;
    flex-wrap: wrap; 
    align-items: center;
    list-style: none;
    justify-content: center;
    margin:0;
`;
export const AlphabetWrapLi  = styled.li`
    padding: 10px 6px;
`;
export const AlphabetBox= styled(RowFlex)`
    background-color: #fff;
    border: 0.4rem solid ${props=>props.gray?'#eee':'#00d2d3'};
    box-shadow: 0 0.4rem 0  ${props=>props.gray?'#d5d5d5':'#00a3a4'};
    width: ${(props)=>3.3*(props.row?props.row:1)}rem;
    height: ${(props)=>props.row===3.21?'5.2rem':'3.06rem'};
    font-size: 2.4rem;
    margin: -0.3rem 0.3rem 0;
    justify-content: center;
    align-items: flex-end;
    /* margin-top: -0.3rem; */
    border-radius: 0.9rem;
    box-sizing: content-box;
    font-weight: 800;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    visibility: ${props=>props.hide&&'hidden'};
    @media (max-height: 720px){
        border-radius: 0.6rem;
        border: 0.3rem solid ${props=>props.gray?'#eee':'#00d2d3'};
        box-shadow: 0 0.3rem 0  ${props=>props.gray?'#d5d5d5':'#00a3a4'};
        width: ${(props)=>2.2*(props.row?props.row:1)}rem;
        height: ${(props)=>props.row===3.21?'5.2rem':'3.06rem'};
        font-size: 1.7rem;
        margin: -0.2rem 0.2rem 0;
    }
    @media (max-height: 500px){
        border-radius: 0.6rem;
        border: 0.3rem solid ${props=>props.gray?'#eee':'#00d2d3'};
        box-shadow: 0 0.3rem 0  ${props=>props.gray?'#d5d5d5':'#00a3a4'};
        width: ${(props)=>2.3*(props.row?props.row:1)}rem;
        height: ${(props)=>props.row===3.21?'3.82rem':'2.16rem'};
        font-size: 1.8rem;
        margin: -0.2rem 0.2rem 0;
}
`;
export const AlphabetBottomWrap= styled(RowFlex)`
border-radius: 0 0 1.2rem 1.2rem;
border-top: 0.12rem solid #e5e5e5;
background-color: #f9f9f9;
flex : 1 1;
justify-content: center;
align-items: center;
`;
export const AlphabetBottomWrapUl= styled.ul`
    display: flex;
    flex : 1 1;
    padding: ${props=>props.borderTop?'0.6rem':'0'};
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    border-radius: ${props=>props.borderTop&&'0 0 1.2rem 1.2rem'};
    border-top: ${props=>props.borderTop&&'0.12rem solid #e5e5e5'};
    background-color: ${props=>props.borderTop&&'#f9f9f9'};
    margin:0;

`;
export const SelectAndDndTopBox= styled(BorderRowBox)`
    flex:2 1;
    position: relative;
    justify-content: center;
    padding:16px;
    align-items: center;
    margin-bottom: 2rem;
    @media (max-height: 500px){
        padding:10px;
         margin-bottom: 0.2rem;
}
`;
export const SelectAndDndBtnBox= styled.ul`
    display: flex;
    margin: 15px 0;
    list-style: none;
    padding:0;
    @media (max-height: 500px){
        margin: 5px 0;
    }
    `;
export const SelectAndDndBtnLi= styled.li`
    overflow: hidden;
    display: flex;
    flex: 1 1;
    border-radius: 16px;
    border: 3px solid ${(props)=>props.isCorrect?'#00d2d3':'#fff'};
    background-color: #fff;
    box-shadow: 0 6px 0 #00a3a4;
    margin: 0 0.5rem;
    color: #f368e0;
    font-size: 2.2rem;
    justify-content: center;
    align-items: center;
    padding: 1.1rem 0;
    font-weight: 700;
    @media (max-height: 500px){
        padding: 0.8rem 0;
         font-size: 1.8rem;
}
    `;
export const SelectAndDndBtn= styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex: 1 1;
    padding: 1.1rem 0;
    font-weight: 700;
    font-size: 2.2rem;
    background-color: white;
    color: #f368e0;
    /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1); */
    cursor: pointer;
    border: none;
    outline:none;
    cursor: pointer;
    @media (max-height: 500px){
        padding: 0.8rem 0;
         font-size: 1.8rem;
}
`;
export const MultiDndBtnLi= styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 1 1; */
    /* margin-bottom: 1rem; */
    padding: 0.5rem;
    border-radius: 16px;
    border: 4px solid #eee;
    width: 31%;

    `
export const MultiWordAlphabetWrap = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
`;
export const LineWordTopBox= styled(BorderRowBox)`
    justify-content: center;
    align-items: center;
    flex: 1 1;
    margin-bottom: 2.2rem;
    padding: 1.2rem;
    @media (max-height: 500px){
        
        margin-bottom: 0.1rem;
    }
`;
export const LineWordUL = styled.ul`
    align-items: stretch;
    flex: 1 1;
    max-height: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    `;
export const LineWordLI = styled.li`
    overflow: hidden;
    display: flex;
    flex: 1 1;
    border-radius: 1.2rem;
    padding-top: 0.5rem;
`;
export const LineWordBtn = styled(AlphabetBox)`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
    background-color: #fff;
    border: 0.6rem solid ${props=>props.gray?'#eee':'#00d2d3'};
    box-shadow: 0 0.6rem 0  ${props=>props.gray?'#d5d5d5':'#00a3a4'};
`;
export const ImgSoundWriteAlphabetWrap = styled(AlphabetWrap)`
    flex-direction: column;
    margin: 0 4px 0 16px;
    padding: 0;
    `;
export const ImgSoundWriteAlphabetBtn = styled(AlphabetBox)`
    padding: 0 16px;
    border-radius: 0;
    border: 6px solid ${(props)=>props.gray?'#eee':'#feca57'};
    box-shadow: 0 6px 0 ${(props)=>props.gray?'#d5d5d5':'#ff9f43'};
    margin: 10px 0;
    @media (max-height: 500px){
        margin: 7px 0;
    }
`;
export const WritePopUpWrap = styled(RowFlex)`
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    `;
export const WritePopUpBG = styled(ColumnFlex)`
    width: calc(100% - 198px);
    height: calc(100vh - 157px);
    padding: 1rem;
    border-radius: 1.2rem;
    background-color: #fff;
    @media (max-height: 500px){
        width: calc(100% - 98px);
        height: calc(100vh - 57px);

    }
    `;
export const WritePopUpBorder = styled.div`
    position: relative;
    flex: 1 1;
    padding: 2rem;
    border-radius: 1.2rem;
    border: 0.4rem solid #ee7470;
    @media (max-height: 500px){
        padding: 1rem;
    }
    `;
export const WritePopUpTitle = styled.h3`
    font-weight: 600;
    text-align: center;
    font-size: 2.6rem;
    padding-bottom: 1em;
    margin: 0;
    padding: 0;
    @media (max-height: 720px){
        font-size: 2rem;
        padding-bottom: 0.7em;

    }
    @media (max-height: 500px){
        font-size: 1.3rem;
        padding-bottom: 0.5em;
    }
    `;
export const WritePopUpWordBox = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    padding: 8px;
    border-radius: 12px;
    border: 3px solid #eee;
    font-weight: 800;
    font-size: 80px;
    margin: 0 0 40; 
    @media (max-height: 720px){
        font-size: 50px;
        margin-bottom: 30px;
    }
    @media (max-height: 500px){
        font-size: 30px;
        margin-bottom: 20px;
    }
`;
export const PageWrap = styled.div`
  position: absolute;
  display: ${(props) => (props.mobile ? 'none' : 'block')};
  top: 20px;
  right: 1.8rem;
  @media (max-height: 500px) {
    top: 15px;
    right: 1.8rem;
    display: ${(props) => (!props.mobile ? 'none' : 'block')};
  }
`;
export const SoundWrap = styled(RowFlex)`
    justify-content: center;
    padding: 10px 0;
@media (max-height: 500px) {
    padding: 5px 0;
    }
`;
export const OutPut = styled.p`
  font-weight: 600;
  text-align: center;
  background-color: #fff8e4;
  position: relative;
  top: 0;
  width: 60%;
  font-size: 2rem;
  border-radius: 8px;
  margin: 0 auto ${(props) => (props.imageWordWrite ? '20px' : '10px')};
  height: 3rem;
  line-height: 3rem;
  @media (max-height: 760px) {
    font-size: 1.8rem;
    border-radius: 8px;
    margin: 0 auto ${(props) => (props.imageWordWrite ? '20px' : '10px')};
    height: 2.5rem;
    line-height: 3rem;
  }
  @media (max-height: 500px) {
    font-size: 1.2rem;
    border-radius: 6px;
    margin: 0 auto ${(props) => (props.imageWordWrite ? '15px' : '7px')};
    height: 2rem;
    line-height: 2rem;
  }
`;
// export const = styled.