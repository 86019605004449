import React, { useMemo, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ManagerPage from './ManagerPage';
import StudentPage from './StudentPage';
import CampusPage from './CampusPage';
import HeadManagerPage from './HeadManagerPage';
import AdminPage from './AdminPage';
import BranchPage from './BranchPage';

import { getCurrentToken } from 'src/utils';
import { userVar } from 'src/apollo/cache';
import { useQueryProxy } from 'src/operations/proxy/user';
import * as queries from 'src/operations/queries';

const DashBoard = () => {
  const query = new URLSearchParams(window.location.search);
  const isRefresh = query.get('refresh');
  const history = useHistory();
  const [tokenCheck, setTokenCheck] = useState(false);
  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
    skip: !token,
  });
  const userType = useMemo(() => userVar()[data?.me?.type] || userVar()[0], [data]);
  useEffect(() => {
    if (isRefresh === 'login') {
      history.replace({ search: '' });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [isRefresh]);

  const [refreshToken, { data: refreshData }] = useLazyQuery(queries.getUser.REFRESH_TOKEN);

  useEffect(() => {
    if (!tokenCheck) {
      let culp_token = JSON.parse(window.localStorage.getItem('culp_token'));
      if (culp_token) {
        const expireAt = moment(culp_token.expire_at);

        if (moment().diff(expireAt, 'minutes') > -60) {
          refreshToken({ variables: { token: culp_token.refreshToken } });
        }
      } else {
        setTokenCheck(true);
      }
    }
  }, [tokenCheck]);
  useEffect(() => {
    if (refreshData) {
      window.localStorage.setItem('culp_token', JSON.stringify(refreshData.refresh));
      setTokenCheck(true);
    }
  }, [refreshData]);

  const userPage = {
    student: <StudentPage />,
    manager: <ManagerPage />, //강사
    campus: <CampusPage />, //원장
    branch: <BranchPage />, //지사
    fc: <HeadManagerPage />,
    admin: <AdminPage />,
  };

  return <>{userPage[userType]}</>;
};

export default DashBoard;
