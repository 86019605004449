import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Checkbox, Button, Dropdown, Menu, Space, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CustomTable from 'src/components/common/CustomTable';
import { useHistory, useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import { getLixileScoreObject } from 'src/utils/index';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import { EXTERNAL_STUDY_BOOK } from 'src/operations/queries/externalStudy';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
// import LevelUpNoticePopup from 'src/popups/LevelUpNoticePopup';
const Wrapper = styled.div`
  width: 100%;
  // height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const AssignButton = styled(RowFlex)`
  border: 2px solid #ffbe00;
  border-radius: 5px;
  padding: 5px 25px;
  color: #000000;
  background-color: #ffffff;
  height: 32px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
`;
const SkillTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #3b3838;
  /* padding-bottom: 20px; */
`;
const SkillOutWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  border-top: 4px solid #595959;
  height: 160px;
  padding: 3px 0;
`;
const SkillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  background-color: #fff;
  width: 100%;
  height: 150px;
`;
const SkillBox = styled(RowFlex)`
  width: 50%;
  background-color: ${(props) => (props.checked ? '#f8ecdc' : '#ffffff')};
  border: 1px solid #f3f2f2;
  font-size: 14px;
  padding: 5px 10px;
`;
const SkillCheckBox = styled.input`
  :checked + label::before {
    background-color: #fff;
    color: #000;
  }
`;
const LevelUpBookListForAssign = () => {
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);
  const [filteredbook, setFilteredbook] = useState([]);
  const [bookType, setBookType] = useState(null);
  const [searchValue, setSeachValue] = useState('');
  const [bookIdx, setBookIdx] = useState();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const history = useHistory();
  const { state } = useLocation();
  const [level, setLevel] = useState('All');
  const [pg, setPg] = useState({ current: 1, pageSize: 10 });
  const [ch, setCh] = useState(
    books?.map((e, i) => {
      let obj;
      obj = {
        no: e.no,
        code: e.code,
        value: false,
        book_no: e.book_no,
      };
      return obj;
    }),
  );
  const [fromSkill, setFromSkill] = useState(null);
  const { data: userBook, loading } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      type: 'levelup',
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
  useEffect(() => {
    if (state) {
      const selected = state?.bookIdxArr.map((item) => item.skill);
      const allSkills = JSON.parse(state?.skills);
      setSelectedSkills(state?.bookIdxArr);
      const skillNames = allSkills.map((e) => ({ skill: e.skills, checked: selected.includes(e.skills), book_ids: e.book_ids }));
      const uniqueBookIndicesSet = new Set();
      state?.bookIdxArr?.forEach((item) => {
        uniqueBookIndicesSet.add(item.book_idx);
        // item?.book_idx?.forEach(bookIdx => {
        //     uniqueBookIndicesSet.add(bookIdx);
        // });
      });
      setBookIdx(Array.from(uniqueBookIndicesSet));
      setFromSkill(skillNames);
      if (state?.level) {
        setLevel(parseInt(state?.level));
      } else {
        setLevel('All');
      }
      // if (state.selectedClass?.mx_level) {
      //   setLevel(state.selectedClass?.mx_level);
      // } else {
      //   setLevel('All');
      // }
    }
  }, [state]);
  const onClickHandleSkills = () => {
    if (state.skills) {
      // console.log(fromSkill)
      const tmp = fromSkill.filter((e) => e.checked);
      setSelectedSkills(tmp);
      const uniqueBookIndicesSet = new Set();
      selectedSkills.forEach((skills) => skills.book_ids.forEach((e) => uniqueBookIndicesSet.add(e)));
      setBookIdx(Array.from(uniqueBookIndicesSet));
    }
  };
  const setMxLevel = (lexile) => {
    const mx = getLixileScoreObject(lexile, 'A-1');
    return mx;
  };
  const setLexileValue = (lexile) => {
    if (lexile.includes('BR')) {
      return 0;
    } else if (lexile.includes('NP')) {
      return 1000;
    } else {
      return parseInt(lexile);
    }
  };
  useEffect(() => {
    if (userBook?.getExternalUserBook?.length > 0) {
      try {
        let list = userBook?.getExternalUserBook?.map((e, i) => {
          e.no = i + 1;
          e.lexileVal = e.lexile_val ? e.lexile_val : setLexileValue(e.lexile);
          e.mxObj = setMxLevel(e.lexileVal);
          e.level = e.mx_level !== undefined ? (e.mx_level === 'PH' ? 0 : e.mx_level) : e.mxObj?.mx;
          return e;
        });
        setBooks(list);
      } catch (e) {
        setError(e);
      }
    }
  }, [userBook]);
  useEffect(() => {
    if (!loading && books) {
      if (level === 'All' && searchValue === '') {
        let list = books.reduce((acc, book, key) => {
          // const bookNoMatch = bookIdx?.includes(book.book_no) ? true : false;
          const bookNoMatch = state?.skilled_books?.includes(book.book_no) ? true : false;
          if (bookIdx?.length) {
            if (bookNoMatch) {
              acc.push(book);
            }
          } else {
            acc.push(book);
          }
          // const index = Math.floor(key);
          // book.no = key + 1;
          return acc;
        }, []);

        if (bookType !== null) {
          list = list.filter((book) => book.is_voca === bookType);
        }

        setFilteredbook(list);
      } else {
        let list2 = books?.reduce((acc, cur, key) => {
          const levelfilter = level !== 'All' ? String(cur.mx_level) === String(level) : true;
          // const typefilter = type !== 'All' ? cur.type === type : true;
          const searchfilter = searchValue !== '' ? cur.title.toLowerCase().includes(searchValue.toLowerCase()) : true;
          // const bookNoMatch = state?bookIdx.includes(cur.book_no):true;
          // const bookNoMatch = bookIdx?.includes(cur.book_no) ? true : false;
          const bookNoMatch = state?.skilled_books?.length ? state?.skilled_books?.includes(cur.book_no) : true;
          if (levelfilter && searchfilter && bookNoMatch) {
            // if (levelfilter && searchfilter) {
            // cur.no = key + 1;
            acc.push(cur);
          }
          return acc;
        }, []);

        if (bookType !== null) {
          list2 = list2.filter((book) => book.is_voca === bookType);
        }

        setFilteredbook(list2);
      }
    }
  }, [books, level, searchValue, loading, userBook, state?.skilled_books, bookIdx, bookType]);
  useEffect(() => {
    setPg({
      ...pg,
      current: 1,
    });
  }, [filteredbook, level]);

  useEffect(() => {
    setCh(
      filteredbook?.map((e, i) => {
        const uniqueBookIdx = new Set();
        state?.bookIdxArr?.forEach((item) => {
          uniqueBookIdx.add(item.book_idx);
          // item?.book_idx?.forEach((bookIdx) => {
          //   uniqueBookIdx.add(bookIdx);
          // });
        });
        const uniqueBookIdxArray = Array.from(uniqueBookIdx);
        const match = uniqueBookIdxArray.includes(e.book_no);
        // const match = [4194, 4303, 4231, 4179, 4253].includes(e.book_no)
        const obj = {
          no: e.no,
          code: e.code,
          value: match ? true : false,
          book_no: e.book_no,
        };
        return obj;
      }),
    );
  }, [books, filteredbook]);

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  function onChange(e, idx, book_no) {
    ch[ch.findIndex((element) => element.no === idx)] = { no: idx, value: e.target.checked, book_no: book_no };
    setCh(JSON.parse(JSON.stringify(ch)));
  }
  const currentPage = (i) => {
    return Math.floor(i / pg.pageSize) === pg.current - 1;
  };
  function onCheckAllChange(e) {
    if (e.target.checked) {
      const arr2 = _.cloneDeep(ch);
      const checked = filteredbook
        .filter((e, i) => {
          return currentPage(i);
        })
        .map((e) => {
          const obj = {
            no: e.no,
            code: e.code,
            value: true,
          };
          return obj;
        });
      const unChecked = arr2.filter((e, i) => {
        return !currentPage(i);
      });
      setCh([...checked, ...unChecked].sort((a, b) => a.id - b.id));
    } else {
      setCh(
        books?.map((e, i) => {
          let obj;
          obj = {
            no: e.no,
            code: e.code,
            value: false,
            book_no: e.book_no,
          };
          return obj;
        }),
      );
    }
  }
  if (loading) {
    return <div className="wait-comment">loading...</div>;
  }
  if (error) {
    return <div className="wait-comment">에러가 발생했습니다.</div>;
  }
  const handleAssignData = () => {
    let data = [];
    filteredbook?.forEach((item, index) => {
      ch?.forEach((checked, index2) => {
        if (item.no === checked.no && checked.value) {
          data.push(item);
        }
      });
    });
    if (data?.length > 0) {
      localStorage.setItem('selectedBooks', JSON.stringify(data));
      if (fromSkill && fromSkill !== null) {
        history.push(
          `/level-up/booklist/assign/${
            fromSkill
              ? `?${state.selectedClass ? `classIdx=${state.selectedClass}&` : ''}${
                  state?.selectedUser ? `userIdx=${state?.selectedUser}` : ''
                }`
              : ''
          }`,
        );
      } else {
        history.push(`/level-up/booklist/assign/`);
      }
    } else {
      alert('도서를 선택해주세요');
    }
  };
  const onClickSkill = (idx, skill) => {
    let check = fromSkill[idx].checked;
    const tmp = JSON.parse(JSON.stringify(fromSkill));
    if (check) {
      tmp[idx].checked = false;
    } else {
      tmp[idx].checked = true;
    }
    setFromSkill(tmp);
  };
  // console.log(state?.skilled_books,'state?.skilled_books');
  const lv = (
    <Menu>
      {!fromSkill || level ? (
        <>
          <Menu.Item key="All">
            <a rel="noopener noreferrer" onClick={() => setLevel('All')}>
              All
            </a>
          </Menu.Item>
          {['PH', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((e) => (
            <Menu.Item key={e}>
              <a rel="noopener noreferrer" onClick={() => setLevel(e)}>
                MX {String(e).padStart(2, '0')}
              </a>
            </Menu.Item>
          ))}
        </>
      ) : (
        <></>
      )}
    </Menu>
  );
  const columns = [
    {
      title: () => {
        const tmp = ch
          ?.filter((e, i) => {
            return currentPage(i);
          })
          .map((e) => {
            return e.value;
          });
        return <Checkbox checked={tmp?.filter((s) => s).length === pg.pageSize} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox
              id={record.id}
              checked={ch?.length ? ch[ch?.findIndex((element) => element.no === record.no)]?.value : ''}
              onChange={(e) => onChange(e, record.no, record.book_no)}
            ></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 60,
      render: (text, record, idx) => {
        return <div>{(pg.current - 1) * pg.pageSize + idx + 1}</div>;
      },
    },
    {
      title: 'Image',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: 130,
      padding: 0,
      render: (text, record) => {
        const url = `https://cdn.cloubot.com/LEVELUP/covers/${record.code}.png`;
        return (
          <img
            onClick={() => {
              window.open(`/level-up/description/${record.code}`, '_blank');
            }}
            style={{ cursor: 'pointer', width: '65px', maxHeight: '80px' }}
            src={url}
          />
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => a.title && a.title.localeCompare(b.title),
    },

    {
      title: 'Lexile',
      dataIndex: 'lexile_val',
      key: 'lexile_val',
      align: 'center',
      width: 80,
      sorter: (a, b) => parseInt(a.lexile_val) - parseInt(b.lexile_val),
    },
    {
      title: 'mx Level',
      dataIndex: 'mx_level',
      key: 'mx_level',
      align: 'center',
      width: 95,
      render: (text, record) => {
        return <span style={{ fontSize: '16px' }}>{record?.mx_level}</span>;
      },
    },
    {
      title: 'GRL',
      dataIndex: 'grl',
      key: 'id',
      align: 'center',
      width: 70,
      render: (text, record) => {
        return <>{record?.genre__1}</>;
      },
    },
    {
      title: 'Topic/Prompt',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      // width: 150,
      render: (_, record) => {
        return <span>{record?.ai_topic?.topic}</span>;
      },
    },
    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      align: 'center',
      width: 120,
      render: (_, record) => {
        // const { key, done_count, onDeleteAssignedExtenalGrp } = row;
        // console.log("key",key);
        return (
          <Button
            style={{ backgroundColor: '#559bdb', color: '#fff' }}
            // type="blue"
            // onClick={() => {
            //   onDeleteAssignedExtenalGrp(key, done_count > 0);
            // }}
            onClick={() => {
              window.open(`/level-up/description/${record.code}`, '_blank');
            }}
          >
            e-book
          </Button>
        );
      },
    },
  ];

  return (
    <Wrapper className="cust-assign-book">
      {/* <LevelUpNoticePopup /> */}
      <RowFlex style={{ justifyContent: 'center' }}>
        <LevelUpMenuGroup currentMenu={'assign'} />
      </RowFlex>
      <div className="library-con" style={{ margin: '0 auto', minHeight: '800px' }}>
        <div className="main-inner-wrap library float__box">
          <div className="section__inner">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="library__top float__box">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="float__right">
                  {/* <SubTitle>Assigned Page</SubTitle> */}
                  <Dropdown overlay={lv} trigger={['click']}>
                    <Link onClick={(e) => e.preventDefault()}>
                      <Space>
                        {level === 'All' ? 'Level' : level > 0 ? `MX ${String(level).padStart(2, '0')}` : `MX ${level}`}
                        <DownOutlined />
                      </Space>
                    </Link>
                  </Dropdown>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="All Stages">
                          <a rel="noopener noreferrer" onClick={() => setBookType(1)}>
                            All Stages
                          </a>
                        </Menu.Item>
                        <Menu.Item key="Only Reading">
                          <a rel="noopener noreferrer" onClick={() => setBookType(0)}>
                            Only Reading
                          </a>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Link
                      onClick={(e) => e.preventDefault()}
                      style={{
                        marginLeft: '5px',
                      }}
                    >
                      <Space>
                        {bookType === null ? 'Book Type' : bookType === 1 ? 'All Stages' : 'Only Reading'}
                        <DownOutlined />
                      </Space>
                    </Link>
                  </Dropdown>
                </div>
                <Button style={{ backgroundColor: '#289428', color: '#ffff', marginLeft: 5 }} onClick={handleAssignData}>
                  Assign
                </Button>
                {/* </Link> */}
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="title search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  value={searchValue}
                />
                {/* <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                  <RedoOutlined />
                </CustomButton> */}
              </div>
            </div>
            {fromSkill && (
              <ColumnFlex style={{ width: '100%', padding: '0 0 15px' }}>
                <RowFlex style={{ width: '100%', padding: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                  <SkillTitle>Selected Skills&nbsp;</SkillTitle>
                  <AssignButton onClick={onClickHandleSkills}>Search Books</AssignButton>
                </RowFlex>
                <SkillOutWrapper>
                  <SkillWrapper>
                    {fromSkill.map((skill, idx) => (
                      <SkillBox
                        key={idx}
                        checked={skill.checked}
                        // onClick={()=>skill.skill&&onClickSkill(idx,skill)}
                      >
                        {skill.skill && (
                          <>
                            {/* <SkillCheckBox checked={skill.checked} type='checkbox'/> */}
                            &nbsp;&nbsp;<label>{skill.skill}</label>
                          </>
                        )}
                      </SkillBox>
                    ))}
                  </SkillWrapper>
                </SkillOutWrapper>
              </ColumnFlex>
            )}
            <div className="library__content">
              <div className="library__box">
                <BookTable
                  locale={{ emptyText: 'No results' }}
                  key={(record) => record.book_no}
                  className="contenttest-table"
                  dataSource={filteredbook}
                  columns={columns}
                  loading={loading}
                  bordered
                  scroll={{ y: 'calc(100vh - 244px)' }}
                  pagination={pg}
                  onChange={(pagination) => {
                    setPg(pagination);
                  }}
                  size="big"
                  color="#edf3fb"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(LevelUpBookListForAssign);
const BookTable = styled(CustomTable)`
  .ant-table-tbody {
    min-height: 700px;
  }
  .ant-table-body {
    min-height: 700px;
    table {
      min-height: 700px;
    }
  }
`;
