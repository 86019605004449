import React, { useState } from 'react';
import { Button, Col, Modal, Row, Input } from 'antd';
import { CloseCircleFilled, SettingOutlined } from '@ant-design/icons';

const EachDayCountModal = ({ disabled, data, setData, yoil }) => {
  const [visible, setVisible] = useState(false);

  const handleAddRow = () => {
    let lastRow = yoil.split('').map((item) => (item === '1' ? '1' : '0'));
    setData([...data, lastRow]);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)} shape="circle" icon={<SettingOutlined />} disabled={disabled} size="small" />
      <Modal title="Setting Each Day Count" open={visible} onOk={() => setVisible(false)} onCancel={() => setVisible(false)}>
        <Button onClick={handleAddRow} style={{ marginBottom: 10 }}>
          Add Row
        </Button>
        {data.map((item, index) => (
          <Row key={index}>
            {item.map((subItem, subIndex) => (
              <Col key={subIndex} span={3}>
                <Input
                  type="number"
                  disabled={yoil[subIndex] === '0'}
                  value={subItem}
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index][subIndex] = e.target.value;
                    setData(newData);
                  }}
                />
              </Col>
            ))}
            &nbsp;
            <CloseCircleFilled
              onClick={() => {
                const newData = [...data];
                newData.splice(index, 1);
                setData(newData);
              }}
            />
          </Row>
        ))}
      </Modal>
    </>
  );
};

export default EachDayCountModal;
