import React, { useMemo } from 'react';
import { Modal, Typography, Divider, Button } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
import MessageBox from 'src/components/DashBoard/SubPage/MessageBox';
import MessageItem from 'src/components/DashBoard/SubPage/MessageItem';

const { Text } = Typography;

function AITalkingReportAnswerModal({
  visible,
  handleCancel,
  chatList,
  topic,
  aiTopic,
  msgListLoading,
  loadMoreMsg,
  hasMore,
  lastMsgId,
  showButton,
}) {
  const currentFileList = useMemo(() => {
    let imageFileList = JSON.parse(aiTopic?.image_file_list || '[]');
    let audioFileList = JSON.parse(aiTopic?.audio_file_list || '[]');
    let currentFileList = imageFileList.concat(audioFileList);
    return currentFileList;
  }, [aiTopic]);

  return (
    <Modal
      title={`Topic: ${topic}`}
      visible={visible}
      width="50%"
      bodyStyle={{ padding: '0 15px 15px 15px', background: '#f5f5f5' }}
      headerStyle={{ background: '#f5f5f5' }}
      onCancel={handleCancel}
      footer={<> </>}
    >
      <div>
        <MessageBox
          id="MessageBox"
          style={{
            height: '55vh',
            overflow: 'auto',
            border: '1px solid #1890ff',
            margin: '2px 0 20px 0',
            backdround: '#fff',
          }}
          className="ai-chat-history"
        >
          <InfiniteScroll
            scrollableTarget="MessageBox"
            style={{ display: 'flex', flexDirection: 'column' }}
            inverse={false}
            hasMore={hasMore}
            dataLength={chatList?.length ?? 0}
            endMessage={<></>}
            // onScroll={handleScroll}
          >
            {msgListLoading ? (
              <p style={{ textAlign: 'center' }}>
                <b>Loading...</b>
              </p>
            ) : (
              <>
                {chatList?.map((thread, key) => {
                  const isMe = Number(userInfoVar()?.id) === thread?.user;
                  return (
                    <div className={thread?.user == 'AI봇' ? 'ai-msg' : 'user-msg'} key={`message-item-key${key}`}>
                      <React.Fragment>
                        <DateDivider
                          isShow={
                            (key < chatList.length &&
                              moment(new Date(thread?.created)).format('YYYY-MM-DD') !==
                                moment(new Date(chatList[key - 1]?.created)).format('YYYY-MM-DD')) ||
                            moment(new Date(thread?.idate)).format('YYYY-MM-DD') !==
                              moment(new Date(chatList[key - 1]?.idate)).format('YYYY-MM-DD')
                          }
                          date={
                            thread?.created
                              ? `${moment(new Date(thread?.created)).format('YYYY-MM-DD')}`
                              : `${moment(new Date(thread?.idate)).format('YYYY-MM-DD')}`
                          }
                        />
                        {showButton ? (
                          <></>
                        ) : (
                          key === 0 &&
                          (lastMsgId != null && hasMore ? (
                            <div className="load-more-btn" onClick={loadMoreMsg}>
                              <img src="/images/talking-tutor/load_more.png" alt="cguide" />
                              Load More Message
                            </div>
                          ) : (
                            <></>
                          ))
                        )}

                        <MessageItem
                          type="ai-talking-tutor"
                          isMe={isMe}
                          name={thread?.chat_user_name}
                          message={thread?.content ? thread?.content : thread?.completion_text || ''}
                          urls={thread?.urls}
                          currentFileList={currentFileList}
                          date={thread?.created ? thread?.created : thread?.idate?.split('T')[0]}
                          readYN={true}
                          unreadCount={0}
                        />
                      </React.Fragment>
                    </div>
                  );
                })}
              </>
            )}
          </InfiniteScroll>
        </MessageBox>
      </div>
    </Modal>
  );
}

export default AITalkingReportAnswerModal;

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};
