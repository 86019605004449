import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { useFullscreenStatus } from 'src/utils/hooks';

const FullScreenButton = ({ activeElement, element }) => {
  const [isWebView, setIsWebView] = useState(false);
  const maximizableElement = useRef(document.documentElement);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = 'Fullscreen not supported';
    isFullscreen = false;
    setIsFullscreen = undefined;
  }
  useEffect(() => {
    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      setIsWebView(true);
    }
  }, []);
  const handleClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  return <> {isWebView ? <></> : <span onClick={handleClick}>{isFullscreen ? activeElement : element}</span>}</>;
};

export default FullScreenButton;
