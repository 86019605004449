import React, { useState, useEffect, useMemo } from 'react';
import { Col, Input, Row, Modal, Typography, Button, Select, DatePicker } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';

import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useModal } from 'src/utils/hooks';
import { userInfoVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { useLoginTo } from 'src/utils/hooks';

const { Title, Text } = Typography;
const { confirm } = Modal;

const SeachBox = styled.div`
  margin: 0.5rem;
`;

function MoveStudent() {
  const loginTo = useLoginTo(false);
  const [moveRequests, setMoveRequests] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [searchValue, setSeachValue] = useState('');
  const [filterTable, setFilterTable] = useState(null);
  const company_idx = useMemo(() => userInfoVar().campus.company.idx, []);
  const company_name = userInfoVar()?.company_name;
  const { visible, showModal, handleCancel, popupMode } = useModal();

  const { data, refetch } = useQuery(queries.company.MOVE_STUDENT_CAMPUS_REQUESTS_LIST, {
    fetchPolicy: 'no-cache',
    variables: { company_idx },
  });

  const [deleteBoard] = useMutation(mutations.board.DELETE_BOARD, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (data) {
      setMoveRequests(
        data.getMoveCampustRequestList.map((item, index) => {
          return {
            key: index,
            idx: item.idx,
            from_branch: item.from_campus?.campus?.name || '',
            from_campus: item.from_campus.name,
            id: item.user.id,
            name: item.user.name,
            to_branch: item.to_campus?.campus?.name || '',
            to_campus: item.to_campus.name,
            schedule_date: moment(item.idate).format('YYYY-MM-DD'),
            stat: item.stat === '0' ? 'pending' : 'completed',
            transfer_by: item.transfer_by,
            loginTo,
          };
        }),
      );
    }
  }, [data]);

  const handleSearch = (value) => {
    setSeachValue(value);
    const filterTable = moveRequests.filter((board) => {
      return Object.keys(board).some((field) => String(board[field]).toLowerCase().includes(value.toLowerCase()));
    });

    setFilterTable(filterTable);
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'From Branch',
      dataIndex: 'from_branch',
      key: 'from_branch',
    },
    {
      title: 'From Campus',
      dataIndex: 'from_campus',
      key: 'from_campus',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'To Branch',
      dataIndex: 'to_branch',
      key: 'to_branch',
    },
    {
      title: 'To Campus',
      dataIndex: 'to_campus',
      key: 'to_campus',
    },
    {
      title: 'Transfer Date',
      dataIndex: 'schedule_date',
      key: 'schedule_date',
    },
    {
      title: 'Status',
      dataIndex: 'stat',
      key: 'stat',
    },
    {
      title: 'Transfer By',
      dataIndex: 'transfer_by',
      key: 'transfer_by',
    },
    {
      title: '로그인',
      key: 'action',
      render: (text, record) => (
        <CustomButton
          type="primary"
          size="small"
          onClick={() => {
            record.loginTo(record.id);
          }}
        >
          로그인
        </CustomButton>
      ),
    },
  ];
  return (
    <>
      <HeaderTitle level={4}>Student Transfer</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="board-campusnotice-height">
            <SeachBox>
              <div
                className="board-campusnotice-height-dv"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                }}
              >
                <Title level={5}></Title>
                <div>
                  <Input.Search
                    className="board-campusnotice-top-fields"
                    placeholder="검색어를 입력해 주세요"
                    allowClear
                    style={{ width: 250, marginRight: 10 }}
                    enterButton
                    onSearch={handleSearch}
                    onChange={onSeachChange}
                    value={searchValue}
                  />

                  <CustomButton onClick={() => handleSearch('')} style={{ marginRight: 10 }}>
                    <RedoOutlined />
                  </CustomButton>
                  <Button type="primary" onClick={showModal}>
                    <PlusOutlined />
                    Create
                  </Button>
                </div>
              </div>
            </SeachBox>
            <CustomTable
              columns={columns}
              dataSource={filterTable ? filterTable : moveRequests}
              pagination={{ pageSize: 10 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setcheckRowList([rowIndex]);
                  },
                };
              }}
              rowClassName={(record, index) => {
                return index === checkRowList[0] ? 'table-row-selected' : '';
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>
      {visible && <CreateCampusMoveRequest visible={visible} handleCancel={handleCancel} refetch={refetch} />}
    </>
  );
}

export default MoveStudent;

const CreateCampusMoveRequest = ({ visible, handleCancel, refetch }) => {
  const [fromCampus, setFromCampus] = useState(null);
  const [toCampus, setToCampus] = useState(null);
  const [studentIdx, setStudentIdx] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [transferBy, setTransferBy] = useState('');
  const company_idx = useMemo(() => userInfoVar().campus.company.idx, []);
  const company_name = userInfoVar()?.company_name;

  const { data } = useQuery(queries.campus.GET_CAMPUS_LIST, {
    variables: { company_idx },
  });

  const { data: users } = useQuery(queries.getUser.GET_CAMPUS_STUDENT_USERS, {
    variables: { campus_idx: fromCampus },
    skip: !fromCampus,
  });

  const [createCampusMoveRequest] = useMutation(mutations.company.CREATE_CAMPUS_MOVE_REQUEST, {
    onCompleted(data) {
      if (data) {
        openNotification('Campus Move Request successfully created.');
        handleCancel();
        refetch();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleCreate = () => {
    if (!fromCampus || !toCampus || !scheduleDate || !studentIdx) {
      openNotification('모든 항목을 입력해 주세요.');
      return;
    }

    confirm({
      title: 'Do you want to create this campus move request?',
      onOk() {
        createCampusMoveRequest({
          variables: {
            company_idx,
            from_campus_idx: fromCampus,
            to_campus_idx: toCampus,
            schedule_date: scheduleDate,
            student_idx: studentIdx,
            transfer_by: transferBy,
          },
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <Modal title="Create Campus Move Request" open={visible} onOk={handleCreate} onCancel={handleCancel}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Text>Company: {company_name}</Text>
        </Col>
        <Col span={24}>
          <Text>From Campus</Text>
          <Select value={fromCampus} onChange={(value) => setFromCampus(value)} placeholder="From Campus" style={{ width: '100%' }}>
            {data?.getCampusList.map((item) => (
              <Select.Option key={item.idx} value={item.idx}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={24}>
          <Text>Student</Text>
          <Select value={studentIdx} onChange={(value) => setStudentIdx(value)} placeholder="Student" style={{ width: '100%' }}>
            {users?.getCampusStudentUsers.map((item) => (
              <Select.Option key={item.idx} value={item.idx}>
                {item.name}(ID: {item.view_id})
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={24}>
          <Text>To Campus</Text>
          <Select value={toCampus} onChange={(value) => setToCampus(value)} placeholder="To Campus" style={{ width: '100%' }}>
            {data?.getCampusList
              .filter((item) => item.idx !== fromCampus)
              .map((item) => (
                <Select.Option key={item.idx} value={item.idx}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Col>
        <Col span={24}>
          <Text>Transfer By</Text>
          <Input value={transferBy} onChange={(e) => setTransferBy(e.target.value)} placeholder="Transfer By" />
        </Col>
        <Col span={24}>
          <Text>Schedule Date</Text>
          <DatePicker
            value={scheduleDate ? moment(scheduleDate) : null}
            onChange={(date, dateString) => setScheduleDate(dateString)}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
    </Modal>
  );
};
