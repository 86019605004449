import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row, Typography, Input, Select, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { HeaderTitle, HalfWrapper, ListTitleWrapper, TableBtnsWrapper } from 'src/components/common/Styles';
//import Detail from './Detail';
import CampusModal from 'src/components/Modal/CampusModal';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_INFO, GET_CAMPUS_LIST } from 'src/operations/queries/campus';
import { DELETE_CAMPUSES } from 'src/operations/mutations/campus';
//import { openNotification } from 'src/components/common/Notification';
import moment from 'moment';

import { useLoginTo } from 'src/utils/hooks';
import { EditOutlined, ControlOutlined, MenuOutlined } from '@ant-design/icons';
import { openNotification } from 'src/components/common/Notification';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool, isPturn } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import CampusPriceSettingModal from 'src/components/Modal/CampusPriceSettingModal';
import CampusMenuSettingModal from 'src/components/Modal/CampusMenuSettingModal';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcelUserSheet } from 'src/utils/index';
const { Option } = Select;
const { Title } = Typography;

const columnsForAfterSchool = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'left',
    width: '110px',
  },
  {
    title: '학교명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (text, record) => {
      return <span>{text}</span>;
    },
  },
  {
    title: '지역',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
  },
  {
    title: '선생님',
    dataIndex: 'director_name',
    key: 'director_name',
    align: 'center',
  },
  {
    title: '선생님연락처',
    dataIndex: 'director_phone',
    key: 'director_phone',
    align: 'center',
  },
  // {
  //   title: '연락처',
  //   dataIndex: 'phone',
  //   key: 'phone',
  //   align: 'center',
  // },
  {
    title: '가입일',
    dataIndex: 'regdate',
    key: 'regdate',
    align: 'center',
    render: (item) => {
      return item ? moment(item).format('YYYY-MM-DD') : '';
    },
  },
  {
    title: '학생수',
    dataIndex: 'member_count',
    key: 'member_count',
    align: 'center',
  },
  {
    title: '수정',
    dataIndex: 'edit',
    key: 'edit',
    align: 'center',
    render: (_, record) => {
      return (
        <>
          <CustomButton
            onClick={() => {
              record.showCampusInfoModal('edit', record);
            }}
            size="small"
            icon={<EditOutlined />}
            title="학교 정보 수정"
          />
          &nbsp;
          <CustomButton
            onClick={() => {
              record.showCampusPriceSettingsModal(record);
            }}
            size="small"
            icon={<ControlOutlined />}
            title="가격관리"
          />
          &nbsp;
          <CustomButton
            onClick={() => {
              record.showCampusMenuSettingsModal(record);
            }}
            size="small"
            icon={<MenuOutlined />}
            title="메뉴 관리"
          />
        </>
      );
    },
  },
  {
    title: '로그인',
    dataIndex: 'login',
    key: 'login',
    align: 'center',
    render: (_, record) => {
      return (
        <>
          <CustomButton
            type="primary"
            size="small"
            onClick={() => {
              //loginTo(record.manager.id, record.client, record.loginUser);
              record.loginTo(record.manager.id);
            }}
          >
            로그인
          </CustomButton>
        </>
      );
    },
  },
];

const SEARCH_OPTIONS = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '학교',
    value: 'school',
  },
  {
    label: '강사',
    value: 'teacher',
  },
];

const SEARCH_OPTIONS_FOR_FC = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '지사',
    value: 'branch',
  },
  {
    label: '학교',
    value: 'school',
  },
  {
    label: '강사',
    value: 'teacher',
  },
];

const Campuses = () => {
  const companyName = useSelector(classStoreData);
  const [showModal, setShowModal] = useState(false);
  const [campusInfo, setCampusInfo] = useState(0);
  const [campusInfoModalMode, setCampusInfoModalMode] = useState('create');
  const [campusRegion, setCampusRegion] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [checkedCampusIdxList, setCheckedCampusIdxList] = useState([]);

  const { visible: goodsPriceVisible, showModal: goodsPriceShowModal, handleCancel: handleGoodsPriceCancel } = useModal();

  const { visible: menuSettingVisible, showModal: menuSettingShowModal, handleCancel: handleMenuSettingCancel } = useModal();

  const userinfo = userInfoVar();
  const hasBranch = useMemo(() => {
    //FC본사이면서..지사가 있는 경움.
    if (userinfo?.campus?.company?.has_branch === '1' && userinfo?.type === 4) {
      return true;
    }
    return false;
  }, [userinfo]);
  //console.log('hasBranch', hasBranch);

  const showCampusInfoModal = (mode, campusInfo) => {
    setCampusInfoModalMode(mode);
    setCampusInfo(campusInfo);
    setShowModal(true);
  };

  const showCampusPriceSettingsModal = (campusInfo) => {
    setCampusInfo(campusInfo);
    goodsPriceShowModal(true);
  };

  const showCampusMenuSettingsModal = (campusInfo) => {
    setCampusInfo(campusInfo);
    menuSettingShowModal(true);
  };

  //https://github.com/apollographql/react-apollo/issues/3180
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: dataCampus, loading: loadingCampusList } = useQuery(GET_CAMPUS_LIST, {
    skip: !dataUser,
    variables: {
      company_idx: dataUser && dataUser.getUserData.campus.fc_company_idx,
    },
  });
  //const [deleteCampuses, { loading: loadingDelete }] = useMutation();

  const [deleteCampuses, { loading: loadingDelete }] = useMutation(DELETE_CAMPUSES, {
    onCompleted: () => {
      openNotification('삭제완료!');
      setCheckedCampusIdxList([]);
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const loginTo = useLoginTo(false);

  const campusSubject = useMemo(() => {
    return isAfterSchool(companyName) ? '학교' : '학원';
  }, [companyName]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'left',
    },
    {
      title: '학교명',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    {
      title: '지역',
      dataIndex: 'region',
      key: 'region',
      align: 'center',
    },
    {
      title: '선생님',
      dataIndex: 'director_name',
      key: 'director_name',
      align: 'center',
    },
    {
      title: '선생님연락처',
      dataIndex: 'director_phone',
      key: 'director_phone',
      align: 'center',
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: '가입일',
      dataIndex: 'regdate',
      key: 'regdate',
      align: 'center',
      render: (item) => {
        return item ? moment(item).format('YYYY-MM-DD') : '';
      },
    },
    {
      title: '학생수',
      dataIndex: 'member_count',
      key: 'member_count',
      align: 'center',
    },
    {
      title: '수정',
      dataIndex: 'edit',
      key: 'edit',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            {!record.external_id && (
              <CustomButton
                onClick={() => {
                  record.showCampusInfoModal('edit', record);
                }}
                size="small"
                icon={<EditOutlined />}
                title="학교 정보 수정"
              />
            )}
            &nbsp;
            <CustomButton
              onClick={() => {
                record.showCampusPriceSettingsModal(record);
              }}
              size="small"
              icon={<ControlOutlined />}
              title="가격관리"
            />
            &nbsp;
            <CustomButton
              onClick={() => {
                record.showCampusMenuSettingsModal(record);
              }}
              size="small"
              icon={<MenuOutlined />}
              title="메뉴 관리"
            />
          </>
        );
      },
    },
    {
      title: '로그인',
      dataIndex: 'login',
      key: 'login',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <CustomButton
              type="primary"
              size="small"
              onClick={() => {
                //loginTo(record.manager.id, record.client, record.loginUser);
                record.loginTo(record.manager.id);
              }}
            >
              로그인
            </CustomButton>
          </>
        );
      },
    },
  ];

  const realColumns = useMemo(() => {
    if (isPturn(companyName) || hasBranch) {
      const colsForBranch = (
        companyName === 'englishtap' ? columnsForAfterSchool.filter((e) => e.title !== '지역') : columnsForAfterSchool
      ).map((item, key) => {
        return key === 1 ? { ...item, title: `${campusSubject}명` } : { ...item };
      });
      if (hasBranch) {
        const add = {
          title: '지사',
          dataIndex: 'branch_name',
          key: 'branch_name',
          align: 'center',
        };
        return [...colsForBranch.slice(undefined, 1), add, ...colsForBranch.slice(1)];
      }
      return colsForBranch;
    } else {
      return columns.map((item, key) => {
        return key === 1 ? { ...item, title: `${campusSubject}명` } : { ...item };
      });
    }
  }, [campusSubject, companyName, hasBranch]);

  const campusList = useMemo(() => {
    if (dataCampus) {
      return dataCampus.getCampusList
        .map((item, key) => {
          const { idx, type, name, phone, manager, regdate, member_count, region, external_id } = item;
          const { name: director_name, phone: director_phone } = manager;

          return {
            key: key,
            No: isAfterSchool(companyName) ? key + 1 : parseInt(dataCampus.getCampusList.length) - parseInt(key),
            idx,
            type,
            name,
            phone,
            manager,
            director_name,
            director_phone,
            regdate,
            region,
            member_count,
            campusSubject,
            external_id,
            branch_name: item?.campus?.name || '',
            //client,
            //아래 두항목을 column에서 호출하려고 하다보니 어쩔 수 없이 전달
            loginTo,
            showCampusInfoModal,
            showCampusPriceSettingsModal,
            showCampusMenuSettingsModal,
          };
        })
        .filter((item2) => {
          if (campusRegion !== '') {
            return campusRegion === item2.region;
          }
          return true;
        })
        .filter((item3) => {
          if (searchValue !== '') {
            if (isPturn(companyName)) {
              if (searchOption === 'school') {
                return item3.name.includes(searchValue);
              } else if (searchOption === 'teacher') {
                return item3.director_name.includes(searchValue);
              } else if (searchOption === 'branch') {
                return item3?.branch_name?.includes(searchValue);
              } else {
                return (
                  item3.name.includes(searchValue) || item3.director_name.includes(searchValue) || item3?.branch_name?.includes(searchValue)
                );
              }
            } else {
              return item3.name.includes(searchValue);
            }
          }
          return true;
        });
    }
    return [];
  }, [dataCampus, campusRegion, searchValue, companyName, searchOption]);

  const regionList = useMemo(() => {
    if (campusList) {
      return campusList.reduce((acc, cur) => {
        if (cur.region) {
          acc.push(cur.region);
        }
        return acc;
      }, []);
    }
    return [];
  }, [campusList]);

  const totalMemberCount = useMemo(() => {
    return campusList.reduce((prev, cur) => {
      return prev + cur.member_count;
    }, 0);
  }, [campusList]);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleChangeChecked = (_, rows) => {
    setCheckedCampusIdxList(rows.map((item) => item.idx));
  };

  const handleDeleteCampus = () => {
    if (checkedCampusIdxList.length === 0) {
      Modal.info({ title: `삭제할 ${campusSubject}}을/를 선택하세요.` });
      return false;
    }
    Modal.confirm({
      title: `${campusSubject} 및 관련된 데이터가 모두 삭제됩니다 .정말 삭제하시겠습니까?`,
      onOk: () => {
        deleteCampuses({
          variables: { campus_idx_list: checkedCampusIdxList },
          refetchQueries: [
            {
              query: GET_CAMPUS_LIST,
              variables: { company_idx: dataUser && dataUser.getUserData.campus.fc_company_idx },
            },
          ],
        });
      },
      oncancel: () => {
        //
      },
    });
  };
  function handleExportToExcel(e) {
    e.preventDefault();
    const fileName = `List`;
    ExportExcelUserSheet(realColumns, campusList, fileName);
  }
  return (
    <>
      <HeaderTitle level={4}>{campusSubject} 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="campus-halfwrapper">
            <ListTitleWrapper>
              <Title level={5}>{campusSubject} 목록</Title>
              <div>
                {isAfterSchool(companyName) ? (
                  <Select
                    style={{ marginRight: '10px' }}
                    placeholder="검색"
                    defaultValue=""
                    value={searchOption}
                    onChange={setSearchOption}
                    options={hasBranch ? SEARCH_OPTIONS_FOR_FC : SEARCH_OPTIONS}
                  />
                ) : (
                  <Select
                    style={{ marginRight: '10px' }}
                    placeholder="지역"
                    defaultValue=""
                    value={campusRegion}
                    onChange={setCampusRegion}
                  >
                    <Option key={`region-key-all`} value="">
                      전체
                    </Option>
                    {regionList &&
                      regionList.map((r, key) => (
                        <Option key={`region-key-${key}`} value={r}>
                          {r}
                        </Option>
                      ))}
                  </Select>
                )}

                <Input.Search style={{ width: '150px' }} placeholder={`search`} maxLength={20} onSearch={setSearchValue} />
              </div>
            </ListTitleWrapper>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TableBtnsWrapper>
                <div>
                  <span style={{ marginRight: 10 }}>총 {totalMemberCount}명</span>
                  {!hasBranch && companyName !== 'educo' && (
                    <CustomButton
                      type="primary"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        showCampusInfoModal('create', 0);
                      }}
                    >
                      등록
                    </CustomButton>
                  )}

                  {companyName !== 'educo' && (
                    <CustomButton danger={true} style={{ marginRight: 10 }} loading={loadingDelete} onClick={handleDeleteCampus}>
                      삭제
                    </CustomButton>
                  )}
                </div>
              </TableBtnsWrapper>
              {isAfterSchool(companyName) && (
                <a href="/" onClick={handleExportToExcel} className="excel-img">
                  <img style={{ height: '20px', width: '20px' }} src={imageexcel} alt="download" />
                </a>
              )}
            </div>
            <CustomTable
              rowSelection={{
                onChange: handleChangeChecked,
              }}
              dataSource={campusList}
              columns={realColumns}
              pagination={{ pageSize: 10, position: ['bottomCenter'], showSizeChanger: false }}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              color="#edf3fb"
              loading={loadingCampusList}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {dataUser && showModal && (
        <CampusModal
          handleCancel={handleCancel}
          visible={showModal}
          maskClosable={false}
          popupMode={campusInfoModalMode}
          company_idx={dataUser.getUserData.campus.fc_company_idx}
          campus_idx={campusInfo?.idx}
          campus_name={campusInfo?.name}
        />
      )}

      {goodsPriceVisible && (
        <CampusPriceSettingModal
          handleCancel={handleGoodsPriceCancel}
          visible={goodsPriceVisible}
          company_idx={dataUser.getUserData.campus.fc_company_idx}
          campus_idx={campusInfo?.idx}
          campus_name={campusInfo?.name}
        />
      )}

      {menuSettingVisible && (
        <CampusMenuSettingModal
          handleCancel={handleMenuSettingCancel}
          visible={menuSettingVisible}
          company_idx={dataUser.getUserData.campus.fc_company_idx}
          campus_idx={campusInfo?.idx}
          campus_name={campusInfo?.name}
        />
      )}
    </>
  );
};

export default Campuses;
