import { gql } from '@apollo/client';

export const GET_COMPANY_BOOKS = gql`
  query getCompanyBookContentList($company_idx: Int!) {
    getCompanyBookContentList(company_idx: $company_idx) {
      book {
        code
      }
    }
  }
`;

export const GET_COMPANY_BOOKS_CODE = gql`
  query getCompanyBookContentList($company_idx: Int!) {
    getCompanyBookContentList(company_idx: $company_idx) {
      book_code
    }
  }
`;

export const GET_COMPANY_BOOK_CONTENTS = gql`
  query getCompanyBookContentList($company_idx: Int!, $is_all: Int, $is_sub: Int) {
    getCompanyBookContentList(company_idx: $company_idx, is_all: $is_all, is_sub: $is_sub) {
      book {
        code
      }
      book_list {
        code
      }
      credit_duration
      prices {
        days
      }
    }
  }
`;

export const GET_COMPANY_BOOK_CONTENTS_SHORT = gql`
  query getCompanyBookContentList($company_idx: Int!, $is_all: Int, $is_sub: Int) {
    getCompanyBookContentList(company_idx: $company_idx, is_all: $is_all, is_sub: $is_sub) {
      idx
      book {
        code
        book_sub {
          title
        }
      }
      price
      prices {
        idx
        book_idx
        price
        days
      }
    }
  }
`;

export const GET_CAMPUS_BOOK_CONTENTS_SHORT = gql`
  query getCampusBookContentList($campus_idx: Int!) {
    getCampusBookContentList(campus_idx: $campus_idx) {
      idx
      campus_idx
      book_code
      book_list {
        title
      }
      price
      prices {
        idx
        campus_bd_idx
        book_code
        price
        days
      }
      credit_duration
      idate
    }
  }
`;

export const GET_COMPANY_CAMPUS_AND_CONTENTS1 = gql`
  query bookCampusAndContents($company_idx: Int!) {
    getCompanyBookContentList(company_idx: $company_idx) {
      book {
        code
      }
      book_list {
        code
      }
    }
    getCampusList(company_idx: $company_idx) {
      idx
      name
    }
  }
`;
export const GET_COMPANY_CAMPUS_AND_CONTENTS = gql`
  query bookCampusAndContents($company_idx: Int!) {
    getCompanyBookContentList(company_idx: $company_idx) {
      book {
        code
      }
      prices {
        idx
        book_idx
        price
        days
      }
      book_list {
        code
      }
    }
    getCampusList(company_idx: $company_idx) {
      idx
      name
    }
  }
`;

export const GET_COMPANY_LIST = gql`
  query companyList {
    companyList {
      idx
      name
      phone
      address
      fax
      manager {
        idx
        id
      }
    }
  }
`;

export const GET_COMPANY_BOOK_DISPLAY_LIST = gql`
  query getCompanyBookDisplayList($company_idx: Int!) {
    getCompanyBookDisplayList(company_idx: $company_idx) {
      idx
      book {
        code
        parent_code
        level_name
        volume_name
        book_sub {
          title
        }
      }
      book_list {
        code
      }
      is_asp
      is_main
      is_bundle
      is_use_credit
      cnt_preview
      shopping_mall_product
      price
      prices {
        idx
        book_idx
        price
        days
      }
    }
  }
`;

export const GET_COMPANY_CONTENTS = gql`
  query getCompanyBookContentList($company_idx: Int!) {
    getCompanyBookContentList(company_idx: $company_idx) {
      book {
        code
        book_sub {
          short_title
        }
      }
      book_list {
        code
        volume_name
        book_sub {
          short_title
        }
      }
    }
  }
`;

export const GET_BRANCH_LIST = gql`
  query branchList {
    branchList {
      idx
      name
      phone
      address
      address2
      user {
        id
      }
    }
  }
`;

export const COMPANY_BOOK_STORE_PACKAGE_LIST = gql`
  query companyBookStorePackageList($company_idx: Int!) {
    companyBookStorePackageList(company_idx: $company_idx) {
      idx
      book_code
      is_bundle
      is_use_credit
      shopping_mall_product
      price
      book {
        book_sub {
          title
          cover_path
          book_category {
            name
          }
        }
      }
      book_list {
        level_no
        level_name
        volume_no
        book_sub {
          title
        }
      }
    }
  }
`;

export const GET_COMPANY_USER_MENU_LIST = gql`
  query getCompanyUserMenuList($company_idx: Int!, $user_type: String) {
    companyUserMenuList(company_idx: $company_idx, user_type: $user_type) {
      idx
      user_type
      company_user_menu_item {
        idx
        parent_id
        item_name
        is_hidden
        show_icon
        icon_url
        index
        route_item {
          idx
          name
          route
          type
          pass_user_info
          is_root_only
        }
      }
    }
  }
`;

export const GET_COMPANY_OPEN_AI_USAGE_LIST = gql`
  query getCompanyOpenAiUsageList($dateStr: String!) {
    getCompanyOpenAiUsageList(dateStr: $dateStr) {
      idx
      name
      prompt_token_count
      completion_token_count
      total_token_count
      image_count
      total_cost
    }
  }
`;

export const GET_META_STATICS_COMPANY_CAMPUS = gql`
  query getCampusMetaStatics($company_idx: Int!, $selected_date: String) {
    getCampusMetaStatics(company_idx: $company_idx, selected_date: $selected_date)
  }
`;

export const GET_NEW_O_MATICS_STATICS_COMPANY_CAMPUS = gql`
  query getCampusNewOMaticsStatics($company_idx: Int!, $selected_date: String) {
    getCampusNewOMaticsStatics(company_idx: $company_idx, selected_date: $selected_date)
  }
`;

export const GET_NEW_O_MATICS_STATICS_DETAIL = gql`
  query getCampusNewOMaticsStaticsDetail($campus_idx: Int!, $selected_date: String, $gettype: String, $type: String!) {
    getCampusNewOMaticsStaticsDetail(campus_idx: $campus_idx, selected_date: $selected_date, gettype: $gettype, type: $type) {
      idx
      external_study_idx
      assigned_idx
      type
      study_date
      data
      article_data
      recording_data
      wpm
      exam_total
      exam_correct
      idate
      last_study_timestamp
    }
  }
`;

export const MOVE_STUDENT_CAMPUS_REQUESTS_LIST = gql`
  query getMoveCampustRequestList($company_idx: Int!) {
    getMoveCampustRequestList(company_idx: $company_idx) {
      idx
      user {
        idx
        id
        name
        phone
        email
        type
      }
      from_campus {
        idx
        name
        campus {
          idx
          name
        }
      }
      to_campus {
        idx
        name
        campus {
          idx
          name
        }
      }
      idate
      stat
      schedule_date
      transfer_by
    }
  }
`;
