import React, { useState,useEffect } from 'react';
import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import Campus from 'src/components/DashBoard/MainPage/Campus';
import SubPage from 'src/components/DashBoard/SubPage';
import SimplePopup from 'src/popups/SimplePopup';
import { useHistory } from 'react-router-dom';
import { isAfterSchool, isPturn, getDashboardTitle } from 'src/utils';

import moment from 'moment';
//캠퍼스 원장용 대시보드 메인 페이지
const CampusPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  const history = useHistory();
  // const now = moment();
  // const popup_date = moment('2024-09-19');
  useEffect(()=>{
    if(company==='pturnbookorder'){
      history.push('/book/order')
    }
  },[company])
  return (
    <>
      {/* {isPturn(company) && <SimplePopup />} */}
      <Row justify="space-between" className="buttonlisting-dashboard">
        <HeaderTitle level={4}>{getDashboardTitle(company)}</HeaderTitle>
        <ButtonWrapper className="buttonlisting-wrap cmp-btn">
          {isPturn(company) && (
            <>
              <Button
                className="btn-ebook order"
                type="primary"
                size="large"
                style={{ margin: 5 }}
                onClick={() => {
                  history.push('/book/order');
                }}
              >
                교재주문
              </Button>
              <Button
                className="btn-ebook content"
                type="primary"
                size="large"
                style={{ margin: 5 }}
                onClick={() => {
                  history.push('/folder');
                }}
              >
                수업자료
              </Button>
              <Button
                className="btn-ebook"
                type="primary"
                size="large"
                style={{ margin: 5 }}
                onClick={() => {
                  history.push('/ebook/SM');
                }}
              >
                E-Book
              </Button>
            </>
          )}
          {isAfterSchool(company) ? (
            <>
              {tab === 'sub' && (
                <Button
                  className="btn-schedule"
                  type={tab === 'main' && 'primary'}
                  size="large"
                  style={{ margin: 5 }}
                  onClick={() => setTab('main')}
                >
                  Schedule
                </Button>
              )}
              {tab === 'main' && (
                <Button className="btn-messageicon" type={tab === 'sub' && 'primary'} size="large" onClick={() => setTab('sub')}>
                  쪽지함
                </Button>
              )}
            </>
          ) : (
            <>
              {/* <Button
                className="btn-schedule"
                type={tab === 'main' && 'primary'}
                size="large"
                style={{ margin: 5 }}
                onClick={() => setTab('main')}
              >
                Schedule
              </Button>
               */}
              {company === 'creo' || isPturn(company) ? (
                <></>
              ) : (
                <>
                  {company==='intoreading'&&<Button
                    className="btn-ebook"
                    type="primary"
                    size="large"
                    style={{ margin: 5 }}
                    onClick={() => {
                      history.push('/ebook');
                    }}
                  >
                    E-Book
                  </Button>}
                  <Button
                    className="btn-ebook order"
                    type="primary"
                    size="large"
                    style={{ margin: 5 }}
                    onClick={() => {
                      history.push('/classroom');
                    }}
                  >
                    Class Mgt.
                  </Button>
                </>
              )}
            </>
          )}
        </ButtonWrapper>
      </Row>

      <Row gutter={[24, 16]}>{tab === 'main' ? <Campus /> : <SubPage />}</Row>
    </>
  );
};

export default CampusPage;
