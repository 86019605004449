import React, { useMemo, useRef } from 'react';
import { Button, Col, Row, List } from 'antd';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts';
import { useReactToPrint } from 'react-to-print';
import { PrinterTwoTone } from '@ant-design/icons';

import { DivBlock, HeaderTitle } from 'src/components/common/Styles';
import { ASSIGNED_TEST_USER_SINGLE_FOR_MANGER } from 'src/operations/queries/getTests';
import BackButton from 'src/components/common/BackButton';

import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';

const TitleBlock = styled(DivBlock)`
  padding-top: 5px;

  @media print {
    padding-top: -5px;
    background-color: #f08c00;
    justify-content: center;
    padding-top: 0px;
    margin-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    h4 {
      font-size: 1.3em;
    }
    .white {
      color: #fff;
    }
    .yellow {
      color: #ffd43b;
    }
    button {
      display: none;
    }
  }
`;

const TestBlockWrapper = styled.div`
  padding: 12px;
  background: #fff;
`;

const PrintWrapper = styled.div`
  width: 100%;

  .print-section {
    display: none;
  }

  @media print {
    padding: 10px;
    .web-section {
      display: none;
    }
    .print-section {
      display: block;
    }
  }
`;

const getType = (title) => {
  if (title === 'Placement Test P Phonics' || title === 'Placement Test SK Type P' || title === '초등영어 P') {
    return 'P';
  } else if (title === 'Placement Test Type A' || title === 'Placement Test SK Type A' || title === '초등영어 A') {
    return 'A';
  } else if (title === 'Placement Test Type B' || title === 'Placement Test SK Type B' || title === '초등영어 B') {
    return 'B';
  } else if (title === 'Placement Test Type C' || title === 'Placement Test SK Type C' || title === '초등영어 C') {
    return 'C';
  }
  return 'P';
};

//배점 테이블
const getEachScore = (title, category) => {
  const SCORE_TABLE = {
    P: {
      listening: 5,
      consonants: 5,
      vowels: 5,
      long_vowels: 5,
    },
    A: {
      listening: 4,
      reading: 4,
      vocabulary: 4,
      writing: 4,
    },
    B: {
      listening: 4,
      vocabulary: 4,
      grammar: 4,
      reading: 4,
    },
    C: {
      listening: 3,
      vocabulary: 2,
      grammar: 3,
      reading: 3,
    },
  };
  const type = getType(title);

  return SCORE_TABLE[type] ? SCORE_TABLE[type][category] || 0 : 0;
};

const getAwesomeLevel = (score, testType) => {
  if (testType !== 'P') {
    const SCORE_TABLE = {
      A: { a: '3-1', b: '2-4', c: '2-1', d: '1-4', e: '1-1' },
      B: { a: '4-4', b: '4-1', c: '3-4', d: '3-1', e: '2-4' },
      C: { a: '6-1', b: '5-4', c: '5-1', d: '4-4', e: '4-1' },
    };
    let awesomeLevel = 'Awesome ';
    if (score >= 90) {
      awesomeLevel += SCORE_TABLE[testType]['a'];
    } else if (score >= 80) {
      awesomeLevel += SCORE_TABLE[testType]['b'];
    } else if (score >= 70) {
      awesomeLevel += SCORE_TABLE[testType]['c'];
    } else if (score >= 60) {
      awesomeLevel += SCORE_TABLE[testType]['d'];
    } else {
      awesomeLevel += SCORE_TABLE[testType]['e'];
    }
    return awesomeLevel;
  }
  let phonicsLevel = 'KidsTap Phonics ';
  if (score > 90) {
    phonicsLevel = 'Awesome 1-1';
  } else if (score >= 85) {
    phonicsLevel += '4';
  } else if (score >= 80) {
    phonicsLevel += '3';
  } else if (score >= 60) {
    phonicsLevel += '2';
  } else {
    phonicsLevel += '1';
  }

  return phonicsLevel;
};

const getSmartEclassLevel = (score, testType) => {
  if (!['A', 'B', 'C', 'P'].includes(testType)) {
    return '';
  }

  let idx = 0;
  if (testType === 'P') {
    if (score > 90) {
      idx = 4;
    } else if (score >= 85) {
      idx = 3;
    } else if (score > 75) {
      idx = 2;
    } else if (score >= 60) {
      idx = 1;
    } else {
      idx = 0;
    }
  } else {
    if (score >= 90) {
      idx = 4;
    } else if (score >= 80) {
      idx = 3;
    } else if (score >= 70) {
      idx = 2;
    } else if (score >= 60) {
      idx = 1;
    } else {
      idx = 0;
    }
  }

  const RESULT_LEVEL_TABLES = [
    { P: 'CoCo Phonics 1', A: 'Smart e class 1-1', B: 'Smart e class 2-4', C: 'Smart e class 4-1' },
    { P: 'CoCo Phonics 3', A: 'Smart e class 1-4', B: 'Smart e class 3-1', C: 'Smart e class 4-4' },
    { P: 'CoCo Phonics 5', A: 'Smart e class 2-1', B: 'Smart e class 3-4', C: 'Smart e class 5-1' },
    { P: 'Super CoCo Phonics 1', A: 'Smart e class 2-4', B: 'Smart e class 4-1', C: 'Smart e class 5-4' },
    { P: 'Smart e class 1-1', A: 'Smart e class 3-1', B: 'Smart e class 4-4', C: 'Smart e class 6-1' },
  ];

  return RESULT_LEVEL_TABLES[idx][testType] || '';
};

const getSneakersLevel = (score, testType) => {
  if (!['A', 'B', 'C', 'P'].includes(testType)) {
    return '';
  }

  let idx = 0;
  if (testType === 'P') {
    if (score > 90) {
      idx = 4;
    } else if (score >= 85) {
      idx = 3;
    } else if (score > 75) {
      idx = 2;
    } else if (score >= 60) {
      idx = 1;
    } else {
      idx = 0;
    }
  } else {
    if (score >= 90) {
      idx = 4;
    } else if (score >= 80) {
      idx = 3;
    } else if (score >= 70) {
      idx = 2;
    } else if (score >= 60) {
      idx = 1;
    } else {
      idx = 0;
    }
  }

  const RESULT_LEVEL_TABLES = [
    { P: 'Sneakers 1', A: 'Sneakers 1', B: 'Sneakers 1', C: 'Sneakers 1' },
    { P: 'Sneakers 2', A: 'Sneakers 2', B: 'Sneakers 2', C: 'Sneakers 2' },
    { P: 'Sneakers 3', A: 'Sneakers 3', B: 'Sneakers 3', C: 'Sneakers 3' },
    { P: 'Sneakers 4', A: 'Sneakers 4', B: 'Sneakers 4', C: 'Sneakers 4' },
    { P: 'Sneakers 5', A: 'Sneakers 5', B: 'Sneakers 5', C: 'Sneakers 5' },
  ];

  return RESULT_LEVEL_TABLES[idx][testType] || '';
};

const StudentReportLevelTest = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { data } = useQuery(ASSIGNED_TEST_USER_SINGLE_FOR_MANGER, {
    variables: { idx: parseInt(props.id) },
  });
  const categories = useMemo(() => {
    if (data) {
      const test_answers = data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.test_answers;

      if (test_answers) {
        return test_answers
          .map((item) => item.meta)
          .reduce((acc, cur) => {
            if (acc.length < 1) {
              return [cur];
            } else if (!acc.includes(cur)) {
              return [...acc, cur];
            }
            return acc;
          }, []);
      }
    }
    return [];
  }, [data]);
  const scoreData = useMemo(() => {
    if (categories && data) {
      const test_cats = data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.test_answers.map((item) => item.meta);
      const assigned_test_answers = data?.assignedTestUserSingleForManager?.assigned_test_answers;
      if (test_cats && assigned_test_answers) {
        const scores = {};
        if (test_cats.length === assigned_test_answers.length) {
          let sumTotal = 100,
            sumScore = 0;
          for (let i = 0; i < test_cats.length; i++) {
            const eachScore = getEachScore(data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.title, test_cats[i]);
            if (!scores[test_cats[i]]) {
              scores[test_cats[i]] = { total: eachScore, score: 0, totalNumber: 1, correctNumber: 0 };
            } else {
              scores[test_cats[i]].total += eachScore;
              scores[test_cats[i]].totalNumber++;
            }
            scores[test_cats[i]].score += assigned_test_answers[i].is_correct ? eachScore : 0;
            scores[test_cats[i]].correctNumber += assigned_test_answers[i].is_correct ? 1 : 0;

            sumScore += assigned_test_answers[i].is_correct ? eachScore : 0;
          }
          const title = data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.title;

          scores['total'] = { total: sumTotal, score: sumScore };

          return Object.keys(scores).map((item) => {
            const section =
              item && `${title} ${item.charAt(0).toUpperCase() + item.slice(1)}\n(${scores[item].score}/${scores[item].total})`;
            const score = item === 'total' ? Math.round((10 * 100 * scores[item].score) / scores[item].total) / 10 : scores[item].score;
            const correctNumber = scores[item].correctNumber || 0;
            const totalNumber = scores[item].totalNumber || 0;
            const totalScore = scores[item].total;
            return {
              section,
              score,
              totalScore,
              correctNumber,
              totalNumber,
            };
          });
        }
      }
    }
    return [];
  }, [categories, data]);
  const chartData = useMemo(() => {
    if (scoreData) {
      return scoreData.map((item) => {
        const arr = item.section.split(' ');
        const cat = arr[arr.length - 1];

        return { name: cat, score: item.score };
      });
    }
  }, [scoreData]);

  const radarData = useMemo(() => {
    if (scoreData) {
      return scoreData.slice(0, scoreData.length - 1).map((item) => {
        const arr = item.section.split(' ');
        const cat = arr[arr.length - 1];
        const score = Math.round((10 * 100 * item.score) / item.totalScore) / 10;
        const [title] = cat.split('\n');

        return { subject: title, A: score, fullMark: 100 };
      });
    }
    return [];
  }, [scoreData]);

  const testType = useMemo(() => {
    if (data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.title) {
      return getType(data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.title);
    }
    return '';
  }, [data]);

  console.log('scoreData', scoreData);

  return (
    <PrintWrapper ref={componentRef}>
      <Col span={24} className="top-section">
        <TitleBlock>
          <HeaderTitle level={4}>
            <span className="white">Placement Test</span>&nbsp;<span className="yellow">결과분석표</span>
          </HeaderTitle>
          <div>
            <Button onClick={handlePrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
              <PrinterTwoTone />
              Print
            </Button>
            <BackButton />
          </div>
        </TitleBlock>
      </Col>

      <Col span={24} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <StudentInformation
          userInfo={data?.assignedTestUserSingleForManager?.user}
          startTime={data?.assignedTestUserSingleForManager?.start_time}
          testLevel={data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.title}
        />
      </Col>

      <Col span={24} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <ScoreInformation scoreData={scoreData} chartData={chartData} />
      </Col>

      <Col span={24}>
        <HeaderTitle level={5}>테스트 결과</HeaderTitle>
        <TestBlockWrapper>
          <div style={{ display: 'flex', paddingBottom: '15px' }}>
            <ResponsiveContainer className="web-section" width="100%" height={300}>
              <BarChart
                width={100}
                height={300}
                data={chartData}
                barSize={40}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Bar dataKey="score" fill="#8884d8">
                  {chartData &&
                    chartData.map((item, index) => (
                      <Cell key={`cell-${index}`} fill={`${index === chartData.length - 1 ? '#f59f00' : '#ffd43b'}`} />
                    ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer className="print-section" width={700} height={250}>
              <BarChart
                width={100}
                height={300}
                data={chartData}
                barSize={40}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Bar dataKey="score" fill="#8884d8">
                  {chartData &&
                    chartData.map((item, index) => (
                      <Cell key={`cell-${index}`} fill={`${index === chartData.length - 1 ? '#f59f00' : '#ffd43b'}`} />
                    ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </TestBlockWrapper>
      </Col>
      <Col span={24}>
        <HeaderTitle level={5}>종합분석</HeaderTitle>
        <TestBlockWrapper>
          <div style={{ display: 'flex' }}>
            <ResponsiveContainer className="web-section" width="50%" height={400}>
              <RadarChart cx={200} cy={200} outerRadius={100} width={200} height={200} data={radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis />
                <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
              </RadarChart>
            </ResponsiveContainer>
            <ResponsiveContainer className="print-section" width={400} height={400}>
              <RadarChart cx={150} cy={150} outerRadius={100} width={150} height={150} data={radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis />
                <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
              </RadarChart>
            </ResponsiveContainer>
            <CommentInformation userInfo={data?.assignedTestUserSingleForManager?.user} chartData={chartData} testType={testType} />
          </div>
        </TestBlockWrapper>
      </Col>
    </PrintWrapper>
  );
};

export default StudentReportLevelTest;

const StudentInformation = ({ userInfo, startTime, testLevel }) => {
  return (
    <>
      <Row gutter={[16, 16]} style={{ fontSize: '1.1em' }}>
        <StudentInformationLabel label="테스트 일시" value={startTime ? moment.utc(startTime).format('YYYY-MM-DD HH:mm') : ''} />
      </Row>
      <Row gutter={[16, 16]} style={{ fontSize: '1.1em' }}>
        <StudentInformationLabel label="응시자" value={userInfo?.name} />
      </Row>

      <Row gutter={[16, 16]} style={{ fontSize: '1.1em' }}>
        <StudentInformationLabel label="테스트 레벨" value={testLevel} isLast={true} />
      </Row>
    </>
  );
};

const StudentInformationLabel = ({ label, value, isLast = false }) => {
  const rowStyle = {
    backgroundColor: '#fff',
    borderTop: '1px solid #666',
    borderLeft: '1px solid #666',
    borderRight: '1px solid #666',
    borderBottom: `${isLast ? '1px solid #666' : ''}`,
  };
  return (
    <>
      <Col span={4} style={{ ...rowStyle, borderRight: '1px solid #666', backgroundColor: '#ffec99' }}>
        <span style={{ fontWeight: 'bold' }}>{label}</span>
      </Col>
      <Col span={20} style={{ ...rowStyle, borderLeft: '0px solid #fff' }}>
        {value}
      </Col>
    </>
  );
};

const ScoreInformation = ({ scoreData, chartData }) => {
  const cellStyle = { backgroundColor: '#fff', borderLeft: '1px solid #666', borderTop: '1px solid #666', borderBottom: '1px solid #666' };
  return (
    <>
      <HeaderTitle level={5}>나의 점수</HeaderTitle>
      <Row gutter={[16, 16]} style={{ fontSize: '1.1em' }}>
        <Col span={4} style={{ ...cellStyle, backgroundColor: '#ffec99' }}>
          <span style={{ fontWeight: 'bold' }}>문제</span>
        </Col>
        <Col span={8} style={cellStyle}>
          {`${scoreData && scoreData.reduce((a, i) => (a += i.correctNumber), 0)}/${
            scoreData && scoreData.reduce((a, i) => (a += i.totalNumber), 0)
          }`}
        </Col>
        <Col span={4} style={{ ...cellStyle, backgroundColor: '#ffec99' }}>
          <span style={{ fontWeight: 'bold' }}>점수</span>
        </Col>
        <Col span={8} style={{ ...cellStyle, borderRight: '1px solid #666' }}>
          {chartData && chartData[chartData.length - 1] && chartData[chartData.length - 1].score}
        </Col>
      </Row>
    </>
  );
};

const CommentInformation = ({ userInfo, chartData, testType }) => {
  const companyName = useSelector(classStoreData);
  const isSmartEclass = useMemo(() => {
    return isAfterSchool(companyName);
  }, [companyName]);

  const resultLevel = useMemo(() => {
    if (chartData && chartData[chartData.length - 1] && testType) {
      const score = chartData[chartData.length - 1].score;
      if (companyName === 'educo' || companyName === 'w_english' || userInfoVar()?.campus_idx === 2506) {
        return getSneakersLevel(score, testType);
      }
      return isSmartEclass ? getSmartEclassLevel(score, testType) : getAwesomeLevel(score, testType);
    }
    return '';
  }, [chartData, testType, isSmartEclass, companyName]);

  const EmSpan = styled.span`
    font-weight: bold;
    color: #fd7e14;
  `;
  return (
    <div>
      <p
        style={{
          fontSize: '1.2em',
          position: 'relative',
          top: '50%',
          transform: `translateY(-50%)`,
          border: '2px solid #ff922b',
          padding: '15px',
          borderRadius: '10px',
          backgroundColor: '#fff3bf',
        }}
      >
        <EmSpan>{userInfo?.name}</EmSpan> 학생의 점수는 <br />
        <EmSpan>{chartData && chartData[chartData.length - 1] && chartData[chartData.length - 1].score}</EmSpan>점입니다.
        <br />
        추천학습과정은 <EmSpan>{resultLevel}</EmSpan> 입니다.
        <br />
        <br /> * Placement Test는 절대적인 기준이 아닌 <br />
        참고자료입니다. <br />
        학습자의 상황, 진도 등에 따라 <br />
        적절히 활용하시기 바랍니다.
      </p>
    </div>
  );
};
