import React, { useEffect } from 'react';
import LevelTab, { useLevelTab } from './LevelTab';
import styled from 'styled-components';
import { BookCard, ESL_PPT_CDN_BASE_URL } from './CommonComp';
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  text-align: center;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  `;
const Button = styled(Title)`
width: 300px;
justify-content: space-between;
border: 1px solid #ccc;
  cursor: pointer;
`;
const ButtonNum = styled.div`
  min-width: 30px;
  min-height: 30px;
  border-radius: 15px;
  background-color: #f0f3f9;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SECTIONS = {
  2.2: {
    4: [
      { no: 1, title: 'Dear Primo' },
      { no: 2, title: 'Games Around the World' },
      { no: 3, title: 'Volcanoes' },
      { no: 4, title: 'To The Rescue' },
      { no: 5, title: 'April Rain Song + Helicopters(Poems About Nature)' },
      { no: 6, title: 'Rain Forests' },
      { no: 7, title: 'African Savannas' },
      { no: 8, title: 'How the Beetle Got Her Colors' },
      { no: 9, title: 'How the Finch Got its Colors' },
    ],
    5: [
      { no: 10, title: 'Brave Bessie' },
      { no: 11, title: "The Prince's Frog" },
      { no: 12, title: 'Grace for President' },
      { no: 13, title: 'Helping to Make Smiles' },
      { no: 14, title: 'A Call to Compost' },
      { no: 15, title: 'Should Students Wear Uniforms' },
      { no: 16, title: 'Once Upon a Baby Brother' },
      { no: 17, title: 'Bully-Free Zone' },
      { no: 18, title: "The Woodcutter's Gift" },
      { no: 19, title: "Earth's Resources" },
    ],
    6: [
      { no: 20, title: 'Money Madness' },
      { no: 21, title: 'King Midas and the Golden Touch' },
      { no: 22, title: 'The Contest of Athena and Poseidon' },
      { no: 23, title: "Poseidon's Gift" },
      { no: 24, title: 'Books to the Ceiling (The World of Ideas)' },
      { no: 25, title: "I've Got This Covered (The World of Ideas)" },
      { no: 26, title: 'Eating While Reading (The World of Ideas)' },
      { no: 27, title: 'Clay Play + Crayons (The World of Ideas)' },
      { no: 28, title: 'My Light' },
      { no: 29, title: 'The Power of Water' },
      { no: 30, title: 'Astronaut Handbook' },
      { no: 31, title: 'Teamwork to the Top' },
    ],
  },
  3.1: {
    1: [
      { no: 1, title: 'Gary the Dreamer' },
      { no: 2, title: 'Sharing Cultures' },
      { no: 3, title: 'Yoon and the Jade Bracelet' },
      { no: 4, title: 'Family Traditions' },
      { no: 5, title: 'Protecting Our Parks' },
      { no: 6, title: '5 Questions for George McDonald' },
      { no: 7, title: 'All Aboard!  Elijah McCoy_s Steam Engine' },
      { no: 8, title: 'Lighting the World' },
      { no: 9, title: 'Wolf' },
      { no: 10, title: 'Jennie and the Wolf' },
    ],
    2: [
      { no: 11, title: 'VOTE!' },
      { no: 12, title: 'A Plan for the People' },
      { no: 13, title: 'The Castle of Hester Street' },
      { no: 14, title: ' Next Stop, America!' },
      { no: 15, title: 'The Inventor Thinks Up Helicopters' },
      { no: 16, title: 'Montgolfier Brothers Hot Air Balloon' },
      { no: 17, title: 'Whooping Cranes in Danger' },
      { no: 18, title: 'Help The Manatees' },
      { no: 19, title: 'Roadrunner_s Dance' },
      { no: 20, title: 'Deltona is Going Batty' },
    ],
    3: [
      { no: 21, title: 'Earth' },
      { no: 22, title: 'Why the Sun is Red' },
      { no: 23, title: 'Martina the Beautiful Cockroach' },
      { no: 24, title: 'Get a Backbone!' },
      { no: 25, title: 'Birth of an Anthem' },
      { no: 26, title: 'Discovering Life Long Ago' },
      { no: 27, title: 'Big Ideas from Nature' },
      { no: 28, title: 'Perdix Invents the Saw' },
      { no: 29, title: 'Finding Lincoln' },
      { no: 30, title: 'A Great American Teacher' },
    ],
  },
  3.2: {
    4: [
      { no: 1, title: 'The Talented Clementine' },
      { no: 2, title: 'Clementine And The Family Meeting' },
      { no: 3, title: 'Amazing Wildlife of the Mojave' },
      { no: 4, title: 'The Little Half Chick' },
      { no: 5, title: 'The Winningest Woman of the Iditarod Dog Sled Race' },
      { no: 6, title: 'Narcissa' },
      { no: 7, title: 'The Real Story of Stone Soup' },
      { no: 8, title: 'Healthful Food Choices' },
      { no: 9, title: 'Hot Air Balloon' },
      { no: 10, title: 'Bellerophon and Pegasus' },
    ],
    5: [
      { no: 11, title: 'Elizabeth Leads the Way' },
      { no: 12, title: 'Susan B. Anthony Takes Action!' },
      { no: 13, title: 'Clever Jack Takes the Cake' },
      { no: 14, title: 'Money Then and Now' },
      { no: 15, title: "It's All in the Wind" },
      { no: 16, title: 'Power for All' },
      { no: 17, title: 'Wildfires' },
      { no: 18, title: 'Windy Gale And The Great Hurricane' },
      { no: 19, title: 'Bravo, Tavo!' },
      { no: 20, title: 'Trash Into Art' },
    ],
    6: [
      { no: 21, title: 'Looking Up To Ellen Ochoa' },
      { no: 22, title: 'A Flight To Lunar City' },
      { no: 23, title: 'King Midas and the Golden Touch' },
      { no: 24, title: "Carlos's Gift" },
      { no: 25, title: "Ollie's Escape" },
      { no: 26, title: 'The Gentleman Bookworm' },
      { no: 27, title: 'Alligators and Crocodiles' },
      { no: 28, title: 'The Monkey and the Crocodile' },
      { no: 29, title: "Nora's Ark" },
      { no: 30, title: 'The Wind and the Sun' },
    ],
  },
  4.1: {
    1: [
      { no: 1, title: 'Earthquakes' },
      { no: 2, title: 'Weathering the Storm' },
      { no: 3, title: 'Experts, Incorporated' },
      { no: 4, title: 'Speaking Out to Stop Bullying' },
      { no: 5, title: 'Kids in Business' },
      { no: 6, title: 'Starting a Successful Business' },
      { no: 7, title: 'A Crash Course in Forces and Motion with Max Axiom, Super Scientist' },
      { no: 8, title: 'The Box-Zip Project' },
      { no: 9, title: 'The Princess and the Pizza' },
      { no: 10, title: 'Tomas and His Sons' },
    ],
    2: [
      { no: 11, title: 'Spiders' },
      { no: 12, title: 'Anansi and the Birds' },
      { no: 13, title: 'Ranita, The Frog Princess' },
      { no: 14, title: 'Pecos Bill and the Bear Lake Monster' },
      { no: 15, title: 'The Sandpiper' },
      { no: 16, title: 'Fog' },
      { no: 17, title: 'The Buffalo are Back' },
      { no: 18, title: 'Energy in the Ecosystem -' },
      { no: 19, title: 'The Secret Message' },
      { no: 20, title: 'The Fox and the Goat' },
    ],
    3: [
      { no: 21, title: 'Aguinaldo' },
      { no: 22, title: 'Partaking in Public Service' },
      { no: 23, title: 'Delivering Justice W.W. Law and the Fight for Civil Rights' },
      { no: 24, title: 'Keeping Freedom in the Family' },
      { no: 25, title: 'A New Kind of Corn' },
      { no: 26, title: 'The Pick of the Patch' },
      { no: 27, title: 'The Cricket in Times Square' },
      { no: 28, title: 'At the Library' },
      { no: 29, title: "Abe's Honest Words" },
      { no: 30, title: 'A New Birth of Freedom' },
    ],
  },
  4.2: {
    4: [
      { no: 1, title: 'See How They Run' },
      { no: 2, title: 'The Birth of American Democracy' },
      { no: 3, title: 'The Moon Over Star' },
      { no: 4, title: 'Star Parties' },
      { no: 5, title: 'Swimming to the Rock' },
      { no: 6, title: 'Genius+Winner' },
      { no: 7, title: 'Why Does the Moon Change Shape' },
      { no: 8, title: 'How It Came to Be' },
      { no: 9, title: 'LaRue for Mayor' },
      { no: 10, title: 'Bringing Government Home' },
    ],
    5: [
      { no: 11, title: 'A Drop of Water' },
      { no: 12, title: 'The Incredible Shrinking Potion' },
      { no: 13, title: "Mama, I'll Give You the World" },
      { no: 14, title: 'Miami by Way of Fujian' },
      { no: 15, title: 'Rediscovering Our Spanish Beginnings' },
      { no: 16, title: "History's Mysteries" },
      { no: 17, title: 'How Ben Franklin Stole The Lightning' },
      { no: 18, title: 'Energy is Everywhere' },
      { no: 19, title: 'Apples to Oregon' },
      { no: 20, title: 'Go for the Gold!' },
    ],
    6: [
      { no: 21, title: 'Energy Island' },
      { no: 22, title: 'Of Fire and Water' },
      { no: 23, title: 'The Game of Silence' },
      { no: 24, title: 'Native Americans Yesterday and Today' },
      { no: 25, title: "The Drum-Birdfoot's Grampa-From MyChinatown" },
      { no: 26, title: 'Growing Up and My People' },
      { no: 27, title: 'The Big Picture of Economics' },
      { no: 28, title: "The Miller's Good Luck" },
      { no: 29, title: 'Valley of the Moon' },
      { no: 30, title: 'One Nation Many Cultures' },
    ],
  },
};

const Wonders = () => {
  const { levelNo, onChangeLevelNo } = useLevelTab(0);
  useEffect(() => {
    onChangeLevelNo('2.2');
  }, []);
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <LevelTab
        levelNo={levelNo}
        levels={Object.keys(SECTIONS)}
        wonders={true}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />
      <Grid>{levelNo !== 0 && Object.keys(SECTIONS[levelNo]).map((unit) => <Title key={unit}>Unit {unit}</Title>)}</Grid>

      <Grid>
        {levelNo !== 0 &&
          Object.values(SECTIONS[levelNo]).map((numbers, colIndex) => (
            <Column key={colIndex}>
              {numbers.map((num) => (
                <Button
                  key={num}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `${ESL_PPT_CDN_BASE_URL}WLA_${levelNo}_${Object.keys(SECTIONS[levelNo])[colIndex]}_${num.no}/index.html`,
                      '_blank',
                    );
                  }}
                >
                  <ButtonNum>{num.no}</ButtonNum>
                  <span>{num.title}</span>
                  <span></span>
                </Button>
              ))}
            </Column>
          ))}
      </Grid>
    </div>
  );
};

export default Wonders;

const UnitGroup = ({ level, volume, section }) => {
  return (
    <BookCard className="unit-con">
      {Array(5)
        .fill(0)
        .map((_, key) => {
          const unitNo = (section - 1) * 5 + key + 1;
          return (
            <div
              key={`lesson-list${key}`}
              className="unit-wrap"
              onClick={(e) => {
                e.stopPropagation();
                window.open(`${ESL_PPT_CDN_BASE_URL}CREO_CROSS_OVER_${level}-${volume}_U${unitNo}/index.html`, '_blank');
              }}
              data-url={`${ESL_PPT_CDN_BASE_URL}CREO_CROSS_OVER_${level}-${volume}_U${unitNo}/index.html`}
            >
              <h2>UNIT {unitNo}</h2>
            </div>
          );
        })}
    </BookCard>
  );
};
