import React, { useCallback, useState, useEffect } from 'react';
import { Redirect, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import AICoachHeader from 'src/components/Header/AICoachHeader';
import Navigation from 'src/components/Navigation';
import Loading from 'src/components/common/Loading';

import { useQueryProxy } from 'src/operations/proxy/user';
import { USER_ME } from 'src/operations/queries/getUser';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { getCurrentToken } from '../utils';
import { storage } from 'src/utils/hooks';
import { useAuthCheck } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';
import jwtDecode from 'jwt-decode';

// import { useSelector, useDispatch } from 'react-redux';
// import { classStoreData, lnbVisibleData, updateLnbVisible, toggleLnbVisible } from 'src/operations/store';

const { Content } = Layout;

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AICoachLayout({ component: Component, logOut, isHeaderStatic = false, closeHeader = false, removeMargin = false, ...rest }) {
  const history = useHistory();
  const queryParams = useQueryParams();
  const token = getCurrentToken();
  const client = useApolloClient();
  const [loaded, setLoaded] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  // const dispatch = useDispatch();
  // const companyName = useSelector(classStoreData);
  // const lnbVisible = useSelector(lnbVisibleData);
  // useEffect(() => {
  //   if (companyName === 'rise') {
  //     dispatch(updateLnbVisible(true));
  //   }
  // }, [companyName]);
  const { loading, data } = useQueryProxy(USER_ME, {
    variables: { token },
    skip: !token,
    onError(error) {
      isLogedIn(false);
      history.push('/login');
      setLoaded(true);
    },
  });

  useEffect(() => {
    if (!isLogedIn() || !token) {
      history.push('/login');
    }
  }, [history, token]);

  useEffect(() => {
    if (data?.me) {
      userInfoVar(data?.me);
      setLoaded(true);
    } else if (token) userInfoVar(jwtDecode(token));
  }, [data, loading, token]);

  const onCollapse = useCallback(() => {
    setCollapsed((prev) => !prev);
    // dispatch(toggleLnbVisible());
  }, []);

  const matchTest = useRouteMatch(['/test/new/:id/:type']);
  useEffect(() => {
    //placement test 용으로 로그인했을 경우, 시험보는 페이지가 아니면 로그아웃 처리한다.
    if (!matchTest && data?.me?.level_test === 1) {
      isLogedIn(false);
      userInfoVar(null);
      client.clearStore();
      client.cache.gc();
      storage.removeItem('culp_token');
      //history.replace('/level-test');
      //return <Redirect to={`/level-test`} />;
    }
  }, [data, matchTest, client]);

  const { isAccepted } = useAuthCheck();

  if (isAccepted === false) {
    return <Redirect path="/" />;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {loading || !loaded ? (
            <Loading />
          ) : (
            <Layout>
              {/* {lnbVisible && <Navigation />} */}
              {collapsed && <Navigation />}

              <Layout className="main-layout">
                {!queryParams.get('removeHeader') ? (
                  <AICoachHeader collapsed={collapsed} onCollapse={onCollapse} isStatic={isHeaderStatic} closeHeader={closeHeader} />
                  // <AICoachHeader collapsed={lnbVisible} onCollapse={onCollapse} isStatic={isHeaderStatic} closeHeader={closeHeader} />
                ) : null}
                <Content style={removeMargin ? {} : { margin: '0 16px' }}>
                  <Component {...matchProps} {...rest} />
                </Content>
              </Layout>
            </Layout>
          )}
        </>
      )}
    />
  );
}

export default AICoachLayout;
