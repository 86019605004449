import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const BookOrderMenuGroup = ({ currentMenu }) => {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 10 }}>
      <MenuButton
        type={`${currentMenu === 'book-order' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/book/order');
        }}
      >
        교재 주문
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'book-order-list' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/book/order/list');
        }}
      >
        주문내역
      </MenuButton>
      {companyName!=='englishtap'&&companyName!=='pturnbookorder'&&<>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'book-return' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/book/return');
        }}
        >
        교재반품
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'book-return-list' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/book/return/list');
        }}
        >
        반품내역
      </MenuButton>
        </>}
    </div>
  );
};

export default BookOrderMenuGroup;
