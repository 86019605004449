import React, { useEffect } from 'react';
import LevelTab, { useLevelTab } from './LevelTab';
import styled from 'styled-components';
import { BookCard, ESL_PPT_CDN_BASE_URL } from './CommonComp';
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  text-align: center;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
`;
const Button = styled(Title)`
  width: 300px;
  justify-content: space-between;
  border: 1px solid #ccc;
  cursor: pointer;
`;
const ButtonNum = styled.div`
  min-width: 30px;
  min-height: 30px;
  border-radius: 15px;
  background-color: #f0f3f9;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SECTIONS = {
  3.2: {
    6: [
      { no: 1, title: 'Animal Behaviors' },
      { no: 2, title: 'Frozen Alive' },
      { no: 3, title: 'This Is Your Life Cycle' },
      { no: 4, title: 'The Nose Awards' },
      { no: 5, title: 'Octopus Escapes Again!' },
      { no: 6, title: 'T.J. The Siberian Tiger Cub' },
      { no: 7, title: 'Performance Task' },
    ],
    7: [
      { no: 8, title: 'Make A Difference' },
      { no: 9, title: "Let's Build a Park!" },
      { no: 10, title: 'Farmer Will Allen And The Growing Table' },
      { no: 11, title: 'One Plastic Bag Isatou Ceesay and the Recycling Women of the Gambia' },
      { no: 12, title: 'Energy Island-How One Community Harnessed the Wind and Changed Their World' },
      { no: 13, title: "The Storyteller's Candle" },
      { no: 14, title: 'Performance Task' },
    ],
    8: [
      { no: 15, title: 'Imagine! Invent!' },
      { no: 16, title: 'A Century of Amazing Inventions' },
      { no: 17, title: 'Timeless Thomas How Thomas Edison Changed Our Lives' },
      { no: 18, title: 'A Bumpy Ride' },
      { no: 19, title: 'Rosie Revere, Engineer' },
      { no: 20, title: "Edison's Best Inventions" },
      { no: 21, title: 'Performance Task' },
    ],
    9: [
      { no: 22, title: 'From Farm to Table' },
      { no: 23, title: 'Great Idea from Great Parents!' },
      { no: 24, title: 'How Did That Get in My Lunchbox' },
      { no: 25, title: 'Carrots, Farm to Fork' },
      { no: 26, title: 'How Do You Raise a Raisin' },
      { no: 27, title: "It's Our Garden From Seeds to Harvest in a School Garden" },
      { no: 28, title: 'Performance Task' },
    ],
    10: [
      { no: 29, title: 'Tell a Tale' },
      { no: 30, title: 'Why We Share Stories' },
      { no: 31, title: 'When The Giant Stirred' },
      { no: 32, title: 'Why the Sky is Far Away' },
      { no: 33, title: 'Cinder Al And The Stinky Footwear' },
      { no: 34, title: 'Compay Mono and Comay Jicotea' },
      { no: 35, title: 'Performance Task' },
    ],
  },
  4.1: {
    1: [
      { no: 1, title: 'What Makes Us Who We Are' },
      { no: 2, title: 'The Story of You' },
      { no: 3, title: 'Flora & Ulysses The Illuminated Adventures' },
      { no: 4, title: 'Yes! We Are Latinos' },
      { no: 5, title: 'The Year of the Rat' },
      { no: 6, title: 'Kitoto the Mighty' },
      { no: 7, title: 'Performance Task' },
    ],
    2: [
      { no: 8, title: 'Come to Your Senses' },
      { no: 9, title: 'What are the Five Senses' },
      { no: 10, title: 'The Science Behind Sight' },
      { no: 11, title: 'Animal Senses' },
      { no: 12, title: 'Blind Ambition' },
      { no: 13, title: 'The Game of Silence' },
      { no: 14, title: 'Performance Task' },
    ],
    3: [
      { no: 15, title: 'Rise to the Occassion' },
      { no: 16, title: 'Never Give Up!' },
      { no: 17, title: 'Rent Party Jazz' },
      { no: 18, title: 'The Galveston Hurricane of 1900' },
      { no: 19, title: 'Catch Me If You Can!' },
      { no: 20, title: 'My Diary from Here to There' },
      { no: 21, title: 'Performance Task' },
    ],
    4: [
      { no: 22, title: 'Heroic Feats' },
      { no: 23, title: "Who's a Hero" },
      { no: 24, title: 'Prince Charming Misplaces His Bride' },
      { no: 25, title: 'Smokejumpers to the Rescue!' },
      { no: 26, title: 'Perseus and the Fall of Medusa' },
      { no: 27, title: 'The Battle of the Alamo' },
      { no: 28, title: 'Performance Task' },
    ],
    5: [
      { no: 29, title: 'Art Everywhere' },
      { no: 30, title: 'Why Art Centers Matter' },
      { no: 31, title: 'The Beatles Were Fab (and They Were Funny)' },
      { no: 32, title: 'How Can Photos Take Us Back In Time' },
      { no: 33, title: 'Let’s Dance Around the World' },
      { no: 34, title: 'The Art of Poetry' },
      { no: 35, title: 'Performance Task' },
    ],
  },
  5.1: {
    1: [
      { no: 1, title: 'Inventors at Work' },
      { no: 2, title: 'Government Must Fund Inventors' },
      { no: 3, title: 'The Inventor’s Secret What Thomas Edison Told Henry Ford' },
      { no: 4, title: 'Winds of Hope' },
      { no: 5, title: 'Wheelchair Sports Hang Glider to Wheeler-Dealer' },
      { no: 6, title: 'Captain ArsenioInventions and (Mis)adventures in Flight' },
      { no: 7, title: 'Performance Task' },
    ],
    2: [
      { no: 8, title: 'What a Story' },
      { no: 9, title: 'Many Ways to Tell a Story' },
      { no: 10, title: 'Airborn' },
      { no: 11, title: 'The Secret Garden' },
      { no: 12, title: 'The Miracle of Spring' },
      { no: 13, title: 'The Poem That Will Not End Fun with Poetic Forms and Voices' },
      { no: 14, title: 'Performance Task' },
    ],
    3: [
      { no: 15, title: 'Natural Disasters' },
      { no: 16, title: 'Who Studies Natural Disasters' },
      { no: 17, title: 'Eruption! Volcanoes and the Science of Saving Lives' },
      { no: 18, title: 'Between the Glacier and the Sea, The Alaska Earthquake' },
      { no: 19, title: 'Quaking Earth, Racing Waves' },
      { no: 20, title: 'Hurricanes The Science Behind Killer Storms' },
      { no: 21, title: 'Performance Task' },
    ],
    4: [
      { no: 22, title: 'Wild West' },
      { no: 23, title: 'Why Go West' },
      { no: 24, title: 'Explore the Wild West!' },
      { no: 25, title: "The Celestial's Railroad" },
      { no: 26, title: 'Homesteading' },
      { no: 27, title: 'A Pioneer Sampler The Daily Life of a Pioneer Family in 1840' },
      { no: 28, title: 'Performance Task' },
    ],
    5: [
      { no: 29, title: 'Project Earth' },
      { no: 30, title: 'The Protective Power of Nature Preserves' },
      { no: 31, title: 'Potatoes on Rooftops Farming in the City' },
      { no: 32, title: 'Living Green' },
      { no: 33, title: 'The Good Garden How One Family Went from Hunger to Having Enough' },
      { no: 34, title: 'Parrots Over Puerto Rico' },
      { no: 35, title: 'Performance Task' },
    ],
  },
};

const IntoReading = () => {
  const { levelNo, onChangeLevelNo } = useLevelTab(0);
  useEffect(() => {
    onChangeLevelNo('3.2');
  }, []);
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <LevelTab
        levelNo={levelNo}
        levels={Object.keys(SECTIONS)}
        wonders={true}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />
      <>
      <Grid>{levelNo !== 0 && Object.keys(SECTIONS[levelNo]).filter((e,i)=>(i/3)<1).map((unit) => <Title key={unit}>Unit {unit}</Title>)}</Grid>

      <Grid>
        {levelNo !== 0 &&
          Object.values(SECTIONS[levelNo]).filter((e,i)=>(i/3)<1).map((numbers, colIndex) => (
            <Column key={colIndex}>
              {numbers.map((num) => (
                <Button
                  key={num}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `${ESL_PPT_CDN_BASE_URL}INTO_${levelNo}_${Object.keys(SECTIONS[levelNo])[colIndex]}_${num.no}/index.html`,
                      '_blank',
                    );
                  }}
                >
                  <ButtonNum>{num.no}</ButtonNum>
                  <span>{num.title}</span>
                  <span></span>
                </Button>
              ))}
            </Column>
          ))}
      </Grid>
      </>
      <>
      <Grid>{levelNo !== 0 && Object.keys(SECTIONS[levelNo]).filter((e,i)=>(i/3)>=1).map((unit) => <Title key={unit}>Unit {unit}</Title>)}</Grid>

      <Grid>
        {levelNo !== 0 &&
          Object.values(SECTIONS[levelNo]).filter((e,i)=>(i/3)>=1).map((numbers, colIndex) => (
            <Column key={colIndex}>
              {numbers.map((num) => (
                <Button
                  key={num}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `${ESL_PPT_CDN_BASE_URL}INTO_${levelNo}_${Object.keys(SECTIONS[levelNo])[colIndex]}_${num.no}/index.html`,
                      '_blank',
                    );
                  }}
                >
                  <ButtonNum>{num.no}</ButtonNum>
                  <span>{num.title}</span>
                  <span></span>
                </Button>
              ))}
            </Column>
          ))}
      </Grid>
      </>
    </div>
  );
};

export default IntoReading;

// const UnitGroup = ({ level, volume, section }) => {
//   return (
//     <BookCard className="unit-con">
//       {Array(5)
//         .fill(0)
//         .map((_, key) => {
//           const unitNo = (section - 1) * 5 + key + 1;
//           return (
//             <div
//               key={`lesson-list${key}`}
//               className="unit-wrap"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 window.open(`${ESL_PPT_CDN_BASE_URL}CREO_CROSS_OVER_${level}-${volume}_U${unitNo}/index.html`, '_blank');
//               }}
//               data-url={`${ESL_PPT_CDN_BASE_URL}CREO_CROSS_OVER_${level}-${volume}_U${unitNo}/index.html`}
//             >
//               <h2>UNIT {unitNo}</h2>
//             </div>
//           );
//         })}
//     </BookCard>
//   );
// };
