import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const WritingReportCoachMenuGroup = ({ currentMenu }) => {
  const history = useHistory();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
      <MenuButton
        type={`${currentMenu === 'assign' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/aicoach/topiclist');
        }}
      >
        Assign
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'list' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/aicoach/list');
        }}
      >
        Assigned Group List
      </MenuButton>
    </div>
  );
};

export default WritingReportCoachMenuGroup;
