import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Modal, Row, Typography } from 'antd';

import styled from 'styled-components';

import RowItem from 'src/components/common/RowItem';
import BackButton from 'src/components/common/BackButton';
import StudentModal from 'src/components/Modal/StudentModal';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';
import { HalfWrapper, HeaderTitle } from 'src/components/common/Styles';

import { useModal } from 'src/utils/hooks';
// import { userTypVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import { CREATE_USER_VACATION_HISTORY, RETURN_TO_CAMPUS } from 'src/operations/mutations/vacation';
import * as queries from 'src/operations/queries';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const { Title, Text } = Typography;
const { confirm } = Modal;

const DetailPage = styled.div`
  background-color: #ecf1f3;
  padding: 15px 15px;
`;

// const FieldName = styled(Col)`
//   width: '40%';
//   font-size: '0.9rem';
//   color: #777777;
// `;

const StudentDetail = () => {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  //const location = useLocation();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [classHistory, setClassHistory] = useState(null);
  const { visible, showModal, handleCancel, popupMode } = useModal();

  // const { data, refetch } = useQuery(queries.getUser.GET_USERS, {
  //   fetchPolicy: 'no-cache',
  //   variables: { type: userTypVar().student, stat: state?.stat },
  // });

  const { data, refetch } = useQuery(queries.getUser.GET_USER_INFO, { fetchPolicy: 'no-cache', variables: { idx: Number(id) } });

  const {
    data: fetchClassStudent,
    loading: studentHistoryLoading,
    //refetch: studentHistoryRefetch,
  } = useQuery(queries.getUser.GET_STUDENT_HISTORY_LIST, {
    fetchPolicy: 'no-cache',
    variables: { student_idx: Number(id) },
  });

  const [deleteUser] = useMutation(mutations.deleteUser.DELETE_USER, {
    variables: { user_idx_list: [Number(student?.idx)] },
    onCompleted(data) {
      if (data) {
        history.goBack();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });
  const [toggleVacation, { loading: loadingToggle }] = useMutation(
    data?.getUserDataByIdx?.stat === '3' ? RETURN_TO_CAMPUS : CREATE_USER_VACATION_HISTORY,
    {
      variables: { input: { user_idx: Number(id) } },
      onCompleted: () => {
        refetch();
        openNotification('처리했습니다.');
      },
      onError: (err) => {
        console.log('error', err);
        alert('error');
      },
    },
  );

  function showDeleteConfirm() {
    confirm({
      title: '정말 삭제하시겠습니까?',
      okText: '예',
      okType: 'primary',
      cancelText: '아니오',
      onOk() {
        console.log('OK');
        deleteUser();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  useEffect(() => {
    if (data) {
      //setStudent(data?.getUsers.filter((user) => user.idx === Number(id))[0]);
      setStudent(data?.getUserDataByIdx);
    }
  }, [data, setStudent]);

  useEffect(() => {
    if (fetchClassStudent) {
      setClassHistory(
        fetchClassStudent.classTeachersForStudent.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            className: cur.class.name,
            start_date: moment(cur.class.begin_date).format('YYYY-MM-DD'),
            end_date: moment(cur.class.end_date).format('YYYY-MM-DD'),
            ddate: cur.class.ddate,
            stat: cur.stat,
            teacher: cur.class.class_teacher[0]?.user?.name,
            level: '',
          };

          return [...acc, obj].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        }, []),
      );
    }
  }, [fetchClassStudent]);

  return (
    <>
      <Row justify="space-around">
        <Col span={12}>
          <HeaderTitle level={4}>학생 관리</HeaderTitle>
        </Col>
        <Col span={12} style={{ paddingTop: 5, textAlign: 'right' }}>
          <BackButton />
        </Col>
      </Row>

      <Row className="tblclass-list classroom-box-wrap-height" gutter={[16, 16]}>
        <Col span={12} className="classroom-box1">
          <HalfWrapper className="tblclass-classroom-height">
            <DetailPage>
              <Row className="halfmanagment-space" gutter={[16, 16]} justify="space-between" style={{ marginBottom: 10 }}>
                <Col>
                  <Title level={5}>
                    {student?.name} 학생 {student?.english_name && `(${student?.english_name})`}
                  </Title>
                </Col>
                {data?.getUserDataByIdx?.stat !== '0' && (
                  <Col>
                    <CustomButton danger style={{ marginRight: 10 }} onClick={showDeleteConfirm}>
                      삭제
                    </CustomButton>
                    <CustomButton type="primary" ghost style={{ marginRight: 10 }} onClick={() => showModal('edit')}>
                      수정
                    </CustomButton>
                    {/* {!isAfterSchool(companyName) && ( */}
                    <CustomButton danger style={{ marginRight: 10 }} onClick={toggleVacation} loading={loadingToggle}>
                      {data?.getUserDataByIdx?.stat === '3' ? '휴원종료' : '휴원처리'}
                    </CustomButton>
                    {/* )} */}
                  </Col>
                )}
              </Row>

              <Row className="halfmanagment-space" gutter={[0, 16]} style={{ marginBottom: 10 }}>
                <Col lg={12} xs={24}>
                  <RowItem title="생년월일" span={8} value={student?.birthday && moment(student?.birthday).format('YYYY-MM-DD')} />
                </Col>
                <Col lg={12} xs={24}>
                  <RowItem title="가입일" span={8} value={student?.idate && moment(student?.idate).format('YYYY-MM-DD')} />
                </Col>
              </Row>

              <Row className="halfmanagment-space" gutter={[0, 16]} style={{ marginBottom: 10 }}>
                <Col lg={12} xs={24}>
                  <RowItem title="ID" span={8} value={student?.id} />
                </Col>
                <Col className="emailidwdth" lg={12} xs={24}>
                  <RowItem title="E-mail" span={8} value={student?.email} />
                </Col>
              </Row>

              <Row className="halfmanagment-space" gutter={[0, 16]} style={{ marginBottom: 10 }}>
                <Col lg={12} xs={24}>
                  <RowItem title="학교" span={8} value={student?.school_name} />
                </Col>
                <Col lg={12} xs={24}>
                  <RowItem title="학년" span={8} value={student?.school_grade} />
                </Col>
              </Row>

              <Row className="halfmanagment-space" gutter={[0, 16]} style={{ marginBottom: 10 }}>
                <Col lg={24} xs={24}>
                  <Row>
                    <Col lg={4} md={8} xs={12}>
                      <Text style={{ size: 0.9, color: '#777777' }}>학생 연락처</Text>
                    </Col>
                    <Col>
                      <Text style={{ color: '#202020' }}>{student?.phone}</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="halfmanagment-space" gutter={[0, 16]} style={{ marginBottom: 10 }}>
                <Col lg={12} xs={24}>
                  <RowItem title="부모님 성함" span={8} value={student?.parent_name} />
                </Col>
                <Col lg={12} xs={24}>
                  <RowItem title="부모님 연락처" span={8} value={student?.parent_phone} />
                </Col>
              </Row>

              <Row gutter={[0, 16]} style={{ marginBottom: 10 }}>
                <Col lg={12} xs={24}>
                  <Row>
                    <Col lg={4} md={8} xs={12}>
                      <Text style={{ size: 0.9, color: '#777777' }}>주소</Text>
                    </Col>
                    <Col>
                      <Text style={{ color: '#202020' }}>{student?.address}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} xs={24}>
                  <RowItem title="메모" span={8} value={student?.memo} />
                </Col>
              </Row>
            </DetailPage>
          </HalfWrapper>
        </Col>

        <Col span={12} className="classroom-box2">
          <HalfWrapper className="tblclass-classroom-height">
            <div style={{ padding: 5 }}>
              <Title level={5}>Student History(수강 이력)</Title>
              <CustomTable
                className="classroom-table-table"
                loading={studentHistoryLoading}
                dataSource={classHistory}
                columns={studyHistoryColumns}
                pagination={{ pageSize: 5 }}
                size="small"
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
              />
            </div>
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <StudentModal userInfo={student} popupMode={popupMode} handleCancel={handleCancel} visible={visible} refetch={refetch} />}
    </>
  );
};

const studyHistoryColumns = [
  {
    title: '반명',
    dataIndex: 'className',
    key: 'className',
    align: 'center',
    render: (text, record) => {
      return record.ddate === null ? (
        record.stat === '2' ? (
          <Text type="warning">{text}</Text>
        ) : (
          <Text>{text}</Text>
        )
      ) : (
        <Text type="danger">{text}</Text>
      );
    },
  },
  {
    title: '시작일',
    dataIndex: 'start_date',
    key: 'start_date',
    align: 'center',
  },
  {
    title: '종강일',
    dataIndex: 'end_date',
    key: 'end_date',
    align: 'center',
  },
  {
    title: '담임',
    dataIndex: 'teacher',
    key: 'teacher',
    align: 'center',
  },
  {
    title: '레벨',
    dataIndex: 'level',
    key: 'level',
    align: 'center',
  },
];

export default StudentDetail;
