import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { isPturn } from 'src/utils';
import { openNotification } from 'src/components/common/Notification';
import CreateSubClassForm from 'src/pages/ClassRoom/CreateSubClassForm';
import UpdateClassForm from 'src/pages/ClassRoom/UpdateClassForm';
import { userTypVar, userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';
import { GET_COMPANY_CONTENTS } from 'src/operations/queries/company';
import LoadingLayer from '../common/LoadingLayer';

const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];

function SubClassRoomModal({ classItems = null, popupMode, handleCancel, visible, refetch, classStudents = [] }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const company_name = userInfoVar()?.company_name;
  const userType = userInfoVar()?.type;
  const [hasLevel, setHasLevel] = useState(true);
  const isAutoSchedule = useMemo(() => {
    const company = userInfoVar()?.campus?.company;
    if (company?.is_auto_schedule === '0') {
      return false;
    }
    return true;
  }, []);

  const initialState = {
    name: classItems?.name,
    begin_date: classItems?.begin_date,
    end_date: classItems?.end_date,
    begin_time: classItems?.begin_time,
    end_time: classItems?.end_time,
    day_of_week_view: classItems?.day_of_week_view,
    room: classItems?.room,
    lexile_level: classItems?.lexile_level,
    campus_idx: userInfoVar()?.campus_idx,
    teachers: `${classItems?.class_teacher?.length > 0 ? classItems?.class_teacher.map((v) => v.user.idx) : []}`,
    is_auto_schedule: isAutoSchedule,
    levelup_level: classItems?.levelup_level,
  };

  const [values, setValues] = useState(initialState);

  useEffect(() => {
    if (classItems?.name) {
      form.setFieldsValue({
        name: classItems?.name,
        begin_date: moment(classItems?.begin_date),
        end_date: moment(classItems?.end_date),
        begin_time: moment(classItems?.begin_time, 'HH:mm'),
        end_time: moment(classItems?.end_time, 'HH:mm'),
      });
    }
  }, [classItems?.name, form]);

  //const [bookList, setBookList] = useState([]);

  const [teacherList, setTeacherList] = useState([]);

  //const { data: fetchBookData } = useQuery(queries.book.GET_BOOK_LIST);
  const { data: dataBookContents } = useQuery(GET_COMPANY_CONTENTS, {
    skip: !userInfoVar(),
    variables: { company_idx: userInfoVar()?.campus.company.idx },
  });

  const { data: fetchTeacherData } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: { type: userTypVar().teacher },
  });

  const [mutate, { loading, data }] = useMutation(mutations.classRoom.CREATE_CLASS, {
    variables: values,

    onCompleted(data) {
      if (data) {
        refetch();
        const class_idx = data.createClass.idx;
        addStudent({
          variables: {
            class_idx,
            students: classStudents.map((v) => parseInt(v.user_idx)),
            is_auto_schedule: isAutoSchedule,
          },
        });
      }
    },
    onError(error) {
      console.log('error values', values);
      console.log('error', error);
    },
  });

  const [addStudent, { loading: loadingAdd }] = useMutation(mutations.classRoom.ADD_STUDENT_IN_CLASS, {
    onCompleted(data) {
      if (data) {
        handleCancel();
        openNotification('완료!');
        refetch();
        console.log('addStudent onCompleted', data);
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const [update, { loading: editing }] = useMutation(mutations.classRoom.UPDATE_CLASS, {
    variables: { ...values, is_auto_schedule: isAutoSchedule, idx: classItems?.idx },
    refetchQueries: [
      {
        query: queries.getClass.CLASS_LESSON_LIST,
        variables: { class_idx: classItems?.idx },
      },
    ],

    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('반 수정 완료!');
      }
    },
    onError(error) {
      console.log('values', values);
      console.log('error', error);
    },
  });

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        if (formData?.book_code === 'manual' && !formData?.book_direct && hasLevel) {
          alert('교재명을 입력하세요.');
          return;
        }

        setValues((prev) => {
          const dayOfWeek = yoilOptions
            .map(({ value }) => {
              if (formData?.day_of_week) {
                return formData.day_of_week.includes(value) ? '1' : '0';
              } else {
                return '0';
              }
            })
            .join('');

          return {
            ...prev,
            teachers: initialState.teachers,
            begin_date: moment(formData.begin_date).format('YYYY-MM-DD'),
            end_date: moment(formData.end_date).format('YYYY-MM-DD'),
            begin_time: initialState.begin_time ? moment(initialState.begin_time).format('HH:mm') : null,
            end_time: initialState.end_time ? moment(initialState.end_time).format('HH:mm') : null,
            name: formData.name,
            book_code: hasLevel ? formData.book_code : 'manual',
            book_title: formData?.book_direct || '',
            day_of_week: dayOfWeek,
            day_of_week_view: classItems.day_of_week_view,
            room: classItems.room,
            lexile_level: classItems.lexile_level,
            levelup_level: classItems.levelup_level,
            is_auto_schedule: isAutoSchedule,
            parent_idx: classItems?.parent_idx,
          };
        });

        formCallback(formData.book_code);
        popupMode === 'create' ? ActionCancel() : handleCancel();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  // 날짜
  function onDateChange(name, dateString) {
    setValues((prev) => ({
      ...prev,
      [name]: dateString,
    }));
  }

  // 시간
  function onTimeChange(name, timeString) {
    console.log('name', name);
    console.log('timeString', timeString);
    setValues((prev) => ({
      ...prev,
      [name]: timeString,
    }));
  }
  function formCallback(book_code) {
    console.log('formCallback');

    if (popupMode === 'create') {
      mutate();
    } else {
      update();
    }
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  useEffect(() => {
    if (data) {
      setValues(initialState);
    }

    // if (fetchBookData) {
    //   setBookList(
    //     fetchBookData.bookList.reduce((acc, cur, index) => {
    //       const obj = {
    //         key: cur.code,
    //         value: cur.code,
    //       };
    //       return [...acc, obj];
    //     }, []),
    //   );
    // }

    if (fetchTeacherData) {
      setTeacherList(
        fetchTeacherData.getUsers.reduce(
          (acc, cur, index) => {
            const obj = {
              key: cur.idx,
              value: cur.name,
              userId: cur.id,
            };
            return [...acc, obj];
          },
          company_name === 'educo' && (userInfoVar()?.type === 2 || userInfoVar()?.type === 6)
            ? [
                {
                  key: parseInt(userInfoVar()?.idx),
                  value: userInfoVar()?.name,
                  userId: userInfoVar()?.id,
                },
              ]
            : [],
        ),
      );
    }
  }, [data, fetchTeacherData]);

  const bookList = useMemo(() => {
    if (dataBookContents?.getCompanyBookContentList) {
      let bookList = [];
      dataBookContents?.getCompanyBookContentList.forEach((bookContent) => {
        if (bookContent?.book_list) {
          bookContent.book_list.forEach((book) => {
            const short_title = book.book_sub[0]?.short_title;
            if (company_name !== 'educo' || !book.code.includes('L0')) {
              bookList.push({
                key: book.code,
                value: short_title || book.volume_name || book.code,
              });
            }
          });
        }
      });

      return bookList;
    }
    return [];
  }, [dataBookContents]);

  const validateMessages = {
    required: `label 을(를) 입력해 주세요.`,
  };
  return (
    <>
      <Modal
        className={`${company_name} fullwdmodal`}
        //className="fullwdmodal"
        centered
        visible={visible}
        title={
          popupMode === 'create' ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
              <span>반 등록</span>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
              <span>반 정보수정</span>
            </div>
          )
        }
        onCancel={handleCancel}
        width={'70%'}
        footer={[
          <Button key="submit" type="primary" loading={popupMode === 'create' ? loading : editing} onClick={handleFormSubmit}>
            {popupMode === 'create' ? '등록' : '수정'}
          </Button>,
          <Button key="back" onClick={() => (popupMode === 'create' ? ActionCancel() : handleCancel())}>
            닫기
          </Button>,
        ]}
      >
        <Form className="classmodal" form={form} name="control-ref" layout="inline" validateMessages={validateMessages}>
          {popupMode === 'create' ? (
            <CreateSubClassForm
              onDateChange={onDateChange}
              bookList={bookList}
              teacherList={teacherList}
              yoilOptions={yoilOptions}
              isAutoSchedule={isAutoSchedule}
              hasLevel={true}
              form={form}
            />
          ) : (
            <UpdateClassForm
              onDateChange={onDateChange}
              onTimeChange={onTimeChange}
              bookList={bookList}
              teacherList={teacherList}
              yoilOptions={yoilOptions}
              isAutoSchedule={isAutoSchedule}
              classItems={classItems}
              form={form}
              hasLevel={company_name === 'creo' || isPturn(company_name) ? true : hasLevel}
              isClassEnd={false}
              userType={userType}
            />
          )}
        </Form>
      </Modal>
      {loadingAdd && <LoadingLayer />}
    </>
  );
}

export default SubClassRoomModal;
