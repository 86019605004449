import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { WriteImageSoundPopup } from './WritePopup';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
// import {useMyScript} from "../common/MyScriptHook";
import './css/animation.css';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import { isMobile } from 'react-device-detect';
import { ScaleChange } from 'src/utils';
let bookUnitStage = undefined;
let bookNameUnitStageChecklist = undefined;

const MultiWordDndWrite = ({ directionEnd, problems, steps, setSteps }) => {
  const [dropSound, setDropSound] = useState(undefined);

  const [images, setImages] = useState(undefined);
  const [words, setWords] = useState(undefined);
  const [isCorrect, setIsCorrect] = useState(undefined);
  const [isWordDropped, setIsWordDropped] = useState(false); //drop후 타이머로 바뀜(애니매이션 때문에)
  const [isWordDroppedA, setIsWordDroppedA] = useState(false); //drop시 바로 바뀜

  // const {myScriptEditorRef, script, onClear, error} = useMyScript();
  const [showWritePopUp, setShowWritePopUp] = useState(false);
  const [correctImageIndex, setCorrectImageIndex] = useState(-1);
  const { level, unit, stage } = useParams();

  const { scale } = ScaleChange();

  if (level == '1b' && unit == 9 && stage == 5) {
    bookUnitStage = true;
    bookNameUnitStageChecklist = {
      box: [{ loopkey1: 2, loopkey2: 2 }],
      fox: [{ loopkey1: 2, loopkey2: 2 }],
      ax: [{ loopkey1: 1, loopkey2: 1 }],
      web: [{ loopkey1: 0, loopkey2: 0 }],
      wolf: [{ loopkey1: 0, loopkey2: 0 }],
      watch: [{ loopkey1: 0, loopkey2: 0 }],
      'yo-yo': [{ loopkey1: 0, loopkey2: 3 }],
      yacht: [{ loopkey1: 0, loopkey2: 0 }],
      yellow: [{ loopkey1: 0, loopkey2: 0 }],
      zebra: [{ loopkey1: 0, loopkey2: 0 }],
      zero: [{ loopkey1: 0, loopkey2: 0 }],
      zoo: [{ loopkey1: 0, loopkey2: 0 }],
    };
  }

  const handlePopupClose = () => {
    console.log('handlePopupClose words=>', words);
    //팝업닫을 때 초기화함
    // onClear();
    setShowWritePopUp(false);
    setIsWordDropped(false);
    setIsWordDroppedA(false);
    setCorrectImageIndex(-1);

    setWords(_.shuffle(problems[steps].words).map((item) => ({ name: item, dropped: false })));
    setImages(_.shuffle(problems[steps].images));
    setIsCorrect(new Array(problems[steps].images.length).fill(false));

    setTimeout(() => {
      //setIsWordDropped(true);
    }, 1000);
  };

  const handleCorrectWriteEnd = () => {
    if (correctImageIndex >= images.length - 1) {
      setShowWritePopUp(false);
      setSteps(steps + 1);
    } else {
      setTimeout(() => {
        setCorrectImageIndex(correctImageIndex + 1);
      }, 1000);
    }
  };
  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      setIsWordDropped(true);

      setDropSound('very-good');
    } else {
      setDropSound(undefined);
    }
  };

  const handleDragEnd = useCallback(
    (result) => {
      const { destination, draggableId } = result;
      if (_.isNil(destination) || destination.index > 0) {
        setDropSound('wrong_sound1');

        return;
      }
      setDropSound('mouse-click');
      setIsWordDroppedA(true);
      //const copyWords = _.cloneDeep(words);
      //const copy = copyWords.map(value => value.name);
      //const findIndex = copy.indexOf(draggableId.substring(0, draggableId.length - 1));
      const findImageIndex = _.map(images, (item) => item.word).indexOf(draggableId.substring(0, draggableId.length - 1));
      console.log('findImageIndex', findImageIndex);
      console.log('isCorrect', isCorrect);

      if (findImageIndex !== -1) {
        let correctCopy = _.cloneDeep(isCorrect);
        correctCopy[findImageIndex] = true;
        setIsCorrect(correctCopy);
      }
    },
    [images, isCorrect],
  );
  useEffect(() => {
    if (!_.isNil(problems[steps])) {
      setWords(_.shuffle(problems[steps].words).map((item) => ({ name: item, dropped: false })));
      setImages(_.shuffle(problems[steps].images));
      setIsCorrect(new Array(problems[steps].images.length).fill(false));
    }
  }, [steps, problems]);

  useEffect(() => {
    if (isWordDropped === true) {
      setTimeout(() => {
        setIsWordDropped(false);
        setIsWordDroppedA(false);
        setTimeout(() => {
          let copyWords = _.cloneDeep(words);
          copyWords.shift();
          setWords(copyWords);
        }, 1000);
      }, 1000);
    }
  }, [isWordDropped]);

  useEffect(() => {
    if (!_.isNil(words) && words.length === 0) {
      setCorrectImageIndex(0);
      // if (error) {
      // 	setSteps(steps + 1);
      // } else {
      setShowWritePopUp(true);
      // }
    }
  }, [
    words,
    // , error
  ]);
  return (
    <>
      <S.MainBox className="main_content onewrap lv1_stg5">
        <DragDropContext onDragEnd={handleDragEnd}>
          <S.MultiDnDCardArea className="card_area">
            {images &&
              images.map((item, index) => (
                <DroppableWordBox scale={scale} key={`droppable-word-${index}`} item={item} index={index} isCorrect={isCorrect} />
              ))}
          </S.MultiDnDCardArea>
          <S.MultiDndMonsterArea className="monster_area">
            {words && words.length > 0 && (
              <DraggableCartAni
                scale={scale}
                key={`draggable-word${1}`}
                item={words[0]}
                index={1}
                isWordDropped={isWordDropped}
                isWordDroppedA={isWordDroppedA}
                directionEnd={directionEnd}
              />
            )}
          </S.MultiDndMonsterArea>
        </DragDropContext>
      </S.MainBox>

      {!_.isNil(dropSound) && (
        <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3`} onEnd={handleDropSoundEnd} />
      )}

      {showWritePopUp && (
        <WriteImageSoundPopup
          showWritePopUp={showWritePopUp}
          image={
            correctImageIndex !== -1 && images ? images[correctImageIndex].url : 'https://cdn.cloubot.com/PM/image/level1/image_apple.png'
          }
          word={correctImageIndex !== -1 && images ? images[correctImageIndex].word : 'apple'}
          num={correctImageIndex + 1}
          total={images ? images.length : 0}
          userScript={{}}
          editorRef={{}}
          onClear={{}}
          onCorrectEnd={handleCorrectWriteEnd}
          onClose={handlePopupClose}
          isEnd={correctImageIndex !== -1 && !_.isNil(images) && !_.isNil(images[correctImageIndex].isEnd) ? true : false}
        />
      )}
    </>
  );
};

export default MultiWordDndWrite;

const DroppableWordBox = ({ item, index, isCorrect, scale }) => {
  return (
    <S.MultiDnDdl scale={scale}>
      <S.MultiDnDdt>
        <img style={{ width: '80%' }} src={item.url} alt={item.word} />
      </S.MultiDnDdt>
      <S.MultiDnDdd scale={scale}>
        <Droppable droppableId={`upper${item.word}`} type={item.word}>
          {(provided, snapshot) => (
            <>
              <S.MultiDndMonsterCard
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined }}
                active={isCorrect && isCorrect[index]}
                className={isCorrect && isCorrect[index] ? 'drag-monster_card row5 active' : 'drag-monster_card'}
                scale={scale}
              >
                {isCorrect && isCorrect[index]
                  ? bookUnitStage
                    ? _.map(item.word, (letter, index) => (
                        <S.MultiDndMonsterCardAlphabet
                          data={`${item.word}`}
                          key={letter + index}
                          isPink={
                            bookNameUnitStageChecklist[item.word][0]['loopkey1'] == index ||
                            bookNameUnitStageChecklist[item.word][0]['loopkey2'] == index
                          }
                          className={
                            bookNameUnitStageChecklist[item.word][0]['loopkey1'] == index ||
                            bookNameUnitStageChecklist[item.word][0]['loopkey2'] == index
                              ? 'font_pink'
                              : 'font_blue'
                          }
                        >
                          {letter}
                        </S.MultiDndMonsterCardAlphabet>
                      ))
                    : _.map(item.word, (word, idx) => (
                        <S.MultiDndMonsterCardAlphabet
                          isPink={idx == 0}
                          data={`word-${word}-${idx}`}
                          key={`word-${word}-${idx}`}
                          className={idx == 0 ? 'font_pink' : 'font_blue'}
                        >
                          {word}
                        </S.MultiDndMonsterCardAlphabet>
                      ))
                  : undefined}
              </S.MultiDndMonsterCard>
            </>
          )}
        </Droppable>
      </S.MultiDnDdd>
    </S.MultiDnDdl>
  );
};

const DraggableCartAni = ({ item, index, isWordDropped, isWordDroppedA, directionEnd, scale }) => {
  const [isloaded, setIsloaded] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [wordSound, setWordSound] = useState(undefined);

  const handleWordSoundEnd = () => {
    setWordSound(undefined);
  };

  useEffect(() => {
    if (directionEnd) {
      if (!isWordDropped) {
        setTimeout(() => {
          setIsloaded(true);
          setTimeout(() => {
            setIsEnd(true);
          }, 1000);
        }, 1000);
      }
    }

    return () => {
      setIsloaded(false);
      setIsEnd(false);
    };
  }, [isWordDropped, directionEnd]);

  useEffect(() => {
    if (isEnd) {
      setWordSound(item.name);
    }
  }, [isEnd, item.name]);
  return (
    <>
      <S.MultiDndMonsterCart
        scale={scale}
        className={`monster_cart ${isWordDropped ? 'slideOutRight animated' : isloaded && !isEnd ? 'slideInLeft animated' : ''}`}
        style={{ visibility: `${isWordDropped ? 'visible' : isloaded ? 'visible' : 'hidden'}` }}
      >
        <S.MultiDndMonsterQ className="monster_q">
          <Droppable droppableId={`under${item.name + index}`} type={item.name} isDropDisabled={true}>
            {(provided) =>
              isWordDroppedA ? (
                <div className="drag-monster_card" style={{ visibility: 'hidden' }}>
                  <span>&nbsp;</span>
                </div>
              ) : (
                <div ref={provided.innerRef}>
                  <Draggable draggableId={item.name + index} index={index} type={item.name}>
                    {(provided) => (
                      <S.MultiDndMonsterCard
                        scale={scale}
                        active={true}
                        className="drag-monster_card active"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {bookUnitStage
                          ? _.map(item.name, (letter, index) => (
                              <S.MultiDndMonsterCardAlphabet
                                data={`${item.name}`}
                                key={letter + index}
                                isPink={
                                  bookNameUnitStageChecklist[item.name][0]['loopkey1'] == index ||
                                  bookNameUnitStageChecklist[item.name][0]['loopkey2'] == index
                                }
                                className={
                                  bookNameUnitStageChecklist[item.name][0]['loopkey1'] == index ||
                                  bookNameUnitStageChecklist[item.name][0]['loopkey2'] == index
                                    ? 'font_pink'
                                    : 'font_blue'
                                }
                              >
                                {letter}
                              </S.MultiDndMonsterCardAlphabet>
                            ))
                          : _.map(item.name, (letter, index) => (
                              <S.MultiDndMonsterCardAlphabet
                                data={`${item.name[item.name.split('').length - 1]}`}
                                key={letter + index}
                                isPink={index == 0}
                                className={index == 0 ? 'font_pink' : 'font_blue'}
                              >
                                {letter}
                              </S.MultiDndMonsterCardAlphabet>
                            ))}
                      </S.MultiDndMonsterCard>
                    )}
                  </Draggable>
                  <div style={{ display: 'none' }}>{provided.placeholder}</div>
                </div>
              )
            }
          </Droppable>
        </S.MultiDndMonsterQ>
      </S.MultiDndMonsterCart>
      {!_.isNil(wordSound) && <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sounds/${wordSound}.mp3`} onEnd={handleWordSoundEnd} />}
    </>
  );
};
