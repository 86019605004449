import React, { useState } from 'react';
import { Col, Row, Button, Radio } from 'antd';
import { BookCard, BookCover, BookMeta, BookTitle, BOOK_COL_STYLE, SMART_ECLASS_LINK_BUTTON_STYLE, THUMBNAIL_BASE_URL } from './CommonComp';
import styled from 'styled-components';
const EBOOK_BASE_URL = 'http://ebook.theclassenglish.com/data/';
const EBOOKS = [
  { name: 'Reading Bridge-1', level: 'RW Bridge-1', image: 'TheClassBridge_RC_1.jpg' },
  { name: 'Reading Bridge-2', level: 'RW Bridge-2', image: 'TheClassBridge_RC_2.jpg' },
  { name: 'Reading Bridge-3', level: 'RW Bridge-3', image: 'TheClassBridge_RC_3.jpg' },
  { name: 'Listening Bridge-1', level: 'LS Bridge-1', image: 'TheClassBridge_LC_1.jpg' },
  { name: 'Listening Bridge-2', level: 'LS Bridge-2', image: 'TheClassBridge_LC_2.jpg' },
  { name: 'Listening Bridge-3', level: 'LS Bridge-3', image: 'TheClassBridge_LC_3.jpg' },
];
const EBOOKSFOREDUCO = [
  { name: 'Reading Bridge-1', level: 'RW Bridge-1', image: 'TheClassBridge_RC_1.jpg' },
  { name: 'Reading Bridge-2', level: 'RW Bridge-2', image: 'TheClassBridge_RC_2.jpg' },
  { name: 'Reading Bridge-3', level: 'RW Bridge-3', image: 'TheClassBridge_RC_3.jpg' },
  { name: 'Reading & Writing-7-1', level: 'RW 7-1', image: 'TheClassRW7_1.jpg' },
  { name: 'Reading & Writing-7-2', level: 'RW 7-2', image: 'TheClassRW7_2.jpg' },
  { name: 'Reading & Writing-7-3', level: 'RW 7-3', image: 'TheClassRW7_3.jpg' },
  { name: 'Reading & Writing-8-1', level: 'RW 8-1', image: 'TheClassRW8_1.jpg' },
  { name: 'Reading & Writing-8-2', level: 'RW 8-2', image: 'TheClassRW8_2.jpg' },
  { name: 'Reading & Writing-8-3', level: 'RW 8-3', image: 'TheClassRW8_3.jpg' },
  { name: 'Reading & Writing-9-1', level: 'RW 9-1', image: 'TheClassRW9_1.jpg' },
  { name: 'Reading & Writing-9-2', level: 'RW 9-2', image: 'TheClassRW9_2.jpg' },
  { name: 'Reading & Writing-9-3', level: 'RW 9-3', image: 'TheClassRW9_3.jpg' },
  { name: 'Reading & Writing-10-1', level: 'RW 10-1', image: 'TheClassRW10_1.jpg' },
  { name: 'Reading & Writing-10-2', level: 'RW 10-2', image: 'TheClassRW10_2.jpg' },
  { name: 'Reading & Writing-10-3', level: 'RW 10-3', image: 'TheClassRW10_3.jpg' },
  { name: 'Listening Bridge-1', level: 'LS Bridge-1', image: 'TheClassBridge_LC_1.jpg' },
  { name: 'Listening Bridge-2', level: 'LS Bridge-2', image: 'TheClassBridge_LC_2.jpg' },
  { name: 'Listening Bridge-3', level: 'LS Bridge-3', image: 'TheClassBridge_LC_3.jpg' },
  { name: 'Listening 7-1', level: 'LS 7-1', image: 'TheClassLC7_1.jpg' },
  { name: 'Listening 7-2', level: 'LS 7-2', image: 'TheClassLC7_2.jpg' },
  { name: 'Listening 7-3', level: 'LS 7-3', image: 'TheClassLC7_3.jpg' },
  { name: 'Listening 8-1', level: 'LS 8-1', image: 'TheClassLC8_1.jpg' },
  { name: 'Listening 8-2', level: 'LS 8-2', image: 'TheClassLC8_2.jpg' },
  { name: 'Listening 8-3', level: 'LS 8-3', image: 'TheClassLC8_3.jpg' },
  { name: 'Listening 9-1', level: 'LS 9-1', image: 'TheClassLC9_1.jpg' },
  { name: 'Listening 9-2', level: 'LS 9-2', image: 'TheClassLC9_2.jpg' },
  { name: 'Listening 9-3', level: 'LS 9-3', image: 'TheClassLC9_3.jpg' },
  { name: 'Listening 10-1', level: 'LS 10-1', image: 'TheClassLC10_1.jpg' },
  { name: 'Listening 10-2', level: 'LS 10-2', image: 'TheClassLC10_2.jpg' },
  { name: 'Listening 10-3', level: 'LS 10-3', image: 'TheClassLC10_3.jpg' },
];
const radioButtonStyle = {
  width: 90,
  textAlign: 'center',
};

const TheClass = ({checkForEduco=false}) => {
  const [levelNo, setLevelNo] = useState(0);
  const handleLevelChange = (e) => {
    const {
      target: { value },
    } = e;

    setLevelNo(value);
  };
  const ebookarr = checkForEduco?EBOOKSFOREDUCO:EBOOKS;
  return (
    <>
      <Radio.Group
        value={levelNo}
        style={{ marginBottom: 16, marginLeft: 16, display: 'block' }}
        buttonStyle="solid"
        onChange={handleLevelChange}
        size="middle"
      >
        <Radio.Button value={0} style={radioButtonStyle}>
          전체
        </Radio.Button>
        <Radio.Button value={'Reading'} style={radioButtonStyle}>
          Reading
        </Radio.Button>
        <Radio.Button value={'Listening'} style={radioButtonStyle}>
          Listening
        </Radio.Button>
      </Radio.Group>

      <Row gutter={[16, 16]} style={{ margin: '0 10px' }}>
        {ebookarr.filter((e, fkey) => (levelNo === 0 ? true : e.name.includes(levelNo))).map((item, key) => (
          <Col
            span={4}
            key={`col-${key}`}
            style={{
              ...BOOK_COL_STYLE,
              borderRight: `${(key%6 === 5) || (levelNo !== 0 && key === (ebookarr.length/2)-1) ? '1px solid #dedede' : ''}`,
            }}
          >
            <BookTitle level={5}>{item.name}</BookTitle>
            <BookCard
              style={{ marginLeft: 5, marginRight: 5 }}
              cover={
                <>
                <BookCover
                  style={{
                    backgroundImage: `url(${THUMBNAIL_BASE_URL}${item.image})`,
                  }}
                  />
                  <EbookLinkGroup level={item.level} isBridge={item.name.includes('Bridge')} />
                  </>
              }
            >
              {/* {<BookMeta description={<EbookLinkGroup level={item.level} isBridge={item.name.includes('Bridge')} />} />} */}
            </BookCard>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default TheClass;

const ebookButtonStyle = {
  width: '48%',
  fontSize: '0.8em',
  textAlign: 'center',
};

const EbookLinkGroup = ({ level,isBridge }) => {
  return (
    <ButtonWrap>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 3px' }}> */}
        <Button
          style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, ...ebookButtonStyle, marginRight: 3 }}
          onClick={() => {
            window.open(`${EBOOK_BASE_URL}${level} U${!isBridge?'1,2':'1,3'}`, '_blank', 'width=1400, height=900');
          }}
        >
          UNIT 1{!isBridge?'-2':'-3'}
        </Button>
        <Button
          style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, ...ebookButtonStyle }}
          onClick={() => {
            window.open(`${EBOOK_BASE_URL}${level} U${!isBridge?'3,4':'4,6'}`, '_blank', 'width=1400, height=900');
          }}
        >
          UNIT {!isBridge?'3-4':'4-6'}
        </Button>
      {/* </div> */}
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: 3, paddingTop: 0 }}> */}
        <Button
          style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, ...ebookButtonStyle, marginRight: 3 }}
          onClick={() => {
            window.open(`${EBOOK_BASE_URL}${level} U${!isBridge?'5,6':'7,9'}`, '_blank', 'width=1400, height=900');
          }}
        >
          UNIT {!isBridge?'5-6':'7-9'}
        </Button>
        <Button
          style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, ...ebookButtonStyle }}
          onClick={() => {
            window.open(`${EBOOK_BASE_URL}${level} U${!isBridge?'7,8':'10,12'}`, '_blank', 'width=1400, height=900');
          }}
        >
          UNIT {!isBridge?'7-8':'10-12'}
        </Button>
      {/* </div> */}
    </ButtonWrap>
  );
};
const ButtonWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  top: 0;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }
`;