import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Form, Modal, Typography, Select } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { isAfterSchool, isPturn } from 'src/utils';
import { openNotification } from 'src/components/common/Notification';
import CreateClassForm from 'src/pages/ClassRoom/CreateClassForm';
import UpdateClassForm from 'src/pages/ClassRoom/UpdateClassForm';
import { userTypVar, userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';
import { GET_COMPANY_CONTENTS } from 'src/operations/queries/company';
import LoadingLayer from '../common/LoadingLayer';

const { Option } = Select;
const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];

function ClassRoomModal({ classItems = null, popupMode, handleCancel, visible, refetch, classStudents = [], setCreditVisible = () => {} }) {
  const [form] = Form.useForm();
  const [eachDayCount, setEachDayCount] = useState([]);
  const nowTimestamp = useMemo(() => Date.now(), []);
  const history = useHistory();
  const company_name = userInfoVar()?.company_name;
  const userType = userInfoVar()?.type;
  const [hasLevel, setHasLevel] = useState(isPturn(company_name) ? true : false);
  const [isLearningCenter, setIsLearningCenter] = useState(company_name === 'educo' ? 1 : 0);
  const isAutoSchedule = useMemo(() => {
    const company = userInfoVar()?.campus?.company;
    if (company?.is_auto_schedule === '0') {
      return false;
    }
    return true;
  }, []);
  const initialState = {
    name: classItems?.name,
    book_code: classItems?.book_code,
    begin_date: classItems?.begin_date,
    //end_date: classItems?.end_date,
    begin_time: classItems?.begin_time,
    end_time: classItems?.end_time,
    day_of_week: classItems?.day_of_week,
    room: classItems?.room,
    lexile_level: classItems?.lexile_level,
    campus_idx: userInfoVar()?.campus_idx,
    teachers: `${classItems?.class_teacher[0]?.user.idx}`,
    is_auto_schedule: isAutoSchedule,
    levelup_level: classItems?.levelup_level,
  };
  const isClassEnd = useMemo(() => {
    if (classItems) {
      if (classItems.end_date) {
        return moment(classItems.end_date).valueOf() < nowTimestamp;
      }
    }
    return false;
  }, [classItems, nowTimestamp]);

  useEffect(() => {
    if (classItems) {
      setIsLearningCenter(classItems.is_learning_center || 0);
    }
  }, [classItems]);

  const [values, setValues] = useState(initialState);

  //const [bookList, setBookList] = useState([]);

  const [teacherList, setTeacherList] = useState([]);

  //const { data: fetchBookData } = useQuery(queries.book.GET_BOOK_LIST);
  const { data: dataBookContents } = useQuery(GET_COMPANY_CONTENTS, {
    skip: !userInfoVar(),
    variables: { company_idx: userInfoVar()?.campus.company.idx },
  });

  const { data: fetchTeacherData } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      type:
        company_name !== 'educo'
          ? company_name === 'rise'
            ? `${userTypVar().teacher},${userTypVar().employee}`
            : userTypVar().teacher
          : `${userTypVar().teacher},${userTypVar().manager},${userTypVar().employee}`,
    },
  });

  const [mutate, { loading, data }] = useMutation(mutations.classRoom.CREATE_CLASS, {
    variables: values,

    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('반 등록 완료!');
      }
    },
    onError(error) {
      console.log('error values', values);
      console.log('error', error);
    },
  });

  const [addStudent, { loading: loadingAdd }] = useMutation(mutations.classRoom.ADD_STUDENT_IN_CLASS, {
    onCompleted(data) {
      if (data) {
        handleCancel();
        openNotification('완료!');
        console.log('addStudent onCompleted', data);
        const cidx = data?.addStudentInClass[0]?.class_idx;
        company_name !== 'englishtap' && history.push('/classroom');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const [recreate, { loading: loadingRecreate }] = useMutation(mutations.classRoom.CREATE_CLASS, {
    variables: values,

    onCompleted(data) {
      if (data) {
        refetch();
        const class_idx = data.createClass.idx;
        addStudent({
          variables: {
            class_idx,
            students: classStudents.map((v) => parseInt(v.user_idx)),
            is_auto_schedule: isAutoSchedule,
          },
        });
      }
    },
    onError(error) {
      console.log('error values', values);
      console.log('error', error);
    },
  });

  const [update, { loading: editing }] = useMutation(mutations.classRoom.UPDATE_CLASS, {
    variables: { ...values, is_auto_schedule: isAutoSchedule, idx: classItems?.idx },
    refetchQueries: [
      {
        query: queries.getClass.CLASS_LESSON_LIST,
        variables: { class_idx: classItems?.idx },
      },
    ],

    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('반 수정 완료!');
      }
    },
    onError(error) {
      console.log('values', values);
      console.log('error', error);
    },
  });

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        if (formData?.book_code === 'manual' && !formData?.book_direct && hasLevel) {
          alert('교재명을 입력하세요.');
          return;
        }

        setValues((prev) => {
          const dayOfWeek = yoilOptions
            .map(({ value }) => {
              if (formData?.day_of_week) {
                return formData.day_of_week.includes(value) ? '1' : '0';
              } else {
                return '0';
              }
            })
            .join('');
          const dayOfWeekView = yoilOptions
            .map(({ value }) => {
              if (formData?.day_of_week_view) {
                return formData.day_of_week_view.includes(value) ? '1' : '0';
              } else {
                return '0';
              }
            })
            .join('');
          const checkTeacher = Array.isArray(formData.teachers) ? formData.teachers.join(',') : null;

          return {
            ...prev,
            teachers: checkTeacher,
            begin_date: moment(formData.begin_date).format('YYYY-MM-DD'),
            end_date: moment(formData.end_date).format('YYYY-MM-DD'),
            begin_time: formData.begin_time ? moment(formData.begin_time).format('HH:mm') : null,
            end_time: formData.end_time ? moment(formData.end_time).format('HH:mm') : null,
            name: formData.name,
            book_code: hasLevel ? formData.book_code : 'manual',
            book_title: formData?.book_direct || '',
            day_of_week: dayOfWeek,
            day_of_week_view: dayOfWeekView,
            room: formData.room,
            lexile_level: formData.lexile_level,
            levelup_level: formData.levelup_level,
            is_auto_schedule: isAutoSchedule,
            is_learning_center: isLearningCenter,
            each_day_count: eachDayCount.length ? eachDayCount.map((item) => item.join('')) : [],
          };
        });

        formCallback(formData.book_code);
        popupMode === 'create' ? ActionCancel() : handleCancel();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  // 날짜
  function onDateChange(name, dateString) {
    setValues((prev) => ({
      ...prev,
      [name]: dateString,
    }));
  }

  // 시간
  function onTimeChange(name, timeString) {
    console.log('name', name);
    console.log('timeString', timeString);
    setValues((prev) => ({
      ...prev,
      [name]: timeString,
    }));
  }
  function formCallback(book_code) {
    console.log('formCallback');

    if (popupMode === 'create') {
      mutate();
    } else {
      if (isClassEnd) {
        console.log(classItems?.book_code, book_code);
        if (classItems?.book_code === book_code) {
          alert('교재를 변경하세요.');
        } else {
          recreate();
          company_name === 'englishtap' && popupMode !== 'create' && isClassEnd && setCreditVisible(true);
        }
      } else {
        update();
      }
    }
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  useEffect(() => {
    if (data) {
      setValues(initialState);
    }

    // if (fetchBookData) {
    //   setBookList(
    //     fetchBookData.bookList.reduce((acc, cur, index) => {
    //       const obj = {
    //         key: cur.code,
    //         value: cur.code,
    //       };
    //       return [...acc, obj];
    //     }, []),
    //   );
    // }

    if (fetchTeacherData) {
      setTeacherList(
        fetchTeacherData.getUsers.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            value: cur.name,
            userId: cur.id,
            view_id: cur.view_id,
          };
          return [...acc, obj];
        }, []),
      );
    }
  }, [data, fetchTeacherData]);

  const bookList = useMemo(() => {
    if (dataBookContents?.getCompanyBookContentList) {
      let bookList = [];
      dataBookContents?.getCompanyBookContentList.forEach((bookContent) => {
        if (bookContent?.book_list) {
          bookContent.book_list.forEach((book) => {
            const short_title = book.book_sub[0]?.short_title;
            bookList.push({
              key: book.code,
              value: short_title || book.volume_name || book.code,
            });
          });
        }
      });

      return bookList;
    }
    return [];
  }, [dataBookContents]);

  const validateMessages = {
    required: `label 을(를) 입력해 주세요.`,
  };

  useEffect(() => {
    if (company_name === 'creo' || company_name === 'rise') setHasLevel(true);
    else if (company_name === 'haveenglish') setHasLevel(false);
    else if (classItems && classItems.book_code === null) {
      setHasLevel(false);
    }
  }, [classItems, company_name]);

  const hasLevelCheck = useMemo(() => {
    if (company_name === 'creo' || isPturn(company_name) || company_name === 'rise') {
      return true;
    } else {
      return false;
    }
  }, [company_name]);

  return (
    <>
      <Modal
        className={`${company_name} fullwdmodal`}
        //className="fullwdmodal"
        centered
        visible={visible}
        title={
          popupMode === 'create' ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
              <span>반 등록</span>
              <div>
                {company_name === 'educo' && (
                  <Select
                    name="is_learning_center"
                    placeholder="코스북"
                    value={isLearningCenter}
                    onChange={(value) => setIsLearningCenter(value)}
                    style={{ width: '150px', marginRight: '10px' }}
                  >
                    <Option key={0} value={0}>
                      수업용
                    </Option>
                    <Option key={1} value={1}>
                      센터용
                    </Option>
                  </Select>
                )}
                {!hasLevelCheck && (
                  <Select name="hasLevel" placeholder="코스북" defaultValue={false} onChange={setHasLevel} style={{ width: '150px' }}>
                    <Option key={false} value={false}>
                      코스북 미사용
                    </Option>
                    <Option key={true} value={true}>
                      코스북 사용
                    </Option>
                  </Select>
                )}
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
              <span>반 정보수정</span>
              <div>
                {company_name === 'educo' && (
                  <Select
                    name="is_learning_center"
                    placeholder="코스북"
                    value={isLearningCenter}
                    onChange={(value) => setIsLearningCenter(value)}
                    style={{ width: '150px', marginRight: '10px' }}
                  >
                    <Option key={0} value={0}>
                      수업용
                    </Option>
                    <Option key={1} value={1}>
                      센터용
                    </Option>
                  </Select>
                )}
                {!hasLevelCheck && (
                  <Select name="hasLevel" placeholder="코스북" initialState={false} onChange={setHasLevel} style={{ width: '150px' }}>
                    <Option key={false} value={false}>
                      코스북 미사용
                    </Option>
                    <Option key={true} value={true}>
                      코스북 사용
                    </Option>
                  </Select>
                )}
              </div>

              {/* <span style={{ fontSize: '0.7em', paddingLeft: '5px' }}>
                새로운 교재로 변경되면 기존 반은 종료된 리스트에서 확인할 수 있습니다.
              </span> */}
            </div>
          )
        }
        onCancel={handleCancel}
        width={'70%'}
        footer={[
          <Button key="submit" type="primary" loading={popupMode === 'create' ? loading : editing} onClick={handleFormSubmit}>
            {popupMode === 'create' ? '등록' : '수정'}
          </Button>,
          <Button key="back" onClick={() => (popupMode === 'create' ? ActionCancel() : handleCancel())}>
            닫기
          </Button>,
        ]}
      >
        {isClassEnd ? (
          <Typography.Paragraph style={{ color: '#f00' }}>
            모든 수업을 마쳤습니다. 교재와 시작일을 변경하여 새로운 교재로 시작하세요.
          </Typography.Paragraph>
        ) : (
          ''
        )}
        <Form className="classmodal" form={form} name="control-ref" layout="inline" validateMessages={validateMessages}>
          {popupMode === 'create' ? (
            <CreateClassForm
              onDateChange={onDateChange}
              onTimeChange={onTimeChange}
              bookList={bookList}
              teacherList={teacherList}
              yoilOptions={yoilOptions}
              isAutoSchedule={isAutoSchedule}
              hasLevel={hasLevelCheck ? true : hasLevel}
              form={form}
              setEachDayCount={setEachDayCount}
              eachDayCount={eachDayCount}
            />
          ) : (
            <UpdateClassForm
              onDateChange={onDateChange}
              onTimeChange={onTimeChange}
              bookList={bookList}
              teacherList={teacherList}
              yoilOptions={yoilOptions}
              isAutoSchedule={isAutoSchedule}
              classItems={classItems}
              form={form}
              hasLevel={hasLevelCheck ? true : hasLevel}
              isClassEnd={isClassEnd}
              userType={userType}
              setEachDayCount={setEachDayCount}
              eachDayCount={eachDayCount}
            />
          )}
        </Form>
      </Modal>
      {(loadingRecreate || loadingAdd) && <LoadingLayer />}
    </>
  );
}

export default ClassRoomModal;
