import React, { useMemo } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { userInfoVar } from 'src/apollo/cache';
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const HomeworkResultMenuGroup = ({ currentMenu }) => {
  const hasElib = useMemo(() => {
    //나중에 바꿔야 한다. 일단 임시로...
    if (userInfoVar()?.campus?.company?.name === 'creo') {
      return false;
    }
    return true;
  }, []);

  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
          <MenuButton
            type={`${currentMenu === 'content' ? 'primary' : 'default'} `}
            onClick={() => {
              history.push('/learning-content');
            }}
          >
            반별 점수관리
          </MenuButton>
          &nbsp;
          <MenuButton
            type={`${currentMenu === 'content-std' ? 'primary' : 'default'} `}
            onClick={() => {
              history.push('/learning-content-std');
            }}
          >
            학생별 점수관리
          </MenuButton>
    </div>
  );
};

export default HomeworkResultMenuGroup;
