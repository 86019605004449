import React, { useState, useMemo } from 'react';
import { Col, Row, Radio, Button, Modal, Image } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import { BookCard, BookCover, BookMeta, BookTitle, BOOK_COL_STYLE, SMART_ECLASS_LINK_BUTTON_STYLE } from './CommonComp';
import styled from 'styled-components';
const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;
// const GROUPS = ['SB', 'WB', 'Lime Tree Readers'];
const LEVELS = [1, 2, 3, 4, 5, 6];
const VOLUMES = [1, 2, 3, 4, 5, 6];
const GROUPS = ['SB', 'WB'];
const plusUrlSB = [
  [,,,,,],
  [,'zcxj',,,,],
  [,,,,,],
  [,'cfiq',,,,],
  [,,,,,],
  [,,,,,],
];
const plusUrlWB = [
  ['egbo', 'xyoq', 'srtw', 'lhfw', 'haot', 'kygs'],
  ['iyow', 'vnzc', 'jifl', 'dvqp', 'oqpj', 'ipdl'],
  ['mpky', 'klug', 'dsra', 'mgaj', 'kzbw', 'lnmx'],
  ['zowy', 'fray', 'irpc', 'pvax', 'ydmo', 'xiyb'],
  ['sorc', 'sbxe', 'jfnx', 'triq', 'itlz', 'rwxt'],
  ['hbri', 'xbqj', 'vgwd', 'upwm', 'ijtr', 'fepn'],
];
const IMAGE_NAMES = {
  title: 'English Tap',
  sb: 'English Tap',
  wb: 'English Tap WB',
  link_name: 'https://admin.kidstapenglish.com/flipBook/englishTap/ET',
  link_name_wb: 'https://online.flipbuilder.com/sangbong91/',
};
const THUMBNAIL_BASE_URL = 'https://cdn.cloubot.com/ET/cover/';
const EnglishTap = () => {
  const [groupNo, setGroupNo] = useState(0);
  const { levelNo, onChangeLevelNo } = useLevelTab();
  const handleChangeGroup = (evt) => {
    const {
      target: { value },
    } = evt;
    setGroupNo(value);
    // onChangeLevelNo(0);
  };
  const onClickBookCard = (lv, vol) => {
    // changing urls for engtap sb
    if (lv === 2 && vol === 2 && groupNo === 0) {
      window.open(
        `${IMAGE_NAMES.link_name_wb}${plusUrlSB[lv-1][vol-1]}`,
        '_blank',
        'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes',
      );
    } else if (lv === 4 && vol === 2 && groupNo === 0) {
      window.open(
        `${IMAGE_NAMES.link_name_wb}${plusUrlSB[lv-1][vol-1]}`,
        '_blank',
        'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes',
      );
    } else{
      window.open(
        `${groupNo === 0 ? IMAGE_NAMES.link_name : IMAGE_NAMES.link_name_wb}${
          groupNo === 0 ? '_' + lv + '_' + vol : plusUrlWB[lv - 1][vol - 1]
        }`,
        '_blank',
        'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes',
      );
    }
  };
  return (
    <>
      <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
        <LevelTab
          levelNo={levelNo}
          levels={LEVELS}
          onChage={(evt) => {
            const {
              target: { value },
            } = evt;
            onChangeLevelNo(value);
          }}
        />
        <Radio.Group
          value={groupNo}
          style={{ marginBottom: 16, marginLeft: 16 }}
          buttonStyle="solid"
          onChange={handleChangeGroup}
          size="middle"
        >
          {GROUPS.map((groupName, key) => (
            <Radio.Button key={`radio-level-key${key}`} value={key} style={{ width: 160, textAlign: 'center' }}>
              {groupName}
            </Radio.Button>
          ))}
        </Radio.Group>
        {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv) => (
          <Row key={`row-${lv}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
            {VOLUMES.map((vol) => (
              <Col
                span={4}
                key={`col-${lv}-${vol}`}
                style={{
                  ...BOOK_COL_STYLE,
                  borderRight: `${vol === 6 ? '1px solid #dedede' : ''}`,
                }}
              >
                <BookTitle level={5} style={{ textAlign: 'center' }}>
                  {`${IMAGE_NAMES.title} ${lv}-${vol} ${groupNo === 1 ? 'WB' : ''}`}
                </BookTitle>
                <BookCard
                  onClick={() => {
                    onClickBookCard(lv, vol);
                  }}
                  cover={
                    <>
                      <BookCover
                        style={{
                          backgroundImage: `url(${THUMBNAIL_BASE_URL}${groupNo === 1 ? 'WB/' : ''}L${lv}V${vol}.jpg)`,
                          border: '0px solid #fff',
                        }}
                      />
                      <EbookLinkGroup onlyEbook={groupNo !== 0} level={lv} volume={vol} handleClick={onClickBookCard} />
                    </>
                  }
                >
                  {/* {groupNo === 0 && <BookMeta description={<EbookLinkGroup level={lv} volume={vol} />} />} */}
                </BookCard>
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </>
  );
};

export default EnglishTap;
const EbookLinkGroup = ({ volume, level, handleClick, onlyEbook }) => {
  return (
    <ButtonWrap>
      <Button
        onClick={() => {
          handleClick(level, volume);
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.9em' }}
      >
        e Book
      </Button>
      {!onlyEbook && (
        <>
          <Button
            style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.9em' }}
            onClick={(e) => {
              e.stopPropagation();
              const culp_token = window.localStorage.getItem('culp_token');
              window.open(`${STUDY_TOOL_HOST}/ET-L${level}V${volume}U1D1?token=${culp_token}`, '_blank');
            }}
          >
            E-learning
          </Button>
          <Button
            style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.9em' }}
            onClick={() => {
              window.open(
                `https://admin.kidstapenglish.com/TG/englishTap/ET_${level}_${volume}/index-h5.html#page=1`,
                '_blank',
                'width=1400, height=900',
              );
            }}
          >
            TG
          </Button>
        </>
      )}
    </ButtonWrap>
  );
};
const ButtonWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  top: 0;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }
`;
