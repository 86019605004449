import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Form, Input, Button, Select } from 'antd';
import styled from 'styled-components';
import { LOGIN_FOR_META_EXAM } from 'src/operations/queries/getLevelTest';
import Loading from 'src/components/common/Loading';
import { Redirect } from 'react-router-dom';
import { storage } from 'src/utils/hooks';
import { isLogedIn } from 'src/apollo/cache';
import { settings } from 'src/dummy/settings.js';
import { Color } from 'src/utils/theme';
import queryString from 'query-string';

const LoginForm = styled.div`
  background-color: #fff;
  width: 476px;
  height: 462px;
  box-shadow: 2px 1px 15px 10px #dddddd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
`;
const LogoImage = styled.div`
  width: 70%;
  background: ${Color.borderBase};
  border-radius: 10px;
`;
const LevelTest = () => {
  const params = queryString.parse(window.location.search);
  const [loginLevelTest, { loading, data: dataLogin, error }] = useLazyQuery(LOGIN_FOR_META_EXAM);
  const [form] = Form.useForm();
  const onSubmit = ({ phone, student_name, dob }) => {
    const variables = {
      phone: '010' + phone,
      student_name: student_name,
      dob: '20' + dob.slice(0, 2) + '-' + dob.slice(2, 4) + '-' + dob.slice(4, 6),
      type: 'meta',
    };
    loginLevelTest({ variables });
    form.resetFields();
  };

  useEffect(() => {
    if (params.phone && params.student_name && params.dob) {
      form.setFieldsValue({
        phone: params.phone,
        student_name: params.student_name,
        dob: params.dob,
      });
      const variables = {
        phone: '010' + params.phone,
        student_name: params.student_name,
        dob: '20' + params.dob.slice(0, 2) + '-' + params.dob.slice(2, 4) + '-' + params.dob.slice(4, 6),
        type: 'meta',
      };
      loginLevelTest({ variables });
    }
  }, [params]);

  const subdomain = useMemo(() => {
    if (window.location.hostname) {
      return window.location.hostname.split('.')[0];
    }
    return '';
  }, []);

  const data = useMemo(() => {
    if (dataLogin) {
      storage.setItem('culp_token', JSON.stringify(dataLogin.loginForLevelTest));
      if (window.Android && window.Android.SaveUserToken) {
        window.Android.SaveUserToken(JSON.stringify(dataLogin.loginForLevelTest));
      }
      isLogedIn(!!dataLogin?.loginForLevelTest);
      return dataLogin;
    }
    return undefined;
  }, [dataLogin]);

  useEffect(() => {
    if (error) {
      console.log(error);
      alert(error);
    }
  }, [error]);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle initialValue="010">
      <Select style={{ width: 70 }} defaultValue="010" disabled>
        <Select.Option value="010">010</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {data?.orderItemList ? (
            <Redirect to={{ pathname: `/level-test-main`, state: { idx_list: data.orderItemList.map((order) => order.idx) } }} />
          ) : (
            <LoginForm>
              {settings[subdomain] && (
                <LogoImage>
                  <img src={settings[subdomain].logo} style={{ width: '100%' }} alt="logo" />
                </LogoImage>
              )}

              <h1 style={{ fontSize: '2em' }}>LevelTest Login</h1>
              <Form
                form={form}
                className="login-form"
                onFinish={onSubmit}
                initialValues={{
                  userPhone: '',
                }}
              >
                <Form.Item
                  style={{ width: 240 }}
                  name="student_name"
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: '학생이름을 입력하세요.',
                      max: 12,
                    },
                  ]}
                >
                  {<Input placeholder="학생이름을 입력하세요." size="large" name="student_name" maxLength="20" />}
                </Form.Item>
                <Form.Item
                  style={{ width: 240 }}
                  name="dob"
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: '생년월일 6자리를 입력하세요.',
                      max: 6,
                    },
                  ]}
                >
                  {<Input placeholder="생년월일 6자리를 입력하세요." size="large" name="dob" maxLength="6" />}
                </Form.Item>
                <Form.Item
                  style={{ width: 240 }}
                  name="phone"
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      pattern: /[0-9]+/,
                      message: '숫자만 입력하세요.',
                      max: 12,
                    },
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    placeholder="부모님 휴대폰 번호를(010제외) 입력하세요."
                    size="large"
                    name="phone"
                    maxLength="8"
                  />
                </Form.Item>

                <Form.Item>
                  <div>
                    <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: 240 }} loading={loading}>
                      Log In
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </LoginForm>
          )}
        </>
      )}
    </>
  );
};

export default LevelTest;
