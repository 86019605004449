import React, { useMemo } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const HomeworkResultMenuGroupRise = ({ currentMenu }) => {

  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
      <MenuButton
        type={`${currentMenu === 'date' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learning');
        }}
      >
        반별 숙제관리
      </MenuButton>
        &nbsp;
      <MenuButton
        type={`${currentMenu === 'class' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learning-class');
        }}
        >
        학생별 숙제관리
      </MenuButton>
    </div>
  );
};

export default HomeworkResultMenuGroupRise;
