import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Select, Radio, Tooltip } from 'antd';
import HomeworkResultMenuGroup from 'src/components/common/HomeworkResultMenuGroup';
import HomeworkResultMenuGroupRise from 'src/components/common/HomeworkResultMenuGroupRise';
import { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_LESSONS_FOR_HOMEWORK } from 'src/operations/queries/getClass';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { userInfoVar } from 'src/apollo/cache';
import UseHistoryState from 'src/components/common/UseHistoryState';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  {
    key: 'class_name',
    title: '반명',
    dataIndex: 'class_name',
    align: 'center',
    sorter: true,
  },
  {
    key: 'book',
    title: '교재정보',
    dataIndex: 'book',
    align: 'center',
    sorter: true,
  },
  {
    key: 'student_name',
    title: '학생명',
    dataIndex: 'student_name',
    align: 'center',
    sorter: true,
    render: (text, record) => {
      return (
        <>
          {text}
          {record.english_name ? `(${record.english_name})` : ''}
        </>
      );
    },
  },
  {
    key: 'lesson_count',
    title: (
      <>
        학습 <br />
        Lesson 수
      </>
    ),
    dataIndex: 'lesson_count',
    align: 'center',
  },
  {
    key: 'complete',
    title: '수행율',
    dataIndex: 'complete',
    align: 'center',
    render: (text) => <Tooltip title={'학생의 최초 학습일부터 현재까지의 수행율입니다.'}>{text}</Tooltip>,
  },
  {
    key: 'average',
    title: '평균점수',
    dataIndex: 'average',
    align: 'center',
  },
  {
    key: 'recent_study_date',
    title: '최근학습일',
    dataIndex: 'recent_study_date',
    align: 'center',
  },
  {
    key: 'recent_study_info',
    title: '최근학습정보',
    dataIndex: 'recent_study_info',
    align: 'center',
  },
  {
    key: 'study_status_view',
    title: (
      <>
        학습현황
        <br />
        상세보기
      </>
    ),
    dataIndex: 'study_status_view',
    align: 'center',
    render: (_, { class_idx, user_idx, student_name, complete_count, history, user_id }) => {
      // if (!complete_count) {
      //   return '-';
      // }
      return (
        <Button
          onClick={() => {
            //window.open(`/online-study-result/${user_idx}`, 'study_report', 'width=980, height=750, scrollbars=yes');
            history.push({
              pathname: `/learning-class/study-result/${class_idx}/${user_idx}`,
              state: { user_name: student_name, user_id: user_id },
            });
          }}
          size="small"
        >
          상세보기
        </Button>
      );
    },
  },
];
const SEARCH_TYPE_ALL = 0;
const SEARCH_TYPE_STUDENT = 1;
const SEARCH_TYPE_CLASS = 2;

const options = [
  { label: '진행중', value: 1 },
  { label: '종료', value: 2 },
  { label: '전체', value: 0 },
];

const getPercentGrade = (rightCount, totalCount) => {
  if (totalCount < rightCount) return `100%`;
  if (rightCount && totalCount) {
    return `${Math.round((100 * rightCount) / totalCount)}%`;
  }
  return `0%`;
};

const LearningClass = () => {
  const companyName = useSelector(classStoreData);
  const PER_PAGE = companyName === 'rise' ? 20 : 10;

  const [searchType, setSearchType] = useState(SEARCH_TYPE_ALL);
  const [classType, setClassType] = useState(1);
  const { onClassChange } = useCampusClass();
  // const { selectedClassIdx, onClassChange } = useCampusClass();

  const campus_idx = userInfoVar()?.campus_idx;
  const history = useHistory();
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      class_idx: 0,
      search_type: SEARCH_TYPE_ALL,
      search_text: '',
      pageNumber: 0,
      sortOrder: '',
      sortField: '',
      classType: 1,
    },
    'hw_search',
  );

  const { data: dataUserLessons, loading } = useQuery(GET_USER_LESSONS_FOR_HOMEWORK, {
    variables: {
      skip: !campus_idx,
      campus_idx: campus_idx,
      class_idx: searchValue.class_idx,
      page: searchValue.pageNumber,
      search_text: searchValue.search_text,
      search_type: searchValue.search_type,
      sort_order: searchValue.sortOrder,
      sort_field: searchValue.sortField,
      class_type: searchValue.classType,
      take: PER_PAGE,
    },
  });

  const dataList = useMemo(() => {
    const list = dataUserLessons?.getUserLessonsCampus?.user_lessons;
    if (list) {
      return list.map((item, key) => {
        const bookTitle = item?.class?.book?.book_sub[0]?.title || item?.class?.book_code;
        return {
          No: PER_PAGE * searchValue.pageNumber + key + 1,
          key: `user-lesson-row${key}`,
          class_name: item?.class?.name,
          class_idx: item?.class?.idx,
          book: bookTitle,
          student_name: item?.user?.name,
          english_name: item?.user?.english_name,
          lesson_count:
            companyName === 'rise'
              ? `${item?.homework_result?.current_complete_count}/${
                  item?.class?.class_lesson?.filter((ele) => {
                    return (
                      moment().isSameOrAfter(ele.study_date) &&
                      moment.utc(ele.study_date).isSameOrAfter(item?.homework_result?.oldest_info?.date)
                    );
                  })?.length
                }`
              : `${item?.homework_result?.complete_count}/${item?.lesson_count}`,
          complete: isAfterSchool(companyName)
            ? getPercentGrade(item?.homework_result?.complete_count, item?.lesson_count)
            : getPercentGrade(
                companyName === 'rise' ? item?.homework_result?.current_complete_count : item?.homework_result?.complete_count,
                item?.class?.class_lesson?.filter((ele) => {
                  return (
                    moment().isSameOrAfter(ele.study_date) &&
                    moment.utc(ele.study_date).isSameOrAfter(item?.homework_result?.oldest_info?.date)
                  );
                })?.length,
              ), //오늘 날짜 기준 학습 수행율

          average: item?.homework_result?.average,
          recent_study_date: item?.homework_result?.latest_info?.date
            ? moment(item?.homework_result?.latest_info?.date).format('YYYY-MM-DD HH:mm')
            : '없음',
          recent_study_info: item?.homework_result?.latest_info?.lesson
            ? item?.homework_result?.latest_info?.lesson.slice(-4).replace('U', 'Unit').replace('D', ' Lesson')
            : '없음',
          user_idx: item?.user?.idx,
          user_id: item?.user?.id,
          complete_count: item?.homework_result?.complete_count,
          history: history,
        };
      });
    }
    return [];
  }, [dataUserLessons, searchValue, companyName]);
  useEffect(() => {
    onClassChange(searchValue.class_idx);
    // eslint-disable-next-line
  }, [searchValue.class_idx]);
  // const handleClassChange = (idx) => {
  //   setSearchValue((prev) => {
  //     return {
  //       ...prev,
  //       class_idx: idx,
  //       pageNumber: 0,
  //     };
  //   });
  // };
  const handleTableChange = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    setSearchValue((prev) => {
      return {
        ...prev,
        pageNumber: pagination.current - 1,
        sortOrder: order || '',
        sortField: field,
      };
    });
  };
  const handleNameSearch = (sv) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        search_text: sv,
        search_type: searchType, //검색 버튼 눌렀을 때, 검색이 되도록 여기에 추가함.
        pageNumber: 0,
      };
    });
  };
  const handleChangeClassType = (v) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        classType: parseInt(v),
        pageNumber: 0,
      };
    });
    setClassType(parseInt(v));
  };
  return (
    <>
      {companyName === 'rise' ? <HomeworkResultMenuGroupRise currentMenu="class" /> : <HomeworkResultMenuGroup currentMenu="class" />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>
          {companyName === 'rise' ? (
            '학생별 숙제관리'
          ) : (
            <span style={{ paddingLeft: 10, fontSize: '0.7em' }}>* 반을 기준으로 개인별 학습 현황을 종합적으로 확인하세요.</span>
          )}
        </HeaderTitle>
        <div style={{ display: 'inline-block', width: 'auto', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
          <Radio.Group
            options={options}
            optionType="button"
            value={classType}
            buttonStyle="solid"
            onChange={(e) => {
              handleChangeClassType(e.target.value);
            }}
          />
          &nbsp;
          {/* {isAfterSchool(companyName) && 
            <ClassSelectBox
              onClassChange={handleClassChange}
              selectedClassIdx={selectedClassIdx}
              campus_idx={campus_idx}
              style={{ width: '200px', textAlign: 'left' }}
            />}
           
          &nbsp; */}
          <label>
            <Select value={searchType} onChange={setSearchType} style={{ width: '90px' }}>
              <Select.Option value={SEARCH_TYPE_ALL}>전체</Select.Option>
              <Select.Option value={SEARCH_TYPE_STUDENT}>학생명</Select.Option>
              <Select.Option value={SEARCH_TYPE_CLASS}>반명</Select.Option>
            </Select>
          </label>
          &nbsp;
          <Input.Search
            placeholder={searchType === 1 ? '학생명 검색' : '검색어 입력'}
            type="text"
            style={{ width: '200px' }}
            defaultValue={searchValue.search_text}
            onSearch={handleNameSearch}
          />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper style={{ minHeight: 'calc(100vh - 25vh)' }}>
            <CustomTable
              dataSource={dataList}
              columns={columns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                current: searchValue.pageNumber + 1,
                total: dataUserLessons?.getUserLessonsCampus?.total || 0,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ x: 530 }}
              loading={loading}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default LearningClass;
