import React from 'react';
import { Col, Row, Button } from 'antd';
import {
  BookCard,
  BookCover,
  BookMeta,
  BookTitle,
  BOOK_COL_STYLE,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  THUMBNAIL_BASE_URL,
} from './CommonComp';
import styled from 'styled-components';
const VOLUMES = [1, 2, 3];

const handleSetupFileDownload = (volume) => {
  //window.open(`http://admin.smart-eclass.co.kr/include/common/downFile3.php?filename=kids_tap_${volume}.zip`);
  window.open(`${SMART_ECLASS_CDN_BASE_URL}common/kids_tap/kids_tap_${volume}.zip`);
};

const GrammarHouse = () => {
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <Row gutter={[16, 16]} style={{ margin: '0 10px' }}>
        {VOLUMES.map((v, key) => (
          <Col span={4} key={`col-${key}`} style={{ ...BOOK_COL_STYLE, borderRight: `${v === 3 ? '1px solid #dedede' : ''}` }}>
            <BookTitle level={5}>Grammar House {key + 1}</BookTitle>
            <BookCard
              style={{ marginLeft: 5, marginRight: 5 }}
              
              cover={
                <>
                  <BookCover
                    style={{
                      backgroundImage: `url(${THUMBNAIL_BASE_URL}grammar_house_${key + 1}.jpg)`,
                    }}
                  />
                  <ButtonWrap>
                    <Button
                      onClick={() => {
                        window.open(`https://cdn.cloubot.com/SM/ppt/grammar_house_${v}/index.html`, '_blank', 'width=1400, height=900');
                      }}
                      style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.9em', lineHeight: '1' }}
                    >
                      e Book
                    </Button>
                  </ButtonWrap>
                </>
              }
            >
              {/* <BookMeta /> */}
            </BookCard>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default GrammarHouse;

const ButtonWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  top: 0;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }
`;
