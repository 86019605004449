import React from 'react';
import { Col, Row, Button } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import {
  BookCard,
  BookCover,
  BookMeta,
  BookTitle,
  BOOK_COL_STYLE,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  THUMBNAIL_BASE_URL,
} from './CommonComp';
import styled from 'styled-components';
const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;
const LEVELS = [1, 2, 3, 4, 5, 6];
const VOLUMES = [1, 2, 3];
const LINK_NAME = 'https://online.flipbuilder.com/sangbong91/';
const plusURL = [
  ['yzqx', 'zpru', 'wqse'],
  ['euyl', 'cgrz', 'afxk'],
  ['rzgm', 'ghrn', 'izex'],
  ['gzjf', 'gafc', 'quln'],
  ['qwbe', 'eqzh', 'ulhj'],
  ['cupf', 'lyrs', 'cvzb'],
];
const TalkingTap = () => {
  const { levelNo, onChangeLevelNo } = useLevelTab();
  const onClickBookCard = (lv, vol) => {
    window.open(`${LINK_NAME}${plusURL[lv - 1][vol - 1]}`, '_blank', 'width=1400, height=900');
  };
  return (
    <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
      <LevelTab
        levelNo={levelNo}
        levels={LEVELS}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />
      {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv) => (
        <Row key={`row-${lv}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
          {VOLUMES.map((vol) => (
            <Col span={4} key={`col-${lv}-${vol}`} style={{ ...BOOK_COL_STYLE, borderRight: `${vol === 3 ? '1px solid #dedede' : ''}` }}>
              <BookTitle level={5} style={{ textAlign: 'center' }}>
                {`Talking Tap ${lv}-${vol}`}
              </BookTitle>
              <BookCard
                // onClick={() => {
                //   onClickBookCard(lv, vol);
                // }}
                cover={
                  <>
                    <BookCover
                      style={{
                        backgroundImage: `url(${THUMBNAIL_BASE_URL.replace('SM', 'TT')}L${lv}V${vol}.jpg)`,
                      }}
                    />
                    <EbookLinkGroup level={lv} volume={vol} handleClick={onClickBookCard} />
                  </>
                }
              >
                {/* <BookMeta description={<EbookLinkGroup level={lv} volume={vol} />} /> */}
              </BookCard>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default TalkingTap;

const EbookLinkGroup = ({ level, volume, handleClick }) => {
  return (
    <ButtonWrap>
      <Button
        onClick={() => {
          handleClick(level, volume);
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.9em' }}
      >
        e Book
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          // if (level > 2 && volume > 1) {
          //   alert('준비중입니다.');
          // } else {
          window.open(`${SMART_ECLASS_CDN_BASE_URL}common/talking-tap/Talking_TAP_Level_${level}-${volume}_teacher_ppt.zip`);
          // }
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.9em', lineHeight: '1' }}
      >
        PPT <br />
        Download
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          const culp_token = window.localStorage.getItem('culp_token');
          window.open(`${STUDY_TOOL_HOST}/TT-L${level}V${volume}U1D1?token=${culp_token}`, '_blank');
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.9em' }}
      >
        E-learning
      </Button>
    </ButtonWrap>
  );
};
const ButtonWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  top: 0;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }
`;
